type SIDEBAR_ROLE_MAPPING_TYPE = {
    [key: string]: Array<string>;
};

export const SIDEBAR_ROLE_MAPPING: SIDEBAR_ROLE_MAPPING_TYPE = {
    VERSA_ADMIN: [
        'HOME',
        'BILLING_DASHBOARD',
        'REPORTING_AND_ANALYTICS',
        'BILLING',
        'INVOICE',
        'USER_MANAGEMENT',
        'ENGINE'
    ],
    CLIENT: ['HOME', 'BILLING_DASHBOARD', 'REPORTING_AND_ANALYTICS', 'BILLING','INVOICE'],
    VERSA: [
        'HOME',
        'BILLING_DASHBOARD',
        'REPORTING_AND_ANALYTICS',
        'BILLING',
        'INVOICE'
    ]
};

export const LOCALE_TZ = {
    'Australia/Melbourne': 'en-AU',
    'America/New_York': 'en-US'
} as {
    [key: string]: string;
};

export const LOCALE_TZ_FORMAT = {
    'Australia/Melbourne': 'DD/MM/YYYY, h:mm:ss a',
    'America/New_York': 'MM/DD/YYYY, h:mm:ss a'
} as {
    [key: string]: string;
};
