// import { Breadcrumb } from 'src/components/dashboard/Views';
import { BillingDashboardWrap } from '../dashboard/BillingDashboard';
import { SWITCH_ROUTES } from 'src/constants/routes';
import styled from 'styled-components';
import { Button } from 'src/components/shared/Button';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { engineWorkflow } from 'src/api/user';
import ClientIdBotIdContext from 'src/components/sidebar/clientIdBotIdContext';
import Switch from 'react-switch';

type SingleTestResult = {
    userSays: string;
    botSays: string;
    expected: string;
    bot: string;
};

export const BREADCRUMB_ITEMS = [
    {
        text: 'Home',
        redirectUrl: SWITCH_ROUTES.DASHBOARD,
        svgSrc: '/images/sidebar/home.svg'
    },
    { text: 'Engine Workflow', redirecUrl: '' }
];

export default function Engine({
    assertion,
    setAssertion,
    rStatus
}: {
    assertion: Array<SingleTestResult>;
    setAssertion: Function;
    rStatus: string;
}): JSX.Element {
    const { botId, region } = useContext(ClientIdBotIdContext);

    const [runStatus, setRunStatus] = useState<string>(rStatus || 'UNINIT');
    // const [result, setResult] = useState<any>();
    const [checked, setChecked] = useState<boolean>(false);

    useEffect(() => {
        setRunStatus(rStatus);
    }, [rStatus]);

    const getBotResponse = (item: SingleTestResult) => {
        if (item?.botSays?.trim() === item?.bot?.trim()) {
            return <SuccessText>{item.bot}</SuccessText>;
        }
        return <FailureText>{item.bot}</FailureText>;
    };

    return (
        <BillingDashboardWrap>
            {/* <Breadcrumb items={BREADCRUMB_ITEMS} /> */}
            <AssertionTitle>Add Assertions</AssertionTitle>
            <ToggleBulkEdit>
                Toggle Bulk Edit{' '}
                <Switch
                    onChange={() => {
                        setChecked(!checked);
                    }}
                    checked={checked}
                    data-testid="switch-bulk-editor"
                />
            </ToggleBulkEdit>

            <div style={{ overflowY: 'auto' }}>
                {checked ? (
                    <BulkEditor
                        data-testid="bulk-editor"
                        rows={10}
                        onChange={(e: any) => {
                            try {
                                const parsedJSON = JSON.parse(e.target.value);
                                setAssertion(parsedJSON);
                            } catch (e) {
                                toast.warning(
                                    'There are issues in the JSON, please fix those.'
                                );
                            }
                        }}
                    >
                        {JSON.stringify(assertion, null, 4)}
                    </BulkEditor>
                ) : (
                    <>
                        <AssertionBox>
                            <div>
                                <p>User Says</p>
                            </div>
                            <div>
                                <p>Bot Says</p>
                            </div>
                        </AssertionBox>
                        {Array.isArray(assertion)
                            ? assertion.map(
                                  (a: SingleTestResult, i: number) => (
                                      <AssertionBox>
                                          <div>
                                              <input
                                                  type="text"
                                                  placeholder="Enter User Says..."
                                                  data-testid={`${i}-userSays`}
                                                  value={a.userSays}
                                                  onChange={e => {
                                                      const dup = JSON.parse(
                                                          JSON.stringify(
                                                              assertion
                                                          )
                                                      );
                                                      dup[i].userSays =
                                                          e.target.value;
                                                      setAssertion(dup);
                                                  }}
                                              />
                                          </div>
                                          <BotSaysContainer>
                                              <input
                                                  type="text"
                                                  data-testid={`${i}-botSays`}
                                                  placeholder="Enter Bot Says..."
                                                  value={a.botSays}
                                                  onChange={e => {
                                                      const dup = JSON.parse(
                                                          JSON.stringify(
                                                              assertion
                                                          )
                                                      );
                                                      dup[i].botSays =
                                                          e.target.value;
                                                      setAssertion(dup);
                                                  }}
                                              />
                                              {getBotResponse(a)}
                                          </BotSaysContainer>
                                          {i > 0 ? (
                                              <div>
                                                  <span
                                                      onClick={() => {
                                                          const dup =
                                                              JSON.parse(
                                                                  JSON.stringify(
                                                                      assertion
                                                                  )
                                                              );
                                                          dup.splice(i, 1);
                                                          setAssertion(dup);
                                                      }}
                                                  >
                                                      Remove
                                                  </span>
                                              </div>
                                          ) : null}
                                      </AssertionBox>
                                  )
                              )
                            : null}
                    </>
                )}
                <ActionBtnContainer>
                    <Button
                        text="Add Assertion"
                        onClick={() => {
                            // add assertion logic
                            const dup = JSON.parse(JSON.stringify(assertion));
                            dup.push({
                                userSays: '',
                                botSays: '',
                                bot: ''
                            });
                            setAssertion(dup);
                        }}
                    />
                    <Button
                        text={
                            runStatus === 'IN_PROGRESS'
                                ? 'Running tests...'
                                : 'Run test'
                        }
                        onClick={async () => {
                            // add test workflow API call

                            if (
                                assertion.some(a => !a.userSays || !a.botSays)
                            ) {
                                toast.warning(
                                    'Please ensure to fill out all the fields before starting test.'
                                );
                            } else {
                                // call API
                                setRunStatus('IN_PROGRESS');
                                try {
                                    const resp = await engineWorkflow(
                                        {
                                            botId,
                                            assertions: Array.isArray(assertion)
                                                ? assertion.map(a => ({
                                                      userSays: a.userSays,
                                                      botSays: a.botSays
                                                  }))
                                                : []
                                        },
                                        region
                                    );
                                    // setResult(resp);
                                    if (resp) {
                                        setAssertion(
                                            resp.results.map((r: any) => ({
                                                userSays: r.user,
                                                botSays: r.expected,
                                                bot: r.bot
                                            }))
                                        );
                                        setRunStatus('SUCCESS');
                                    } else {
                                        setRunStatus('ERROR');
                                        toast.error('Could not run test case.');
                                    }
                                } catch (e: any) {
                                    setRunStatus('ERROR');
                                    toast.error(
                                        e.message || 'Could not run test case.'
                                    );
                                }
                            }
                        }}
                    />
                </ActionBtnContainer>
            </div>
        </BillingDashboardWrap>
    );
}

export const AssertionTitle = styled.p`
    font-size: 20px;
`;

const AssertionBox = styled.div`
    display: flex;
    margin: 20px 0px;
    gap: 20px;

    > div:nth-child(1),
    > div:nth-child(2) {
        flex: 0 0 45%;

        > p {
            font-size: 16px;
            font-weight: 500;
        }
    }

    > div:nth-child(3) {
        flex: 0 0 10%;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    input[type='text'] {
        width: 100%;
        background: rgb(0, 25, 70);
        border: 1px solid rgb(6, 61, 181);
        height: 40px;
        color: white;
        padding: 6px 8px;
        backdrop-filter: blur(2px);
        border-radius: 4px;
    }
`;

const ActionBtnContainer = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
`;

const ToggleBulkEdit = styled.div`
    font-weight: bold;

    display: flex;
    gap: 10px;
    align-items: center;
`;

const BulkEditor = styled.textarea`
    width: 100%;
    background: rgb(0, 25, 70);
    border: 1px solid rgb(6, 61, 181);
    height: 200px;
    color: white;
    padding: 6px 8px;
    backdrop-filter: blur(2px);
    border-radius: 4px;
    margin-top: 20px;
`;

const BotSaysContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const SuccessText = styled.p`
    color: green;
    white-space: pre-wrap;
`;

export const FailureText = styled.p`
    color: red;
    white-space: pre-wrap;
`;

// const TestResult = styled.div`
//     height: 200px;
// `;
