/* eslint no-console: ["error", { allow: ["log", "error"] }] */
import { lowerCaseObjectKeys } from 'src/utils/utils';
import { getAuthToken } from 'src/utils/ls';
import { toast } from 'react-toastify';
import { SWITCH_ROUTES } from 'src/constants/routes';

type RequestMethodType = 'POST' | 'GET' | 'DELETE' | 'PUT' | 'PATCH';
export function headerConfig(type: string, payload?: any) {
    const headers: any = {
        Authorization: getAuthToken(),
        // 'Cache-Control': 'no-cache',
        // Accept: '*/*'
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };

    const realPayload = JSON.stringify(payload);
    const body = type === 'GET' ? null : realPayload;
    return {
        method: type,
        headers,
        body
    };
}

export async function fetchWrapper<T = any>(
    url: string,
    method: RequestMethodType,
    payload?: any
) {
    return fetch(url, headerConfig(method, payload))
        .then(response => {
            if (response.status === 401 || response.status === 4030) {
            // Clear cookie and redirect to home page
            // console.log(
            //     'Unauthorized access or session timed out. Please login again.'
            // );
                window.location.href = SWITCH_ROUTES.LOGOUT
            } else if (response.status !== 200) {
                toast(`Call Api Failed. Status:${response.status}. Url:${url}`);
            }
            return response.json();
        })
        .then(result => {
            // TODO hard code. this is for dev, please remove it when go production
            // if (process.env.NODE_ENV === 'production') {
            //     console.error(result);
            // } else {
            //     Debug(result);
            // }
            // if (result) return lowerCaseObjectKeys(result);
            if (result && typeof result === 'object')
                return lowerCaseObjectKeys(result) as T;
            return result as T;
        })
        .catch(response => {
            console.log('error', `${url}. ${response}`);
        });
}
