export const COLORS = {
    interface_button_default:
        'linear-gradient(180deg, #183F84 0%, #000714 100%)',
    button_drop_shadow: '0px 0px 2px #000000',
    interface_button_selected:
        'linear-gradient(180deg, #051E49 0%, #0F3373 100%)',
    interface_dard_fade:
        'linear-gradient(90deg, rgba(0, 25, 70, 0.4) 0%, rgba(0, 25, 70, 0) 100%)',
    interface_dark_darkblue: '#001946',
    primary_blue: '#58B1EB',
    neutrals_black: '#000000',
    neutrals_black_2: 'rgba(0, 0, 0, 0.2)',
    neutrals_black_2_noa: 'rgb(5, 21, 56)',
    interface_dark_gradient_01:
        'linear-gradient(180deg, #001946 0%, #0C215A 32.81%, #001946 100%)',
    interface_dark_fade:
        'linear-gradient(90deg, rgba(0, 25, 70, 0.4) 0%, rgba(0, 25, 70, 0) 100%)',
    interface_dark_bad: '#FF0000',
    interface_dark_good: '#FFD600',
    interface_dark_accent_01: '#04FFD2',
    interface_dark_accent_02: '#2CCCFF',
    interface_dark_accent_03: '#2361FF',
    Interface_dark_glow: '#063DB5',
    interface_dark_navbg: '#00153A',
    white_9: 'rgba(255, 255 ,255 ,0.9)'
};
