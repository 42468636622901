import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sankey from 'src/sankey/Sankey';
import TableForSankeyData from 'src/components/TableForSankeyData';
import TEST_DATA from 'src/sankey/test-data.json';
import TEST_DATA2 from 'src/sankey/test-data2.json';
import TABLE_TEST_DATA from 'src/sankey/test-table-data.json';
import TABLE_TEST_DATA2 from 'src/sankey/test-table-data2.json';

export default function SankeyPage() {
    const [chartData, setChartData] = useState(TEST_DATA);
    const [tableData, setTableData] = useState(TABLE_TEST_DATA);
    const [selectedData, setSelectedData] = useState({});
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

    const reset = () => {
        setChartData(TEST_DATA);
        setTableData(TABLE_TEST_DATA);
    };

    const onSelected = (e: any) => {
        // console.log(e)
        setSelectedData(e);

        setChartData(TEST_DATA2);
        setTableData(TABLE_TEST_DATA2);
    };

    return (
        <div>
            <button onClick={goBack}>Go Back</button>

            <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                Sankey Page
                <button onClick={() => onSelected(null)}>
                    Filter(Mock Click)
                </button>
                <button onClick={reset}>Reset</button>
            </div>

            <div style={{ height: '55vh', width: '100%' }}>
                <Sankey data={chartData} onClick={onSelected} />
            </div>

            {/* <br/> TableForSankeyData */}
            <TableForSankeyData data={tableData} />
        </div>
    );
}
