import { Outlet } from 'react-router-dom';
import { Footer } from 'src/components/shared/Footer';
import { UserInfoFloating } from 'src/components/shared/UserInfoFloating';
import Sidebar from 'src/components/sidebar/Sidebar';
import useCheckUserState from 'src/hooks/useCheckUserState';

export default function BORoot() {
    // <Link to={SWITCH_ROUTES.BO}>BO ROOT</Link>
    const { userInfo } = useCheckUserState();
    // console.log('userInfo', userInfo)
    return (
        <>
            <Sidebar />
            <UserInfoFloating info={userInfo} />
            <div id="bo-content-view">
                <Outlet />
                <Footer />
            </div>
        </>
    );
}
