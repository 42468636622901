import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';
import { COLORS } from 'src/constants/colors';
import { MultiBarChartDataT } from 'src/types/dashboardT';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            color: 'white',
            ticks: {
                color: 'white'
            }
        },
        y1: {
            color: 'white',
            position: 'left' as const,
            ticks: {
                color: 'white'
            }
        },
        y2: {
            color: 'white',
            position: 'right' as const,
            ticks: {
                color: 'white'
            }
        }
    },
    plugins: {
        legend: {
            position: 'bottom' as const,
            labels: {
                color: 'white'
            }
        },
        tooltip: {
            backgroundColor: COLORS.white_9,
            titleColor: 'black',
            bodyColor: 'black',
            callbacks: {
                label: function (tooltipItem: any) {
                    return `${tooltipItem.dataset.label} $${tooltipItem.formattedValue}`;
                }
            }
        }
    }
};

const nonCurrencyOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            color: 'white',
            ticks: {
                color: 'white'
            }
        },
        y1: {
            color: 'white',
            position: 'left' as const,
            ticks: {
                color: 'white'
            }
        },
        y2: {
            color: 'white',
            position: 'right' as const,
            ticks: {
                color: 'white'
            }
        }
    },
    plugins: {
        legend: {
            position: 'bottom' as const,
            labels: {
                color: 'white'
            }
        },
        tooltip: {
            backgroundColor: COLORS.white_9,
            titleColor: 'black',
            bodyColor: 'black',
            callbacks: {
                label: function (tooltipItem: any) {
                    return `${tooltipItem.dataset.label} ${tooltipItem.formattedValue}`;
                }
            }
        }
    }
};

const FAKE_LABELS = ['9', '10', '11', '12', '13', '14', '15'];
const FAKE_DATA = {
    labels: FAKE_LABELS,
    datasets: [
        {
            label: 'Hourly',
            data: FAKE_LABELS.map(() =>
                faker.datatype.number({ min: 0, max: 500 })
            ),
            backgroundColor: COLORS.interface_dark_accent_01,
            barThickness: 8,
            borderWidth: 2,
            borderRadius: 4,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)'
        },
        {
            label: 'Average',
            data: FAKE_LABELS.map(() =>
                faker.datatype.number({ min: 0, max: 500 })
            ),
            backgroundColor: COLORS.interface_dark_accent_02,
            barThickness: 8,
            borderWidth: 2,
            borderRadius: 4
        }
    ]
};
const INIT_DATASET_SETTING = {
    barThickness: 3,
    borderWidth: 1,
    borderRadius: 1,
    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
    hoverBorderColor: 'rgba(255,99,132,1)'
};

type MultiBarChartProps = {
    data?: MultiBarChartDataT;
};
const MultiBarChart = (props: MultiBarChartProps) => {
    const { data } = props;
    const vOptions = data?.isCurrency === true ? options : nonCurrencyOptions;
    let displayData = FAKE_DATA;
   
    if (data)
        displayData = {
            labels: data.labels,
            datasets: data.datasets.map(d => {
                return { ...INIT_DATASET_SETTING, ...d };
            })
        };
    return <Bar options={vOptions} data={displayData} />;
};

export default MultiBarChart;
