import { createGlobalStyle } from 'styled-components';
import { ThemeTemplate } from 'src/constants/theme';
import { FOOTER_WRAP_HEIGHT } from 'src/constants/numbers';

/* eslint-disable */
declare module 'styled-components' {
    interface DefaultTheme extends ThemeTemplate {}
}
/* eslint-disable */

const GlobalStyles = createGlobalStyle`
    html {
      box-sizing: border-box;
    }
    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }

    #root {
      display: flex;
      height: 100%;
      width: 100%;
    }

    * {
      font-family: 'Inter', 'Noto Sans', sans-serif;
      // Firefox hide scrollbar
      scrollbar-width: none;
    }

    @font-face {
      font-family: 'MonsalGothic-ExtraBoldItalic';
      src: url(/fonts/monsal-gothic-bold-italic.otf) format('woff');
    }

   ::-webkit-scrollbar {
     // hide all scrollbar
      display: none;
    }
    
    html {
        height: 100%;
    }
    body {
      height: 100%;
      // background-color: ${({ theme }) => theme.body};
      // color: ${({ theme }) => theme.default_text_color};
      background-image: url(/images/shared/default-bg.png);
      background-size: cover;
      transition: all 0.50s linear;
      margin: 0px !important;
      overflow: hidden;
      color: white;
    }

  
    #bo-content-view {
      position: relative;
      flex: 1;
      padding: 2rem 4rem;
      width: 100%;
      max-width: 100%;
      height: 100vh;
      overflow: hidden;
      padding-bottom: calc(${FOOTER_WRAP_HEIGHT}px + 2rem);
      >div:first-child {
        height: 100%;
        overflow: hidden;
      }
  }
    
    #bo-content-view.loading {
        opacity: 0.25;
        transition: opacity 200ms;
        transition-delay: 200ms;
    }

    button {
      font-size: 1rem;
      font-family: inherit;
      border: none;
      border-radius: 8px;
      padding: 0.5rem 0.75rem;
      box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.2), 0 1px 2px hsla(0, 0%, 0%, 0.2);
      background-color: white;
      line-height: 1.5;
      margin: 0;
      cursor: pointer;
      color: #3992ff;
      font-weight: 500;
    }
    button:hover {
      box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.6), 0 1px 2px hsla(0, 0%, 0%, 0.2);
    }

    button:active {
      box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.4);
      transform: translateY(1px);
    }


    @keyframes spin {
      from {
          transform: rotate(0deg);
      }
      to {
          transform: rotate(360deg);
      }
    }

`;
export default GlobalStyles;

// .body-grid-layout {
//   width: 100%;
// @media (min-width: ${MIN_EXTRA_LARGE_CONTENT_WIDTH}) {
//   max-width: 1200px;;
// }
// }

// .body-grid-layout-margin {
// padding: 0px ${MOBILE_LF_PADDING};
// @media screen and (min-width: ${MIN_TABLET_CONTENT_WIDTH}) and ( max-width: 1560px) {
// padding: 0px ${TABLET_LF_PADDING};
// }
// @media screen and (min-width: 1560px) {
// padding: 0px ${DESKTOP_LF_PADDING};
// }
// }

// .gap-vertical {
// & > *:not(:first-child) {
// margin-top: ${SectionGap};
// }
// }
