import { ReactSVG } from 'react-svg';
import BaseModal from 'src/components/shared/modals/BaseModal';
import { CreateContainer } from '../UserMgmt/components/CreateUser';
import Dropdown from 'src/components/shared/Dropdown';
import { ChangeEvent, useContext, useState } from 'react';
import {
    generateWeeklyDateRange,
    generateWeeklyDateRangePreviousWeek,
    generateYears,
    getCurrentMonth
} from 'src/utils/utils';
import { Button } from 'src/components/shared/Button';
import { toast } from 'react-toastify';
import ClientIdBotIdContext from 'src/components/sidebar/clientIdBotIdContext';
import { downloadConvReport } from 'src/api/user';
import styled from 'styled-components';

type DownloadReportProps = {
    show: boolean;
    onClose: Function;
};

export default function DownloadReport({ show, onClose }: DownloadReportProps) {
    const { region, botId, clientId, timezone, accountId } =
        useContext(ClientIdBotIdContext);

    const [form, setForm] = useState({
        period: 'monthly',
        year: new Date().getFullYear().toString(),
        month: getCurrentMonth(),
        weekType: 'current',
        includeConversations: false
    });
    const [status, setStatus] = useState('UNINIT');

    const generateKey = (): string => {
        let key = '';
        if (form.period === 'monthly') {
            if (form.includeConversations) {
                key = `${accountId}/${clientId}/${botId}/${form.year}/${form.month}/conversations_conv.xlsx`;
            } else {
                key = `${accountId}/${clientId}/${botId}/${form.year}/${form.month}/conversations.xlsx`;
            }
        } else if (form.period === 'weekly') {
            if (form.weekType === 'current') {
                const {
                    startDateOfWeek,
                    endDateOfWeek,
                    year: weeklyYear
                } = generateWeeklyDateRange(timezone);
                if (form.includeConversations) {
                    key = `${accountId}/${clientId}/${botId}/${weeklyYear}/weekly/${startDateOfWeek}_${endDateOfWeek}/conversations_conv.xlsx`;
                } else {
                    key = `${accountId}/${clientId}/${botId}/${weeklyYear}/weekly/${startDateOfWeek}_${endDateOfWeek}/conversations.xlsx`;
                }
            } else {
                const {
                    startDateOfWeek,
                    endDateOfWeek,
                    year: weeklyYear
                } = generateWeeklyDateRangePreviousWeek(timezone);
                if (form.includeConversations) {
                    key = `${accountId}/${clientId}/${botId}/${weeklyYear}/weekly/${startDateOfWeek}_${endDateOfWeek}/conversations_conv.xlsx`;
                } else {
                    key = `${accountId}/${clientId}/${botId}/${weeklyYear}/weekly/${startDateOfWeek}_${endDateOfWeek}/conversations.xlsx`;
                }
            }
        }

        return key;
    };

    const downloadReport = () => {
        setStatus('IN_PROGRESS');
        downloadConvReport(generateKey(), region)
            .then(({ data }) => {
                window.open(data, '_blank');

                setStatus('SUCCESS');
                toast.success('Report download successfully.');
            })
            .catch(e => {
                setStatus('ERROR');
                toast.error('Report could not be downloaded');
            });
    };

    return (
        <BaseModal show={show}>
            <div
                style={{
                    margin: '0 auto',
                    maxHeight: '90vh',
                    overflowY: 'scroll'
                }}
            >
                <CreateContainer>
                    <div>
                        Download Report
                        <div className="divide-line" />
                        <ReactSVG
                            wrapper="span"
                            src={'/images/shared/close.svg'}
                            className="close-btn"
                            onClick={() => {
                                onClose();
                            }}
                        />
                    </div>
                    <ReportContainer>
                        <div className="dp-container">
                            <label>Period*</label>
                            <Dropdown
                                value={form.period}
                                onChange={(
                                    e: ChangeEvent<HTMLSelectElement>
                                ) => {
                                    setForm({
                                        ...form,
                                        period: e.target.value
                                    });
                                }}
                            >
                                <option value={'monthly'}>{'Monthly'}</option>
                                <option value={'weekly'}>{'Weekly'}</option>
                            </Dropdown>
                        </div>
                        {form.period === 'monthly' ? (
                            <>
                                <div className="dp-container">
                                    <label>Year*</label>
                                    <Dropdown
                                        value={form.year}
                                        onChange={(
                                            e: ChangeEvent<HTMLSelectElement>
                                        ) => {
                                            setForm({
                                                ...form,
                                                year: e.target.value
                                            });
                                        }}
                                    >
                                        <option>--Choose year--</option>
                                        {generateYears().map(y => (
                                            <option value={y} key={y}>
                                                {y}
                                            </option>
                                        ))}
                                    </Dropdown>
                                </div>
                                <div className="dp-container">
                                    <label>Month*</label>
                                    <Dropdown
                                        value={form.month}
                                        onChange={(
                                            e: ChangeEvent<HTMLSelectElement>
                                        ) => {
                                            setForm({
                                                ...form,
                                                month: e.target.value
                                            });
                                        }}
                                    >
                                        <option>--Choose month--</option>
                                        {(form.year === '2024'
                                            ? [
                                                  'April',
                                                  'May',
                                                  'June',
                                                  'July',
                                                  'August',
                                                  'September',
                                                  'October',
                                                  'November',
                                                  'December'
                                              ]
                                            : [
                                                  'January',
                                                  'February',
                                                  'March',
                                                  'April',
                                                  'May',
                                                  'June',
                                                  'July',
                                                  'August',
                                                  'September',
                                                  'October',
                                                  'November',
                                                  'December'
                                              ]
                                        ).map(y => (
                                            <option value={y} key={y}>
                                                {y}
                                            </option>
                                        ))}
                                    </Dropdown>
                                </div>
                            </>
                        ) : null}
                        {form.period === 'weekly' ? (
                            <div className="dp-container">
                                <label>Week type*</label>
                                <Dropdown
                                    value={form.weekType}
                                    onChange={(
                                        e: ChangeEvent<HTMLSelectElement>
                                    ) => {
                                        setForm({
                                            ...form,
                                            weekType: e.target.value
                                        });
                                    }}
                                >
                                    <option>--Choose weekly type--</option>
                                    <option value={'current'}>Current</option>
                                    <option value={'previous'}>Previous</option>
                                </Dropdown>
                            </div>
                        ) : null}
                        <div>
                            <input
                                id={'include-conversations'}
                                type="checkbox"
                                checked={form.includeConversations}
                                onChange={() => {
                                    setForm({
                                        ...form,
                                        includeConversations:
                                            !form.includeConversations
                                    });
                                }}
                            />
                            <label htmlFor={'include-conversations'}>
                                Include transcript
                            </label>
                        </div>
                        <Button
                            text={
                                status === 'IN_PROGRESS'
                                    ? 'Downloading...'
                                    : 'Download'
                            }
                            onClick={() => {
                                if (status !== 'IN_PROGRESS') {
                                    if (
                                        form.period === 'weekly' &&
                                        form.weekType
                                    ) {
                                        downloadReport();
                                    } else if (
                                        form.period === 'monthly' &&
                                        form.year &&
                                        form.month
                                    ) {
                                        downloadReport();
                                    } else {
                                        toast.warning(
                                            'Please make a selection to proceed to download.'
                                        );
                                    }
                                }
                            }}
                        />
                    </ReportContainer>
                </CreateContainer>
            </div>
        </BaseModal>
    );
}

const ReportContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;
