import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { ReactNode } from 'react';
import { COLORS } from 'src/constants/colors';

const PADDING_LEFT = 38;
export const PageWrap = styled.div`
    position: relative;
    // padding: 160px 160px 200px 160px;
    padding: 8% 160px 10% 160px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: auto;

    .fixed-footer {
        position: fixed;
        bottom: 0px;
    }
`;

export const Portion = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    min-height: 500px;

    @media (max-width: 1400px) {
        min-height: 700px;
    }

    > div:first-child {
        flex: 0.6;
    }

    > div:nth-child(2) {
        background: ${COLORS.interface_dard_fade};
        mix-blend-mode: overlay;
        width: 1px;
        height: 100%;
    }

    > div:nth-child(3) {
        flex: 0.4;
    }
`;

const HeaderWrap = styled.div`
    display: flex;
    align-items: center;
    height: 120px;
    width: 100%;
    background: ${COLORS.neutrals_black_2};
    padding-left: ${PADDING_LEFT}px;
    > img {
        width: 70px;
        height; 50px;
    }
    >p:nth-child(2) {
        color: white;
        font-weight: 900;
        padding-left: 20px;
        font-size: 2rem;
    }
    >p:nth-child(3) {
        color: white;
        font-weight: 300;
        padding-left: 10px;
        font-size: 2rem;
    }
`;
export const Header = () => {
    return (
        <HeaderWrap>
            <img alt="logo" src="/images/sidebar/logo.svg" />
            <p>VERSA</p>
            <p>CONNECTS</p>
        </HeaderWrap>
    );
};

const WELCOME_PADDING_SPACE = 24;
const WelcomeWrap = styled.div`
    color: white;
    background: linear-gradient(
        180deg,
        #001946 0%,
        #0c215a 32.81%,
        #001946 100%
    );
    .welcome-content {
        padding: ${WELCOME_PADDING_SPACE}px 40px 0px;
        > p {
            margin: 0px;
        }
        > p:first-child {
            padding-bottom: ${WELCOME_PADDING_SPACE}px ${PADDING_LEFT}px;
            font-size: 2rem;
        }
        > p:nth-child(2) {
            padding-bottom: ${WELCOME_PADDING_SPACE}px;
        }
        > p:nth-child(4) {
            padding: ${WELCOME_PADDING_SPACE}px 0px ${WELCOME_PADDING_SPACE}px;
        }

        .divide-line {
            background: ${({ theme }) => theme.interface_dark_glow};
            width: 100%;
            height: 1px;
        }

        > button {
            background: #001946;
            background: ${({ theme }) => theme.interface_dark_blue};
            border: 1px solid ${({ theme }) => theme.interface_dark_glow};
            border-radius: 4px;
            margin-bottom: 100px;
            > div {
                display: flex;
                gap: 10px;
                align-items: center;
            }
            .button-icon {
                margin-bottom: -5px;
            }
        }
    }
`;
export const Welcome = () => {
    return (
        <WelcomeWrap>
            <Header />
            <div className="welcome-content">
                <p>Welcome to the VERSA Connects Dashboard</p>
                <p>
                    The VERSA Connects Digital Workforce takes care of the
                    repetitive, low-value and high-volume interactions across
                    voice and chat channels —freeing up your human team to focus
                    on more meaningful customer conversations.
                </p>
                <div className="divide-line" />
                <p>
                    If you would like to request access to the VERSA Connects
                    Dashboard, please use the link below or contact a sales
                    representative via the website.
                </p>
                <button onClick={() => window.open('https://www.versaconnects.com/contact')}>
                    <div>
                        <ReactSVG
                            // alt={title}
                            wrapper="span"
                            className="button-icon"
                            src={'/images/shared/email.svg'}
                        />
                        Request access
                    </div>
                </button>
            </div>
        </WelcomeWrap>
    );
};

const LoginViewWrap = styled.div`
    color: white;
    background: linear-gradient(
        180deg,
        #001946 0%,
        #0c215a 32.81%,
        #001946 100%
    );
    .login-content {
        padding: ${WELCOME_PADDING_SPACE}px 40px 0px;
        > p {
            margin: 0px;
        }
        > p:first-child {
            padding-bottom: ${WELCOME_PADDING_SPACE}px ${PADDING_LEFT}px;
            font-size: 2rem;
        }
        > p:nth-child(2) {
            padding-bottom: ${WELCOME_PADDING_SPACE}px;
        }

        .input-wrap {
            display: flex;
            flex-direction: column;
            gap: 24px;
            padding-bottom: 10px;
        }

        .buttons {
            display: flex;
            justify-content: space-between;
            margin-top: 24px;
            > p {
                margin: 0px;
                color: ${({ theme }) => theme.primary_bule};
                cursor: pointer;
            }
            > button {
                background: ${COLORS.interface_button_default};
                box-shadow: 0px 0px 2px #000000;
                border-radius: 4px;
                color: white;
            }
        }
        > button {
            background: #001946;
            background: ${({ theme }) => theme.interface_dark_blue};
            border: 1px solid ${({ theme }) => theme.interface_dark_glow};
            border-radius: 4px;
            margin-bottom: 100px;
            > div {
                display: flex;
                gap: 10px;
                align-items: center;
            }
            .button-icon {
                margin-bottom: -5px;
            }
        }
        .error-msg {
            margin: 0;
            color: ${COLORS.interface_dark_good};
            font-size: 12px;
        }
    }
`;
export const LoginView = ({ children }: { children: ReactNode }) => {
    return (
        <LoginViewWrap>
            <HeaderWrap />
            <div className="login-content">{children}</div>
        </LoginViewWrap>
    );
};
