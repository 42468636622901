import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import BaseModal from 'src/components/shared/modals/BaseModal';
import { COLORS } from 'src/constants/colors';
import { Input } from 'src/components/shared/Inputs';
import { Button } from '../Button';
import { Auth } from 'aws-amplify';

type EditProfileModalProps = {
    show: boolean;
    onClose: () => void;
};
const INIT_FORM_STATE = {
    companyName: '',
    contactName: '',
    contactNumber: '',
    website: ''
};
const EditProfileModal = ({ show, onClose }: EditProfileModalProps) => {
    const [formState, setFormState] = useState(INIT_FORM_STATE);
    useEffect(() => {
        const fetchUser = async () => {
            const user = await Auth.currentAuthenticatedUser();
            if (user !== null) {
                setFormState({
                    companyName: user.attributes['custom:company'],
                    contactName: user.attributes.name,
                    contactNumber: user.attributes.phone_number,
                    website: user.attributes.website
                });
            }
        };
        fetchUser().catch(console.error);
    }, []);
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setFormState({ ...formState, [name]: value });
    };
    async function updateUser() {
        const { companyName, contactName, contactNumber, website } = {
            ...formState
        };
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
            'custom:company': companyName,
            name: contactName,
            phone_number: contactNumber,
            website: website
        });
        onClose();
    }
    return (
        <BaseModal show={show}>
            <EditProfileWrap>
                <div>
                    Edit Your Details
                    <ReactSVG
                        wrapper="span"
                        src={'/images/shared/close.svg'}
                        className="close-btn"
                        onClick={onClose}
                    />
                </div>
                <div>
                    <div className="edit-profile-logo">
                        <div className="user-info-logo">
                            <ReactSVG
                                wrapper="span"
                                src={'/images/shared/logo.svg'}
                            />
                        </div>
                        Replace Logo
                    </div>
                    <div className="inputs">
                        <Input
                            title="Company Name"
                            name="companyName"
                            onChange={handleInputChange}
                            value={formState.companyName}
                            placeholder="Company Name"
                        />
                        <Input
                            title="Contact Name"
                            name="contactName"
                            onChange={handleInputChange}
                            value={formState.contactName}
                            placeholder="Contact Name"
                        />
                        <Input
                            title="Contact Number"
                            name="contactNumber"
                            onChange={handleInputChange}
                            value={formState.contactNumber}
                            placeholder="Contact Number"
                        />
                        <Input
                            title="Website"
                            name="website"
                            onChange={handleInputChange}
                            value={formState.website}
                            placeholder="Website"
                        />
                        <Button
                            classname="edit-profile-update"
                            text="Update"
                            onClick={updateUser}
                        />
                    </div>
                </div>
            </EditProfileWrap>
        </BaseModal>
    );
};

export default EditProfileModal;

const EditProfileWrap = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: ${COLORS.interface_dark_gradient_01};
    border: 1px solid ${COLORS.Interface_dark_glow};

    > div {
        padding: 20px;
    }
    > div:first-child {
        position: relative;
        background: black;
        > span {
            svg {
                height: 20px;
                rect {
                    fill: ${COLORS.primary_blue};
                }
            }
        }

        .close-btn {
            position: absolute;
            right: 20px;
            cursor: pointer;
        }
    }
    > div:nth-child(2) {
        display: flex;
        gap: 20px;
    }

    .edit-profile-logo {
        position: relative;
        color: ${COLORS.primary_blue};
        text-align: center;
        > div {
            padding: 15px;
            background: white;
            border-radius: 50%;
            width: 120px;
            height: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            > span {
                svg {
                    height: 50px;
                    width: 50px;
                }
            }
        }
    }

    .inputs {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 16px;
        input {
            width: 30vw;
            max-width: 460px;
        }
    }

    .edit-profile-update {
        margin: 20px 0px 40px;
        display: block;
        width: 110px;
        text-align: center;
        padding: 12px 24px;
    }
`;
