import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BaseModal from 'src/components/shared/modals/BaseModal';
import styled from 'styled-components';
import { COLORS } from 'src/constants/colors';
import { ReactSVG } from 'react-svg';
import { useCallHistory } from '../hooks';
import { WithDateRangeTitle } from 'src/components/dashboard/Views';
import moment from 'moment';
import { CallLogWrap } from 'src/components/dashboard/CallLogPagination';
import { InvoiceContentWrap } from '..';

import Pagination from 'src/components/shared/pagination/Pagination';
import ClientIdBotIdContext from 'src/components/sidebar/clientIdBotIdContext';
import { getLocaleDateTimeString } from 'src/utils/utils';

type CallHistoryProps = {
    ClientId: string;
    InvoiceId: string;
    onClose: () => void;
};

export default function CallHistory({
    ClientId,
    InvoiceId,
    onClose
}: CallHistoryProps): JSX.Element {
    const [conversationDate, setConversationDate] = useState({
        startDate: moment().subtract(1, 'month'),
        endDate: moment()
    });
    // const [current, setCurrent] = useState(1);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const { clientId, timezone } = useContext(ClientIdBotIdContext);
    const { status, data, error, count, fetchFn } = useCallHistory() as {
        status: string;
        data: Array<{
            _id: string;
            callId: string;
            ingressCallingNumber: string;
            egressCalledNumber: string;
            setupTime: string;
            durationSeconds: number;
            clientId: string;
            botId: string;
            cost: number;
        }>;
        error: string;
        count: number;
        fetchFn: Function;
    };

    useEffect(() => {
        fetchFn(
            clientId,
            pageIndex,
            conversationDate.startDate,
            conversationDate.endDate,
            timezone
        );
    }, [pageIndex]);

    useEffect(() => {
        if (status === 'SUCCESS') {
            setPageCount(Math.ceil(count / 50));
        }
    }, [status]);

    let body = (<></>) as JSX.Element | Array<JSX.Element>;

    if (status === 'IN_PROGRESS') {
        body = <p>Loading...</p>;
    } else if (status === 'SUCCESS') {
        body = data.map(d => (
            <InvoiceContentWrap key={d._id}>
                <div>
                    <span>{d.ingressCallingNumber}</span>
                </div>
                <div>
                    <span>{d.egressCalledNumber}</span>
                </div>
                <div>
                    <span>
                        {getLocaleDateTimeString(d.setupTime, '', timezone)}
                    </span>
                </div>
                <div>
                    <span>{d.durationSeconds}</span>
                </div>
                <div>
                    <span>{d.botId}</span>
                </div>
                <div>
                    <span>{d.cost}</span>
                </div>
            </InvoiceContentWrap>
        ));
    } else if (status === 'ERROR') {
        body = <p>{error}</p>;
    }

    return (
        <BaseModal show={!!(ClientId && InvoiceId)}>
            <div
                style={{
                    margin: '0 auto',
                    height: '70vh',
                    overflowY: 'scroll'
                }}
            >
                <CallHistoryWrap>
                    <Pagination
                        pageIndex={pageIndex}
                        pageCount={pageCount}
                        turnPage={setPageIndex}
                    >
                        <div>
                            Call History
                            <div className="divide-line" />
                            <WithDateRangeTitle
                                {...conversationDate}
                                setDates={setConversationDate}
                                childClassname="h-100 max-h-none"
                                customTitle={null}
                                customFilterViews={null}
                                hideIcon
                            >
                                <CallLogWrap>
                                    <InvoiceContentWrap>
                                        <div>
                                            <span>
                                                <strong>Calling Number</strong>
                                            </span>
                                        </div>
                                        <div>
                                            <span>
                                                <strong>Called Number</strong>
                                            </span>
                                        </div>
                                        <div>
                                            <span>
                                                <strong>Date Time</strong>
                                            </span>
                                        </div>
                                        <div>
                                            <span>
                                                <strong>
                                                    Duration (Seconds)
                                                </strong>
                                            </span>
                                        </div>
                                        <div>
                                            <span>
                                                <strong>
                                                    Digital Assistant
                                                </strong>
                                            </span>
                                        </div>
                                        <div>
                                            <span>
                                                <strong>Cost</strong>
                                            </span>
                                        </div>
                                    </InvoiceContentWrap>
                                    {body}
                                    {/* <Paginate
                                defaultPageSize={50}
                                defaultCurrent={current}
                                onShowSizeChange={(current, pageSize) => {}}
                                onChange={(current, pageSize) => {
                                    setCurrent(current);
                                }}
                                total={count}
                                containerStyle={{ marginBottom: '15%' }}
                            /> */}
                                </CallLogWrap>
                            </WithDateRangeTitle>
                            <ReactSVG
                                wrapper="span"
                                src={'/images/shared/close.svg'}
                                className="close-btn"
                                onClick={onClose}
                            />
                        </div>
                    </Pagination>
                </CallHistoryWrap>
            </div>
        </BaseModal>
    );
}

CallHistory.propTypes = {
    ClientId: PropTypes.string.isRequired,
    InvoiceId: PropTypes.string.isRequired
};

const CallHistoryWrap = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-width: 960px;
    width: 70vw;
    background: ${COLORS.neutrals_black_2};
    border: 1px solid ${COLORS.Interface_dark_glow};

    > div {
        padding: 20px;
    }
    > div:first-child {
        position: relative;
        background: ${COLORS.neutrals_black_2_noa};

        .close-btn {
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
        }
    }
    > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        background: ${COLORS.interface_dark_gradient_01};
        padding: 20px;
        .conversations-wrap {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-height: 60vh;
            overflow-y: scroll;
        }
    }

    .divide-line {
        background: ${({ theme }) => theme.interface_dark_glow};
        width: 100%;
        height: 1px;
        margin: 20px 0px;
    }
`;
