import BaseModal from 'src/components/shared/modals/BaseModal';
import { ReactSVG } from 'react-svg';
import { Button } from 'src/components/shared/Button';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { BodyContainer } from './EditTestCase';
import { useSaveTestCaseCategoryHook } from '../hooks';
import ClientIdBotIdContext from 'src/components/sidebar/clientIdBotIdContext';

export default function EditCategory({
    show,
    onClose
}: {
    show: boolean;
    onClose: Function;
}): JSX.Element {
    const { botId, region } = useContext(ClientIdBotIdContext);
    const [n, setN] = useState<string>();
    const { status, fn } = useSaveTestCaseCategoryHook() as {
        status: string;
        fn: Function;
    };

    return (
        <BaseModal show={show}>
            <div
                style={{
                    margin: '0 auto',
                    maxHeight: '90vh',
                    overflowY: 'scroll'
                }}
            >
                <BodyContainer>
                    <div>
                        {'New Category'}
                        <div className="divide-line" />
                        <ReactSVG
                            wrapper="span"
                            src={'/images/shared/close.svg'}
                            className="close-btn"
                            onClick={() => {
                                onClose();
                            }}
                        />
                    </div>
                    <div className="form-row-item flex-50">
                        <div className="dp-container">
                            <label>Category Name</label>
                            <input
                                type="text"
                                value={n}
                                placeholder="Enter category name"
                                onChange={e => setN(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="form-row-item flex-50">
                        <div>
                            <Button
                                text={
                                    status === 'IN_PROGRESS'
                                        ? 'Saving...'
                                        : 'Save'
                                }
                                onClick={() => {
                                    if (status !== 'IN_PROGRESS') {
                                        if (n) {
                                            // make API Call to save category
                                            fn(
                                                {
                                                    botId,
                                                    name: n
                                                },
                                                region,
                                                () => {
                                                    onClose();
                                                }
                                            );
                                        } else {
                                            toast.warning(
                                                'Please enter a category name to save.'
                                            );
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                </BodyContainer>
            </div>
        </BaseModal>
    );
}
