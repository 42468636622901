import styled from 'styled-components';
import { COLORS } from 'src/constants/colors';
import { FOOTER_WRAP_HEIGHT } from 'src/constants/numbers';

export const Footer = ({ classname }: { classname?: string }) => {
    return <FooterWrap className={classname}>2023 © VERSA Connects</FooterWrap>;
};

const FooterWrap = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: ${FOOTER_WRAP_HEIGHT}px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${COLORS.primary_blue};
    background: ${COLORS.interface_dark_darkblue};
`;
