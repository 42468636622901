import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { BillingDashboardWrap } from '../BillingDashboard';
import {
    Breadcrumb,
    ScrollArea,
    WithDateRangeTitle
} from 'src/components/dashboard/Views';
import { SWITCH_ROUTES } from 'src/constants/routes';
import moment from 'moment';
import { useDownloadInvoice, useInvoiceList } from './hooks';
import { parseDate, parseDateAndTime } from './common';
import { CallLogWrap } from '../../../components/dashboard/CallLogPagination';
import styled from 'styled-components';
import { COLORS } from 'src/constants/colors';
import CallHistory from './components/CallHistory';
import ViewInvoice from './components/ViewInvoice';
import ClientIdBotIdContext from 'src/components/sidebar/clientIdBotIdContext';
import { SIGNED_PDF_URL } from 'src/__mocks__';

type InvoiceT = {
    billGenerateDate: string;
    billPeriodStart: string;
    billPeriodEnd: string;
    billAmount: number;
    totalCalls: number;
    _id: string;
    clientId: string;
    invoiceNumber: string;
};

export const BREADCRUMB_ITEMS = [
    {
        text: 'Home',
        redirectUrl: SWITCH_ROUTES.DASHBOARD,
        svgSrc: '/images/sidebar/home.svg'
    },
    { text: 'Billing Dashboard', redirectUrl: SWITCH_ROUTES.DASHBOARD_BILLING },
    { text: 'Invoice', redirecUrl: '' }
];

export default function Invoice(): JSX.Element {
    const { clientId } = useContext(ClientIdBotIdContext);
    const [conversationDate, setConversationDate] = useState({
        startDate: moment().subtract(1, 'month'),
        endDate: moment()
    });
    const [showInvoiceDetail, setShowInvoiceDetail] = useState<InvoiceT | null>(
        null
    );
    const [showCallHistory, setShowCallHistory] = useState<InvoiceT | null>(
        null
    );

    const { status, data, error, fetchFn } = useInvoiceList() as {
        status: string;
        data: Array<InvoiceT>;
        error: string;
        fetchFn: Function;
    };

    useEffect(() => {
        if (clientId) {
            fetchFn(clientId);
        }
    }, [clientId]);

    const { fetchFn: donwloadInvoice } = useDownloadInvoice() as {
        status: string;
        fetchFn: Function;
    };

    let body: ReactElement | Array<ReactElement> = <></>;

    if (status === 'IN_PROGRESS') {
        body = <p>Loading...</p>;
    } else if (status === 'SUCCESS') {
        body = data.length ? (
            data.map(d => (
                <InvoiceContentWrap key={d._id}>
                    <div>
                        <span>{parseDate(d.billGenerateDate)}</span>
                    </div>
                    <div>
                        <span>{parseDate(d.billPeriodStart)}</span>
                    </div>
                    <div>
                        <span>{parseDate(d.billPeriodEnd)}</span>
                    </div>
                    <div>
                        <span>${(d.billAmount/100).toFixed(2)}</span>
                    </div>
                    <div>
                        <span>{d.totalCalls}</span>
                    </div>
                    <div>
                        {/* <span
                            onClick={() => {
                                // get call history
                                setShowCallHistory(d);
                            }}
                        >
                            Call History
                        </span>
                        &nbsp; &nbsp; */}
                        <span
                            onClick={() => {
                                // view Invoice
                                setShowInvoiceDetail(d);
                            }}
                        >
                            View
                        </span>
                        &nbsp;&nbsp;
                        <span
                            onClick={() => {
                                // write download action later.
                                donwloadInvoice(d.clientId, d.invoiceNumber);
                            }}
                        >
                            Download
                        </span>
                    </div>
                </InvoiceContentWrap>
            ))
        ) : (
            <p>No Invoice found!</p>
        );
    } else if (status === 'ERROR') {
        body = <p>{error}</p>;
    }

    return (
        <>
            <BillingDashboardWrap>
                <Breadcrumb items={BREADCRUMB_ITEMS} />
                <ScrollArea>
                    <WithDateRangeTitle
                        {...conversationDate}
                        hideDateRange={true}
                        setDates={setConversationDate}
                        childClassname="h-100 max-h-none"
                        customTitle={<PageTitle>Invoice List</PageTitle>}
                        customFilterViews={null}
                        hideIcon
                        noFilter={true}
                    >
                        <CallLogWrap>
                            <InvoiceContentWrap>
                                <div>
                                    <span>
                                        <strong>Bill Generate Date</strong>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <strong>Bill Period Start</strong>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <strong>Bill Period End</strong>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <strong>Bill Amount</strong>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <strong>Total Calls</strong>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <strong>Action</strong>
                                    </span>
                                </div>
                            </InvoiceContentWrap>
                            {body}
                        </CallLogWrap>
                    </WithDateRangeTitle>
                </ScrollArea>
                {showCallHistory ? (
                    <CallHistory
                        ClientId={showCallHistory?.clientId}
                        InvoiceId={showCallHistory?.invoiceNumber}
                        onClose={() => {
                            setShowCallHistory(null);
                        }}
                    />
                ) : null}
                {showInvoiceDetail ? (
                    <ViewInvoice
                        ClientId={showInvoiceDetail?.clientId}
                        InvoiceId={showInvoiceDetail?.invoiceNumber}
                        onClose={() => {
                            setShowInvoiceDetail(null);
                        }}
                    />
                ) : null}
            </BillingDashboardWrap>
        </>
    );
}

export const PageTitle = styled.div`
    padding: 14px 20px;
    color: white;
    font-size: 1.4rem;
    padding: 14px 20px;
    background: ${COLORS.neutrals_black_2};
`;

export const InvoiceContentWrap = styled.div<{ hideMoreImg?: boolean }>`
    display: flex;
    align-items: flex-start;
    gap: 10px;
    color: white;
    > div {
        cursor: pointer;
    }
    > div:first-child {
        width: 16%;
    }
    > div:nth-child(2) {
        width: 16%;
    }
    > div:nth-child(3) {
        width: 16%;
    }
    > div:nth-child(4) {
        width: 16%;
        display: flex;
        gap: 8px;
        white-space: nowrap;
        > span:first-child {
            margin-top: 2px;
        }
    }
    > div:nth-child(5) {
        position: relative;
        width: 16%;
    }
    > div:nth-child(6) {
        position: relative;
        width: 16%;

        > span:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .duration-wrap {
        position: relative;
        display: flex;
        padding-left: 25px;
        flex-direction: column;

        > span:first-child {
            position: absolute;
            left: 0px;
            top: 5px;
            max-width: 25px;

            svg {
                path {
                    fill: ${COLORS.primary_blue};
                }
            }
        }
    }

    .more-svg {
        position: absolute;
        right: 20px;
    }
`;
