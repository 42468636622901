import { useState } from 'react';
import {
    getUsers,
    getUserDetail,
    getMasterAccounts,
    saveUser,
    updateUser
} from '../../api/user';
import { toast } from 'react-toastify';

export const useGetUserAccounts = () => {
    const [status, setStatus] = useState('UNINIT');
    const [data, setData] = useState({});
    const [error, setError] = useState<string>();

    const fn = async (rgn: string | undefined) => {
        setStatus('IN_PROGRESS');
        try {
            const users = await getUsers(rgn || '');
            if (users?.code === 200) {
                setData(users.data);
                setStatus('SUCCESS');
            } else {
                setError('Could not get users.');
                setStatus('ERROR');
            }
        } catch (e: any) {
            setError(e.message);
            setStatus('ERROR');
        }
    };

    return {
        status,
        data,
        error,
        fn
    };
};

export const useCreateUser = () => {
    const [status, setStatus] = useState('UNINIT');

    const fn = async (payload: any, cb: () => {}, rgn: string | undefined) => {
        setStatus('IN_PROGRESS');
        try {
            const users = await saveUser(payload, rgn || '');
            if (users?.code === 200) {
                if (cb) {
                    cb();
                }
            } else {
                toast.error('Could not save users.');
                setStatus('ERROR');
            }
        } catch (e: any) {
            toast.error(e.message);
            setStatus('ERROR');
        }
    };

    return {
        status,
        fn
    };
};

export const useGetUserDetail = () => {
    const [status, setStatus] = useState('UNINIT');
    const [data, setData] = useState();
    const [error, setError] = useState<string>();

    const fn = async (
        userId: string,
        setForm: Function,
        rgn: string | undefined
    ) => {
        setStatus('IN_PROGRESS');
        try {
            const users = await getUserDetail(userId, rgn || '');
            if (users?.code === 200) {
                setData(users.data);
                setForm(users.data);
                setStatus('SUCCESS');
            } else {
                setError('Could not get user detail.');
                setStatus('ERROR');
            }
        } catch (e: any) {
            setError(e.message);
            setStatus('ERROR');
        }
    };

    return {
        status,
        data,
        error,
        fn
    };
};

export const useUpdateUser = () => {
    const [status, setStatus] = useState('UNINIT');

    const fn = async (
        userId: string,
        payload: any,
        cb: () => void,
        rgn: string | undefined
    ) => {
        setStatus('IN_PROGRESS');
        try {
            const users = await updateUser(userId, payload, rgn || '');
            if (users?.code === 200) {
                setStatus('SUCCESS');
                toast.success('User updated successfully.');
                if (cb) {
                    cb();
                }
            } else {
                toast.error('Could not update user.');
                setStatus('ERROR');
            }
        } catch (e: any) {
            toast.error(e.message);
            setStatus('ERROR');
        }
    };

    return {
        status,
        fn
    };
};

export const useGetMasterAccounts = () => {
    const [status, setStatus] = useState('UNINIT');
    const [data, setData] = useState();
    const [error, setError] = useState<string>();

    const fn = async (rgn: string | undefined) => {
        setStatus('IN_PROGRESS');
        try {
            const users = await getMasterAccounts(rgn || '');
            if (users?.code === 200) {
                setData(users.data);
                setStatus('SUCCESS');
            } else {
                setError('Could not get master accounts.');
                setStatus('ERROR');
            }
        } catch (e: any) {
            setError(e.message);
            setStatus('ERROR');
        }
    };

    return { status, data, error, fn };
};
