import { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { COLORS } from 'src/constants/colors';

const Eye = () => <ReactSVG wrapper="span" src={'/images/shared/eye.svg'} />;

const EveyIconWrap = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;

    > input {
        width: 100%;
        padding: 10px 25px 10px 10px;
    }
    .input-title {
        margin: 0px;
        width: 100%;
        color: ${COLORS.primary_blue};
    }
    .eye-icon {
        position: absolute;
        right: 5px;
        cursor: pointer;
    }
`;

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    leadingView?: ReactNode;
    title?: string;
};
export const Input = (props: InputProps) => {
    const { leadingView, title, type, className, ...rest } = props;
    const [showPassword, setShowPassword] = useState(false);
    const togglePassword = () => setShowPassword(prev => !prev);
    const typeIsPassword = type === 'password';

    return (
        <EveyIconWrap className={className}>
            {leadingView}
            {title && <p className="input-title">{title}</p>}
            <input
                {...(rest as any)}
                type={typeIsPassword && showPassword ? 'text' : type}
            />
            {typeIsPassword && (
                <div className="eye-icon" onClick={togglePassword}>
                    <Eye />
                </div>
            )}
        </EveyIconWrap>
    );
};
