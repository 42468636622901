import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { COLORS } from 'src/constants/colors';
import { Z_INDEXS } from 'src/constants/numbers';

type DateRangePickerT = {
    startDate: Date;
    endDate: Date;
    onChange: any;
    minDate?: Date;
    maxDate?: Date;
    maxDateSetting: any;
};
export const DateRangePicker = (props: DateRangePickerT) => {
    const {
        startDate,
        endDate,
        onChange,
        minDate,
        maxDate,
        maxDateSetting,
        ...others
    } = props;
    const [dateRange, setDateRange] = useState([startDate, endDate]);
    const [minDateRangeSetting, setMinDateRangeSetting] = useState(minDate);
    const [maxDateRangeSetting, setMaxDateRangeSetting] = useState(maxDate);
    const [startOfRange, endOfRange] = dateRange;
    let isSelected = false;

    const onDateChange = (dates: any) => {
        isSelected = true;
        setDateRange(dates);
        const [start, end] = dates;

        // set max date
        if (maxDateSetting && isSelected && start) {
            const { amount, unit } = maxDateSetting;
            const getMaxDate = moment(start).add(amount, unit);
            const getMaxDateSetting = getMaxDate.isAfter(moment(maxDate))
                ? maxDate
                : getMaxDate.toDate();

            setMaxDateRangeSetting(getMaxDateSetting);
        }

        // set raw min and max date
        if (start && end) {
            isSelected = false;
            onChange(dates);
            setMinDateRangeSetting(minDate);
            setMaxDateRangeSetting(maxDate);
        }
    };

    const onClickOutside = () => {
        if (!startOfRange || !endOfRange) {
            const yesterday = moment().subtract(1, 'day').toDate();
            setDateRange([yesterday, yesterday]);
            setMinDateRangeSetting(minDate);
            setMaxDateRangeSetting(maxDate);
        }
    };

    useEffect(() => {
        setDateRange([startDate, endDate]);
    }, [startDate, endDate]);

    useEffect(() => {
        setMinDateRangeSetting(minDate);
    }, [minDate]);

    useEffect(() => {
        setMaxDateRangeSetting(maxDate);
    }, [maxDate]);

    return (
        <DatePickerWrapper>
            <ReactSVG wrapper="span" src={'/images/shared/calendar.svg'} />

            <DatePicker
                startDate={startOfRange}
                endDate={endOfRange}
                onChange={onDateChange}
                minDate={minDateRangeSetting}
                maxDate={maxDateRangeSetting}
                onClickOutside={onClickOutside}
                // monent={moment}
                dateFormat="yyyy/MM/dd"
                selectsRange
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
                    e.preventDefault()
                }
                {...others}
            />
        </DatePickerWrapper>
    );
};

const DatePickerWrapper = styled.div`
    display: inline-flex;
    padding: 6px 8px;
    background: ${COLORS.interface_dark_darkblue};
    border: 1px solid ${COLORS.Interface_dark_glow};
    backdrop-filter: blur(2px);
    border-radius: 4px;
    gap: 6px;
    z-index: ${Z_INDEXS.REACT_DATEPICKER_POPPER};

    svg {
        height: 17px;
        width: 17px;
        margin-bottom: -2px;
    }

    input {
        min-width: 200px !important;
        background: transparent;
        color: white;
        border: none;
    }
    // .react-datepicker-popper {
    //     z-index: ${Z_INDEXS.REACT_DATEPICKER_POPPER};
    // }
`;
