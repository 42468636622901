import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { cloneDeep, groupBy } from 'lodash';
import { ReactSVG } from 'react-svg';
// import { Auth } from 'aws-amplify';
import {
    OverviewCard,
    PureTextContent
} from 'src/components/dashboard/OverviewCard';
import { CallLogT, parseCallLog } from 'src/components/dashboard/CalllogsView';
import { COLORS } from 'src/constants/colors';
import {
    Breadcrumb,
    ScrollArea,
    WithDateRangeTitle,
    WithTitleAndViewing
} from 'src/components/dashboard/Views';
import DoughnutChart from 'src/components/dashboard/DoughnutChart';
import MultiBarChart from 'src/components/dashboard/MultiBarChart';
import { SWITCH_ROUTES } from 'src/constants/routes';
// import LoadingView from 'src/components/shared/LoadingView';
import {
    AnalyticsResT,
    MultiBarChartDataT,
    CallAggregatesTypes,
    HourlyCallAggregatesT,
    PureTextContentItemT
} from 'src/types/dashboardT';
import CallLogPagination from 'src/components/dashboard/CallLogPagination';
import HighchartsSankeyPage, {
    SankeyChartDataT
} from 'src/components/shared/HighchartsSankeyChart';
import {
    getConversations,
    getSankeyFlow,
    getAnalytics
} from 'src/api/dashboard';
import CoversactionDetailModal from 'src/components/dashboard/CoversactionDetailModal';
import { round, secondsToTimeStr } from 'src/utils/utils';
import { Input } from 'src/components/shared/Inputs';
import Dropdown from 'src/components/shared/Dropdown';
import { BUTTONS } from './BillingDashboard';
import ClientIdBotIdContext from 'src/components/sidebar/clientIdBotIdContext';
import LoadingView from 'src/components/shared/LoadingView';
import { getLocaleDateTimeString } from 'src/utils/utils';
import { LOCALE_TZ_FORMAT } from 'src/utils/constants';
import { Button } from 'src/components/shared/Button';
import DownloadReport from './DownloadReport';

const BREADCRUMB_ITEMS = [
    {
        text: 'Home',
        redirectUrl: SWITCH_ROUTES.DASHBOARD,
        svgSrc: '/images/sidebar/home.svg'
    },
    { text: 'Reporting & Analytics', redirectUrl: '' }
];
type hourlyCallAggItem = {
    date: string;
};
type SankeyResT = {
    from: string;
    to: string;
    count: number;
}[];

type UnitDataT = {
    [k: string]: {
        totalCost: number;
        totalConversation: number;
        totalDuration: number;
    };
};
export function getLineChartDataFromCallAggregates(
    timezone: string,
    hourlyCallAggregates?: HourlyCallAggregatesT
) {
    if (!hourlyCallAggregates) return null;

    const hourlyCalls = hourlyCallAggregates
        .filter(d => d.type === CallAggregatesTypes['1HOUR'])
        .map(d => {
            const date = d.date;
            const momentD = moment(date, LOCALE_TZ_FORMAT[timezone]);
            return { ...d, date, hour: momentD.hour() };
        });
    const hourlyCallsGroupByHour = groupBy(hourlyCalls, d => d.hour);
    let hourlyData: UnitDataT = {};
    Object.keys(hourlyCallsGroupByHour).forEach(k => {
        hourlyData[k] = hourlyCallsGroupByHour[k].reduce(
            (prev, c) => {
                return {
                    totalCost: prev.totalCost + c.totalAccountWiseCost,
                    totalConversation:
                        prev.totalConversation + c.totalConversation,
                    totalDuration: prev.totalDuration + c.totalDuration
                };
            },
            { totalCost: 0, totalConversation: 0, totalDuration: 0 }
        );
    });

    return {
        labels: Object.keys(hourlyCallsGroupByHour),
        datasets: [
            {
                label: 'Calls',
                data: Object.keys(hourlyData).map(
                    k => hourlyData[k].totalConversation
                ),
                backgroundColor: COLORS.interface_dark_accent_01,
                borderColor: COLORS.interface_dark_accent_01,
                yAxisID: 'y1',
                type: 'bar'
            }
        ],
        isCurrency: false
    };
}

const QUICK_SORT_DROPDOWN_LIST = [
    { key: 'DateNTO', value: 'DateNTO', text: 'Date (Newest to Oldest)' },
    { key: 'DateOTN', value: 'DateOTN', text: 'Date (Oldest to Newest)' }
];
export default function ReportAndAnalytics() {
    const { botId, region, timezone } = useContext(ClientIdBotIdContext);
    const [sankeyData, setSankeyData] = useState<SankeyChartDataT | []>([]);
    const [analyticsData, setAnalyticsData] = useState<AnalyticsResT | null>(
        null
    );
    const [hourlyLineChartData, setHourlyLineChartData] =
        useState<MultiBarChartDataT | null>(null);
    const [fullCoversationsData, setFullCoversationsData] = useState<
        CallLogT[] | []
    >([]);
    const [filterCoversations, setFilterCoversations] = useState<
        CallLogT[] | []
    >([]);
    const [coversationSearchKeyword, setCoversationSearchKeyword] =
        useState('');
    const [selectedQuickSort, setSelectedQuickSort] = useState(
        QUICK_SORT_DROPDOWN_LIST[0].value
    );
    const [sankeyDate, setSankeyDate] = useState({
        startDate: moment().subtract(1, 'month'),
        endDate: moment()
    });
    const [conversationDate, setConversationDate] = useState({
        startDate: moment().subtract(1, 'month'),
        endDate: moment()
    });
    const [selectedCallLog, setSelectedCallLog] = useState<CallLogT | null>(
        null
    );
    const [downloadReportPopup, setDownloadReportPopup] =
        useState<boolean>(false);

    const [fetchSankeyFlowStatus, setFetchSankeyFlowStatus] =
        useState('UNINIT');
    const [fetchAnalyticsStatus, setFetchAnalyticsStatus] = useState('UNINIT');
    const [fetchConvStatus, setFetchConvStatus] = useState('UNINIT');

    const fetchSankeyFlow = async () => {
        // const user = await Auth.currentAuthenticatedUser();
        setFetchSankeyFlowStatus('IN_PROGRESS');
        const res = await getSankeyFlow(
            {
                botid: botId,
                fromdate: sankeyDate.startDate,
                todate: sankeyDate.endDate
            },
            region
        );
        setFetchSankeyFlowStatus('SUCCESS');
        const { code, data } = res;
        if (code === 200) {
            const sankeyD = (data as SankeyResT).reduce((prev: any, d) => {
                return [...prev, [d.from, d.to, d.count]];
            }, []);
            setSankeyData(sankeyD);
        } else setSankeyData([]);
    };
    const fetchAnalytics = async () => {
        // const user = await Auth.currentAuthenticatedUser();
        setFetchAnalyticsStatus('IN_PROGRESS');
        const res = await getAnalytics(
            {
                botid: botId,
                fromdate: moment().startOf('month'),
                todate: moment()
            },
            region
        );
        setFetchAnalyticsStatus('SUCCESS');

        if (res) {
            const { code, data } = res;
            if (code === 200) {
                data.hourlyCallAggregates.forEach((c: hourlyCallAggItem) => {
                    c.date = getLocaleDateTimeString(c.date, botId, timezone);
                });

                setAnalyticsData(data);
            } else setAnalyticsData(null);
        }
    };
    const fetchConversations = async () => {
        // const user = await Auth.currentAuthenticatedUser();
        setFetchConvStatus('IN_PROGRESS');
        const res = await getConversations(
            {
                botid: botId,
                fromdate: conversationDate.startDate,
                todate: conversationDate.endDate
            },
            region
        );
        setFetchConvStatus('SUCCESS');
        const { code, data } = res;
        if (code === 200) {
            setFullCoversationsData(
                data.map((d: any) => parseCallLog(d, botId, timezone))
            );
        } else setFullCoversationsData([]);
    };

    useEffect(() => {
        if (botId && region) {
            fetchAnalytics();
        }
    }, [botId, region]);

    useEffect(() => {
        if (botId && region) {
            fetchSankeyFlow();
        }
    }, [sankeyDate, botId, region]);

    useEffect(() => {
        if (botId && region) {
            fetchConversations();
        }
    }, [conversationDate, botId, region]);

    useEffect(() => {
        setHourlyLineChartData(
            getLineChartDataFromCallAggregates(
                timezone,
                analyticsData?.hourlyCallAggregates
            )
        );
    }, [analyticsData]);
    useEffect(() => {
        let newList = cloneDeep(fullCoversationsData);
        if (coversationSearchKeyword) {
            newList = fullCoversationsData.filter(
                c =>
                    c.displayStartTime?.includes(coversationSearchKeyword) ||
                    c.callingNumber?.includes(coversationSearchKeyword) ||
                    c.typeText?.includes(coversationSearchKeyword) ||
                    `${c.messagesCount}` === coversationSearchKeyword ||
                    c.callStatusText?.includes(coversationSearchKeyword) ||
                    c.details?.some(d =>
                        d.text.includes(coversationSearchKeyword)
                    ) ||
                    c.conversationId?.includes(coversationSearchKeyword) ||
                    c.clientCallID?.includes(coversationSearchKeyword)
            );
        }

        if (selectedQuickSort === QUICK_SORT_DROPDOWN_LIST[0].value) {
            newList = newList.sort((a, b) => {
                if (a.startDate && b.startDate)
                    return parseInt(b.startDate) - parseInt(a.startDate);
                else return 1;
            });
        } else if (selectedQuickSort === QUICK_SORT_DROPDOWN_LIST[1].value) {
            newList = newList.sort((a, b) => {
                if (a.startDate && b.startDate)
                    return parseInt(a.startDate) - parseInt(b.startDate);
                else return 1;
            });
        }

        setFilterCoversations(newList);
    }, [coversationSearchKeyword, fullCoversationsData, selectedQuickSort]);

    const renderCallLogSection = () => {
        return (
            <WithDateRangeTitle
                {...conversationDate}
                setDates={setConversationDate}
                childClassname="h-100 max-h-none"
                customTitle={
                    <SankeyTitle onClick={() => {}}>
                        <div>
                            Call Log
                            {/** Download Report Button */}
                            <Button
                                text="Download Report"
                                onClick={() => {
                                    // load a dialog for download options
                                    setDownloadReportPopup(true);
                                }}
                            />
                        </div>

                        <div className="divide-line" />
                    </SankeyTitle>
                }
                customFilterViews={
                    <>
                        <Input
                            title=""
                            className="search-input-wrap"
                            name="searchType"
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                setCoversationSearchKeyword(event.target.value)
                            }
                            placeholder="Search (Type here)"
                            leadingView={
                                <ReactSVG
                                    wrapper="span"
                                    className="search-input-icon"
                                    src={'/images/shared/search.svg'}
                                />
                            }
                            value={coversationSearchKeyword}
                        />
                        <Dropdown
                            value={selectedQuickSort}
                            onChange={(e: any) =>
                                setSelectedQuickSort(e.target.value)
                            }
                        >
                            {!!QUICK_SORT_DROPDOWN_LIST &&
                                QUICK_SORT_DROPDOWN_LIST.map((c: any) => (
                                    <option key={c.key} value={c.value}>
                                        {c.text}
                                    </option>
                                ))}
                        </Dropdown>
                    </>
                }
                hideIcon
            >
                <CallLogPagination
                    items={filterCoversations}
                    onItemClick={setSelectedCallLog}
                />
            </WithDateRangeTitle>
        );
    };

    const renderReportDetail = () => {
        const doughnutD = {
            labels: ['Handover', 'Resolved'],
            datasets: analyticsData
                ? [
                      {
                          data: [
                              analyticsData?.totalHandover,
                              analyticsData?.totalResolved
                          ],
                          backgroundColor: [
                              COLORS.interface_dark_accent_01,
                              COLORS.interface_dark_accent_02
                          ]
                      }
                  ]
                : []
        };
        const durationD: PureTextContentItemT[] = [
            {
                key: 'Total Duration',
                value: analyticsData
                    ? secondsToTimeStr(analyticsData.totalDuration)
                    : ''
                // arrow: 'down'
            },
            {
                key: 'Total',
                subItems: [
                    {
                        key: 'today',
                        value: analyticsData
                            ? secondsToTimeStr(analyticsData.todayDurationToday)
                            : ''
                        // arrow: 'down'
                    },
                    {
                        key: 'this week',
                        value: analyticsData
                            ? secondsToTimeStr(
                                  analyticsData.todayDurationThisWeek
                              )
                            : ''
                        // arrow: 'down'
                    },
                    {
                        key: 'this month',
                        value: analyticsData
                            ? secondsToTimeStr(
                                  analyticsData.todayDurationThisMonth
                              )
                            : ''
                        // arrow: 'up'
                    }
                ]
            }
        ];
        return (
            <WithTitleAndViewing title="" buttons={BUTTONS} hideTitle selectAll>
                <div className="report-detail">{renderCallLogSection()}</div>
            </WithTitleAndViewing>
        );
    };

    return (
        <ReportAndAnalyticsWrap>
            <Breadcrumb items={BREADCRUMB_ITEMS} />
            {/* {true ? ( */}
            {fetchSankeyFlowStatus === 'IN_PROGRESS' ||
            fetchAnalyticsStatus === 'IN_PROGRESS' ||
            fetchConvStatus === 'IN_PROGRESS' ? (
                <LoadingView />
            ) : fetchSankeyFlowStatus === 'SUCCESS' &&
              fetchAnalyticsStatus === 'SUCCESS' &&
              fetchConvStatus === 'SUCCESS' ? (
                <ScrollArea>
                    <WithTitleAndViewing
                        title=""
                        buttons={BUTTONS}
                        hideTitle
                        selectAll
                    >
                        <WithDateRangeTitle
                            {...sankeyDate}
                            setDates={setSankeyDate}
                            childClassname="h-500"
                            customTitle={
                                <SankeyTitle onClick={() => {}}>
                                    Sankey Chart <div className="divide-line" />
                                </SankeyTitle>
                            }
                            hideIcon
                        >
                            <HighchartsSankeyPage data={sankeyData} />
                        </WithDateRangeTitle>
                    </WithTitleAndViewing>

                    {renderReportDetail()}
                </ScrollArea>
            ) : null}

            {/* ) : (
                <LoadingView />
            )} */}
            {selectedCallLog ? (
                <CoversactionDetailModal
                    item={selectedCallLog}
                    onClose={() => setSelectedCallLog(null)}
                    showNavBtns={true}
                    onNext={() => {
                        // get index of selectedLog
                        const index = filterCoversations.findIndex(c => c.conversationId === selectedCallLog.conversationId)
                        if (index < filterCoversations.length - 1) {
                            setSelectedCallLog(filterCoversations[index+1])
                        }
                    }}
                    onPrev={() => {
                        // get index of selectedLog
                        const index = filterCoversations.findIndex(c => c.conversationId === selectedCallLog.conversationId)
                        if (index > 0) {
                            setSelectedCallLog(filterCoversations[index-1])
                        }
                    }}
                />
            ) : null}
            {downloadReportPopup ? (
                <DownloadReport
                    show={downloadReportPopup}
                    onClose={() => setDownloadReportPopup(false)}
                />
            ) : null}
        </ReportAndAnalyticsWrap>
    );
}

const HEIGHT = 1260;
const ReportAndAnalyticsWrap = styled.div`
    box-sizing: border-box;
    overflow: hidden auto;
    display: flex;
    flex-direction: column;

    .report-detail {
        display: flex;
        position: relative;
        height: ${HEIGHT}px;
        background: ${COLORS.interface_dark_gradient_01};

        > div:first-child {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            > div:first-child,
        }
    }

    .search-input-wrap {
        input {
            height: 37px;
            background: transparent;
            color: white;
            border: none;
            border: 1px solid ${COLORS.Interface_dark_glow};
            backdrop-filter: blur(2px);
            border-radius: 4px;
            padding-left: 35px;
        }
        input:focus-visible {
            outline: none;
        }
        .search-input-icon {
            position: absolute;
            left: 8px;
            margin-bottom: -4px;
            z-index: 1;
            svg {
                height: 18px;
                width: 20px;
            }
        }
    }
`;
export const SankeyTitle = styled.div`
    color: white;
    font-size: 1.4rem;
    padding: 14px 20px 0px;
    background: ${COLORS.neutrals_black_2};

    > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > div {
            font-size: 14px;
        }
    }

    .divide-line {
        height: 1px;
        width: 100%;
        margin-top: 14px;
        background: ${COLORS.Interface_dark_glow};
    }
`;
