export const theme = {
    body: '',
    default_text_color: 'red',
    interface_dark_glow: '#063DB5',
    interface_dark_blue: '#001946',
    primary_bule: '#58B1EB',
    interface_button_default: '#000714',
    sidebar: {
        border: '#0C234A',
        divider: 'white'
    }
};

type SidebarTheme = {
    border: string;
    divider: string;
};

export type ThemeTemplate = {
    body: string;
    default_text_color: string;
    interface_dark_glow: string;
    interface_dark_blue: string;
    primary_bule: string;
    interface_button_default: string;
    sidebar: SidebarTheme;
};
