import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { setAuthToken } from 'src/utils/ls';

export type AttributesT = {
    email: string;
    email_verified: boolean;
    family_name: string;
    given_name: string;
};
export type InfoT = {
    attributes: AttributesT;
    username?: string;
};
export type UserInfoT = (AttributesT & Pick<InfoT, 'username'>) | null;

const useCheckUserState = () => {
    const [userInfo, setUserInfo] = useState<UserInfoT>(null);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchToken = async () => {
            try {
                const session = (await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken();
                const info: InfoT = await Auth.currentUserInfo();

                //expand the rule to check the jwt exp time
                if (session === '' || !info) {
                    navigate('/login');
                } else {
                    setAuthToken(session);
                    setUserInfo({
                        ...info.attributes,
                        username: info.username
                        // session
                    });
                }
            } catch {
                navigate('/login');
            }
        };
        fetchToken().catch(console.error);
    }, []);
    return { userInfo };
};

export default useCheckUserState;
