import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { groupBy } from 'lodash';
import { OverviewCard } from 'src/components/dashboard/OverviewCard';
import { COLORS } from 'src/constants/colors';
import {
    Breadcrumb,
    ScrollArea,
    WithDateRangeTitle,
    WithTitleAndViewing
} from 'src/components/dashboard/Views';
import DoughnutChart from 'src/components/dashboard/DoughnutChart';
import LineChart from 'src/components/dashboard/LineChart';
import {
    BarChartDataT,
    CallAggregatesResT,
    CallAggregatesTypes
} from 'src/types/dashboardT';
// import TEST_DATA from 'src/constants/fake-dashboard-data.json';
import BillingOverview from 'src/components/dashboard/BillingOverview';
import { round } from 'src/utils/utils';
import { getCallAggregates } from 'src/api/dashboard';
import LoadingView from 'src/components/shared/LoadingView';
import {
    BREADCRUMB_ITEMS,
    BUTTONS,
    BillingDashboardWrap,
    TotalSpendLineTitle,
    UnitDataT
} from './BillingDashboard';
import ClientIdBotIdContext from 'src/components/sidebar/clientIdBotIdContext';

export default function AdminBillingDashboard() {
    const [currentMonthCallAggregatesRes, setCurrentMonthCallAggregatesRes] =
        useState<CallAggregatesResT>(null);
    const [customDateCallAggregatesRes, setcustomDateCallAggregatesRes] =
        useState<CallAggregatesResT>(null);
    // const linechartDivRef = useRef<HTMLDivElement>(null);
    const [averageLinechartData, setAverageLinechartData] =
        useState<BarChartDataT>();
    const [costLnechartData, setCostLnechartData] = useState<BarChartDataT>();
    // const { width:linecharWidth } = useContainerDimensions(linechartDivRef)
    const [customDate, setCustomDate] = useState({
        startDate: moment().subtract(7, 'day'),
        endDate: moment()
    });

    const { region } = useContext(ClientIdBotIdContext);

    const fetchThisMonthData = async () => {
        const monthRes = await getCallAggregates(
            {
                // clientid: 'CRG',
                fromdate: moment().startOf('month'),
                todate: moment()
            },
            region
        );
        if (monthRes?.code === 200)
            setCurrentMonthCallAggregatesRes(
                monthRes.data as CallAggregatesResT
            );
    };
    const fetchCustomTimeData = async () => {
        const custRes = await getCallAggregates(
            {
                // clientid: 'CRG',
                fromdate: customDate.startDate,
                todate: customDate.endDate
            },
            region
        );
        if (custRes?.code === 200)
            setcustomDateCallAggregatesRes(custRes.data as CallAggregatesResT);
    };
    useEffect(() => {
        fetchThisMonthData();
    }, []);
    useEffect(() => {
        fetchCustomTimeData();
    }, [customDate]);

    useEffect(() => {
        if (!customDateCallAggregatesRes) return;
        const calls = customDateCallAggregatesRes.callAggregates
            .filter(d => d.type === CallAggregatesTypes['1HOUR'])
            .map(d => {
                const date = d.date.substring(0, 19);
                const momentD = moment(date, 'YYYYMMDDhhmmss');
                return { ...d, date, dateStr: momentD.format('YYYYMMDD') };
            });
        const dailyCalls = groupBy(calls, d => d.dateStr);

        let dailyData: UnitDataT = {};
        Object.keys(dailyCalls).forEach(k => {
            dailyData[k] = dailyCalls[k].reduce(
                (prev, c) => {
                    return {
                        totalCost: prev.totalCost + c.totalCost,
                        totalConversation:
                            prev.totalConversation + c.totalConversation,
                        totalDuration: prev.totalDuration + c.totalDuration
                    };
                },
                { totalCost: 0, totalConversation: 0, totalDuration: 0 }
            );
        });

        const labels = Object.keys(dailyData).map(
            d => `${d.substring(6, 8)}/${d.substring(4, 6)}`
        );
        setAverageLinechartData({
            labels,
            datasets: [
                {
                    label: 'Total Cost',
                    data: Object.keys(dailyData).map(
                        k => dailyData[k].totalCost
                    ),
                    backgroundColor: COLORS.interface_dark_accent_01,
                    borderColor: COLORS.interface_dark_accent_01
                },
                {
                    label: 'Average Cost',
                    data: Object.keys(dailyData).map(k =>
                        dailyData[k].totalConversation > 0
                            ? round(
                                  dailyData[k].totalCost /
                                      dailyData[k].totalConversation
                              )
                            : 0
                    ),
                    backgroundColor: COLORS.interface_dark_accent_02,
                    borderColor: COLORS.interface_dark_accent_02
                }
            ]
        });
        setCostLnechartData({
            labels,
            datasets: [
                {
                    label: 'Voice',
                    data: Object.keys(dailyData).map(
                        k => dailyData[k].totalCost
                    ),
                    backgroundColor: COLORS.interface_dark_accent_01,
                    borderColor: COLORS.interface_dark_accent_01
                }
            ]
        });
    }, [customDateCallAggregatesRes]);

    const renderThirdArea = () => {
        const doughnutData: any = {
            labels: ['Voice'],
            datasets: [
                {
                    data: [customDateCallAggregatesRes?.totalCost],
                    backgroundColor: [COLORS.interface_dark_accent_01]
                }
            ]
        };
        return (
            <WithTitleAndViewing title="" buttons={BUTTONS} hideTitle>
                <div className="row overview-cards-bg third-area min-h-480">
                    <OverviewCard
                        title="TOTAL SPEND"
                        value={`$${customDateCallAggregatesRes?.totalCost}`}
                        arrow="down"
                        childClassName="h-100 p-doughnut"
                        hideTypeIcon
                    >
                        <DoughnutChart {...doughnutData} />
                    </OverviewCard>
                    <div className="divide-line" />
                    <OverviewCard
                        customTitle={
                            <TotalSpendLineTitle>
                                TOTAL SPEND
                                <p>{`$${customDateCallAggregatesRes?.totalCost}`}</p>
                            </TotalSpendLineTitle>
                        }
                        value={`$${customDateCallAggregatesRes?.totalCost}`}
                        childClassName="h-100"
                        hideTypeIcon
                    >
                        <LineChart data={costLnechartData} />
                    </OverviewCard>
                </div>
            </WithTitleAndViewing>
        );
    };

    return (
        <BillingDashboardWrap>
            <Breadcrumb items={BREADCRUMB_ITEMS} />
            {currentMonthCallAggregatesRes ? (
                <ScrollArea>
                    <BillingOverview
                        buttons={BUTTONS}
                        callAggregatesRes={currentMonthCallAggregatesRes}
                    />

                    <WithDateRangeTitle
                        {...customDate}
                        setDates={setCustomDate}
                        childClassname="h-100-end"
                    >
                        <LineChart data={averageLinechartData} />
                    </WithDateRangeTitle>

                    {renderThirdArea()}
                </ScrollArea>
            ) : (
                <LoadingView />
            )}
        </BillingDashboardWrap>
    );
}
