import { Breadcrumb } from 'src/components/dashboard/Views';
import { SWITCH_ROUTES } from 'src/constants/routes';
import styled from 'styled-components';
import { BillingDashboardWrap } from '../dashboard/BillingDashboard';
import Engine, { AssertionTitle, FailureText, SuccessText } from '../engine';
import { useGetAllTestCasesHook, useRunTestCases } from './hooks';
import { useContext, useEffect, useState } from 'react';
import ClientIdBotIdContext from 'src/components/sidebar/clientIdBotIdContext';
import { Button } from 'src/components/shared/Button';
import DeleteTestCase from './components/DeleteTestCase';
import EditTestCase from './components/EditTestCase';
import { useUpdateTestCaseHook } from './hooks';
import EditCategory from './components/EditCategory';
import { ReactSVG } from 'react-svg';
import { TestCase } from 'src/types/testcase';
import { Auth } from 'aws-amplify';

export const INIT_FORM_STATE = {
    companyName: '',
    contactName: '',
    email: '',
    website: '',
    role: ''
};

export const BREADCRUMB_ITEMS = [
    {
        text: 'Home',
        redirectUrl: SWITCH_ROUTES.DASHBOARD,
        svgSrc: '/images/sidebar/home.svg'
    },
    { text: 'Engine Workflow', redirecUrl: '' }
];

export default function Enginev2(): JSX.Element {
    const { botId, region } = useContext(ClientIdBotIdContext);

    const {
        status: getAllTestCasesStatus,
        data: allTestCases,
        setData: setAlltestCases,
        error: allTestCasesError,
        fn: allTestCasesFn
    } = useGetAllTestCasesHook() as {
        status: string;
        data: TestCase[];
        setData: Function;
        error: string;
        fn: Function;
    };

    const [newTestCase, setNewTestCase] = useState<boolean>(false);
    const [newCategory, setNewCategory] = useState<boolean>(false);
    const [editTestCase, setEditTestCase] = useState<any>();
    const [deleteTestCase, setDeleteTestCase] = useState<any>();
    const [testCaseToView, setTestCaseToView] = useState<any>();

    const { status: updateStatus, fn: updateFn } = useUpdateTestCaseHook();

    const [formState, setFormState] = useState(INIT_FORM_STATE);

    const { runStatus, fn: runTestCaseFn } = useRunTestCases();

    const getBotResponse = (status: string) => {
        switch (status) {
            case 'SUCCESS':
                return <SuccessText>Success</SuccessText>;
            case 'ERROR':
                return <FailureText>Failure</FailureText>;
            case 'IN_PROGRESS':
                return <p>Running...</p>;
            case 'UNINIT':
            default:
                return <p>Not Run</p>;
        }
    };

    useEffect(() => {
        const fetchUser = async () => {
            const user = await Auth.currentAuthenticatedUser();
            if (user !== null) {
                // console.log(user);
                setFormState({
                    companyName: user.attributes['custom:company'],
                    contactName: user.attributes.given_name,
                    email: user.attributes.email,
                    website: user.attributes.website,
                    role: user?.attributes['custom:Role'] || ''
                });
            }
        };
        fetchUser().catch(console.error);
    }, []);

    useEffect(() => {
        if (botId && region) {
            allTestCasesFn(
                {
                    botId
                },
                region
            );
        }
    }, [botId, region]);

    const getTestCaseById = (id: string): any => {
        let testCaseId = null;

        allTestCases.forEach(c => {
            c.testcases.forEach((t: any) => {
                if (t._id === id) {
                    testCaseId = t;
                }
            });
        });

        return testCaseId;
    };

    const getTestCaseIndex = (id: string) => {
        let testCaseId = [] as number[];

        allTestCases.forEach((c, i) => {
            c.testcases.forEach((t: any, ii) => {
                if (t._id === id) {
                    testCaseId = [i, ii];
                }
            });
        });

        return testCaseId;
    };

    const checkIfAllTestCasesAreChecked = () => {
        const dup = JSON.parse(JSON.stringify(allTestCases));

        const arr = [] as boolean[];

        dup.forEach((d: any) =>
            d.testcases.forEach((t: any) => arr.push(t.selectToRun))
        );

        return arr.every(a => a);
    };

    let body = <></>;

    if (getAllTestCasesStatus === 'IN_PROGRESS') {
        body = <p>Loading...</p>;
    } else if (getAllTestCasesStatus === 'SUCCESS') {
        console.log('------------------ runStatus', runStatus);
        body = (
            <>
                <LeftSection>
                    <SectionHeader>
                        <span>TestCase</span>
                        <Button
                            onClick={() => {
                                // run selected testcases
                                if (runStatus !== 'IN_PROGRESS') {
                                    runTestCaseFn(
                                        allTestCases,
                                        setAlltestCases
                                    );
                                }
                            }}
                            text={
                                runStatus === 'IN_PROGRESS'
                                    ? 'Running...'
                                    : 'Run TestCases'
                            }
                        />
                    </SectionHeader>
                    <TestCaseItem chosen={false}>
                        <input
                            id="select-all"
                            type="checkbox"
                            checked={checkIfAllTestCasesAreChecked()}
                            onChange={() => {
                                const dup = JSON.parse(
                                    JSON.stringify(allTestCases)
                                );

                                if (checkIfAllTestCasesAreChecked()) {
                                    // uncheck all
                                    dup.forEach((d: any) =>
                                        d.testcases.forEach((t: any) => {
                                            t.selectToRun = false;
                                        })
                                    );
                                } else {
                                    // check all
                                    dup.forEach((d: any) =>
                                        d.testcases.forEach((t: any) => {
                                            t.selectToRun = true;
                                        })
                                    );
                                }

                                setAlltestCases(dup);
                            }}
                        />
                        <label htmlFor="select-all">Select All</label>
                    </TestCaseItem>
                    {allTestCases.length ? (
                        allTestCases.map((a: any, i: number) => (
                            <Accordion key={a._id}>
                                <AccordionHeader>
                                    <strong>{a.name}</strong>
                                    <ReactSVG
                                        wrapper="span"
                                        className={`more-svg ${
                                            a.expanded ? 'expanded' : ''
                                        }`}
                                        src={
                                            '/images/dashboard/arrow-more-blue.svg'
                                        }
                                        onClick={() => {
                                            const dup = JSON.parse(
                                                JSON.stringify(allTestCases)
                                            );
                                            dup[i].expanded = !dup[i].expanded;
                                            setAlltestCases(dup);
                                        }}
                                    />
                                </AccordionHeader>
                                {a.expanded ? (
                                    <AccordionBody>
                                        {a.testcases.map(
                                            (t: any, ii: number) => (
                                                <TestCaseItem
                                                    chosen={
                                                        t._id === testCaseToView
                                                    }
                                                    key={t._id}
                                                >
                                                    <p>
                                                        <input
                                                            id={a._id}
                                                            type="checkbox"
                                                            checked={
                                                                t.selectToRun
                                                            }
                                                            onChange={() => {
                                                                const dup =
                                                                    JSON.parse(
                                                                        JSON.stringify(
                                                                            allTestCases
                                                                        )
                                                                    );

                                                                dup[
                                                                    i
                                                                ].testcases[
                                                                    ii
                                                                ].selectToRun =
                                                                    !dup[i]
                                                                        .testcases[
                                                                        ii
                                                                    ]
                                                                        .selectToRun;
                                                                setAlltestCases(
                                                                    dup
                                                                );
                                                            }}
                                                        />
                                                        <label htmlFor={t._id}>
                                                            {t.name}
                                                        </label>
                                                    </p>
                                                    <div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                gap: '10px',
                                                                alignItems:
                                                                    'center'
                                                            }}
                                                        >
                                                            <span>
                                                                Run Status -
                                                            </span>
                                                            {getBotResponse(
                                                                t.runStatus
                                                            )}
                                                        </div>
                                                        <div>
                                                            <span
                                                                onClick={() => {
                                                                    setTestCaseToView(
                                                                        t._id
                                                                    );
                                                                }}
                                                            >
                                                                View
                                                            </span>
                                                            <span
                                                                onClick={() => {
                                                                    setEditTestCase(
                                                                        t._id
                                                                    );
                                                                }}
                                                            >
                                                                Edit
                                                            </span>
                                                            <span
                                                                onClick={() => {
                                                                    setDeleteTestCase(
                                                                        t._id
                                                                    );
                                                                }}
                                                            >
                                                                Delete
                                                            </span>
                                                        </div>
                                                    </div>
                                                </TestCaseItem>
                                            )
                                        )}
                                    </AccordionBody>
                                ) : null}
                            </Accordion>
                        ))
                    ) : (
                        <p>No test cases exist, please add some testcases.</p>
                    )}
                </LeftSection>
                <RightSection>
                    <SectionHeader>
                        <span>
                            Testcase Detail
                            {testCaseToView
                                ? getTestCaseById(testCaseToView)?.name
                                    ? ` (${
                                          getTestCaseById(testCaseToView)?.name
                                      })`
                                    : ''
                                : null}
                        </span>
                        {updateStatus === 'IN_PROGRESS' ? (
                            <p>Saving...</p>
                        ) : (
                            <Button
                                onClick={() => {
                                    // Call API to save test case

                                    // check if _id exists for the selected item, if not then it is a new record which needs to be created.
                                    const tc = getTestCaseById(testCaseToView);
                                    updateFn(
                                        {
                                            botId,
                                            name: tc?.name,
                                            categoryId: tc?.categoryId,
                                            testcase: tc?.testcase.map(
                                                (t: any) => ({
                                                    userSays: t.userSays,
                                                    botSays: t.botSays
                                                })
                                            ),
                                            createdBy: undefined
                                        },
                                        region,
                                        tc?._id,
                                        () => {
                                            allTestCasesFn(
                                                {
                                                    botId
                                                },
                                                region
                                            );
                                        }
                                    );
                                }}
                                text="Save TestCase"
                            />
                        )}
                    </SectionHeader>

                    {testCaseToView ? (
                        <RightSectionContent>
                            <Engine
                                assertion={
                                    getTestCaseById(testCaseToView)?.testcase
                                }
                                setAssertion={(assertion: any) => {
                                    const dup = JSON.parse(
                                        JSON.stringify(allTestCases)
                                    );

                                    const [i, ii] =
                                        getTestCaseIndex(testCaseToView);

                                    dup[i].testcases[ii].testcase = assertion;
                                    setAlltestCases(dup);
                                }}
                                rStatus={
                                    getTestCaseById(testCaseToView)?.runStatus
                                }
                            />
                        </RightSectionContent>
                    ) : (
                        <SelectTestCaseContainer>
                            <p>
                                Please select a testcase from left panel, to
                                view details
                            </p>
                        </SelectTestCaseContainer>
                    )}
                </RightSection>
            </>
        );
    } else if (getAllTestCasesStatus === 'ERROR') {
        body = <p>{allTestCasesError}</p>;
    }

    return (
        <BillingDashboardWrap>
            <Breadcrumb items={BREADCRUMB_ITEMS} />
            <TopHeader>
                <AssertionTitle>Workflow Testcases</AssertionTitle>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <Button
                        onClick={() => {
                            // Add New TestCase
                            setNewCategory(true);
                        }}
                        text="New Category"
                    />
                    <Button
                        onClick={() => {
                            // Add New TestCase
                            setNewTestCase(true);
                        }}
                        text="New TestCase"
                    />
                </div>
            </TopHeader>
            <WorkFlowContainer>{body}</WorkFlowContainer>
            {newTestCase ? (
                <EditTestCase
                    show={newTestCase}
                    onClose={() => {
                        setNewTestCase(false);
                    }}
                    testCase={null}
                    refresh={() => {
                        allTestCasesFn(
                            {
                                botId
                            },
                            region
                        );
                        setNewTestCase(false);
                    }}
                    agentDetails={formState}
                />
            ) : null}
            {newCategory ? (
                <EditCategory
                    show={newCategory}
                    onClose={() => setNewCategory(false)}
                />
            ) : null}
            {editTestCase ? (
                <EditTestCase
                    show={!!editTestCase}
                    onClose={() => {
                        setEditTestCase(null);
                    }}
                    testCase={getTestCaseById(editTestCase)}
                    refresh={() => {
                        allTestCasesFn(
                            {
                                botId
                            },
                            region
                        );
                        setEditTestCase(null);
                    }}
                    agentDetails={formState}
                />
            ) : null}
            {deleteTestCase ? (
                <DeleteTestCase
                    show={!!deleteTestCase}
                    onClose={() => {
                        setDeleteTestCase(null);
                    }}
                    testCaseToDelete={deleteTestCase}
                    refresh={() => {
                        setDeleteTestCase(null);
                        setTestCaseToView(null);
                        allTestCasesFn(
                            {
                                botId
                            },
                            region
                        );
                    }}
                />
            ) : null}
        </BillingDashboardWrap>
    );
}

export const WorkFlowContainer = styled.div`
    display: flex;
    gap: 10px;
`;

export const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 321px);
    flex: 0 0 25%;
    background: linear-gradient(
        rgb(0, 25, 70) 0%,
        rgb(12, 33, 90) 32.81%,
        rgb(0, 25, 70) 100%
    );
    overflow: scroll;
`;

export const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 300px);
    flex: 0 0 75%;
    background: linear-gradient(
        rgb(0, 25, 70) 0%,
        rgb(12, 33, 90) 32.81%,
        rgb(0, 25, 70) 100%
    );
    overflow: scroll;
`;

export const TopHeader = styled.div`
    display: flex;
    align-items: center;
    padding: 18px 10px 16px;
    justify-content: space-between;
    > span {
        font-size: 20px;
    }
`;

export const SectionHeader = styled(TopHeader)`
    background: rgba(0, 0, 0, 0.2);
    padding-right: 20px;
`;

export const TestCaseItem = styled.div<{ chosen: boolean }>`
    border-bottom: 1px solid rgb(0, 25, 70);
    padding: 10px;
    cursor: pointer;
    background: ${props => (props.chosen ? 'rgba(0, 0, 0, 0.2)' : '')}


    &: hover {
        background: rgba(0, 0, 0, 0.2);
    }

    > div {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        > div {
            display: flex;
            gap: 10px;
            justify-content: flex-end;
        }
    }

    > div > span {
        color: #fff;
        &:hover {
            text-decoration: underline;
        }
    }
`;

export const RightSectionContent = styled.div`
    padding: 30px 20px;
`;

export const SelectTestCaseContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Accordion = styled.div``;

export const AccordionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;

    strong {
        font-weight: bold;
    }

    svg {
        cursor: pointer;

        transform: rotate(90deg);
    }

    .expanded {
        svg {
            transform: rotate(270deg);
        }
    }
`;

export const AccordionBody = styled.div``;
