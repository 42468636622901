import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { groupBy } from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
    OverviewCard,
    PureTextContent
} from 'src/components/dashboard/OverviewCard';
import { COLORS } from 'src/constants/colors';
import MultiBarChart from 'src/components/dashboard/MultiBarChart';
import { WithTitleAndViewing } from 'src/components/dashboard/Views';
import {
    MultiBarChartDataT,
    CallAggregatesResT,
    CallAggregatesTypes,
    PureTextContentItemT
} from 'src/types/dashboardT';
import { round, secondsToTimeStr } from 'src/utils/utils';
import { ButtonT } from '../shared/Button';
import { SWITCH_ROUTES } from 'src/constants/routes';
import ClientIdBotIdContext from '../sidebar/clientIdBotIdContext';
import { LOCALE_TZ_FORMAT } from 'src/utils/constants';

const DAILY_BAR_LABELS = ['M', 'T', 'W', 'T', 'F', 'S', 'S']; //0 -> 6
type CurrentDataT = {
    averageCost?: number;
    totalCost?: number;
    averageCostPerHour: number;
    averageCostPerDay: number;
    averageInfo: PureTextContentItemT[];
    predictedInfo: PureTextContentItemT[];
    hourlyBarData?: MultiBarChartDataT;
    dailyBarData?: MultiBarChartDataT;
} | null;
type UnitDataT = {
    [k: string]: {
        totalCost: number;
        totalConversation: number;
        totalDuration: number;
    };
};
type BillingOverviewProps = {
    sectionTitle?: string;
    callAggregatesRes: CallAggregatesResT;
    buttons: ButtonT[];
};
const BillingOverview = (props: BillingOverviewProps) => {
    const { timezone } = useContext(ClientIdBotIdContext);
    const [currentMonthData, setCurrentMonthData] =
        useState<CurrentDataT>(null);
    const navigate = useNavigate();
    const { sectionTitle, buttons, callAggregatesRes } = props;
    const goBillingDashboard = () => navigate(SWITCH_ROUTES.DASHBOARD_BILLING);

    useEffect(() => {
        if (!callAggregatesRes) return;

        const averageInfo: PureTextContentItemT[] = [
            {
                key: 'Average Duration',
                value: secondsToTimeStr(callAggregatesRes?.averageDuration),
                arrow: 'down'
            },
            {
                key: 'Average Cost',
                subItems: [
                    {
                        key: 'per hour',
                        value: `$${callAggregatesRes?.averageCostPerHour}`,
                        arrow: 'down'
                    },
                    {
                        key: 'per day',
                        value: `$${callAggregatesRes?.averageCostPerDay}`,
                        arrow: 'down'
                    },
                    {
                        key: 'per week',
                        value: `$${callAggregatesRes?.averageCostPerWeek}`,
                        arrow: 'up'
                    }
                ]
            }
        ];
        const predictedInfo: PureTextContentItemT[] = [
            {
                key: 'Projected Quarterly',
                value: `$${callAggregatesRes?.totalCostPredictedQtrly}`,
                arrow: 'down'
            },
            {
                key: 'Projected Annual',
                value: `$${callAggregatesRes?.totalCostPredictedAnnually}`,
                arrow: 'down'
            }
            // {
            //     key: '2023',
            //     subItems: [
            //         { key: 'January', value: '$????', arrow: 'down' },
            //         { key: 'February', value: '$????', arrow: 'up' },
            //         { key: 'March', value: '$???', arrow: 'down' }
            //     ]
            // }
        ];
        const hourlyCalls = callAggregatesRes.callAggregates
            .filter(d => d.type === CallAggregatesTypes['1HOUR'])
            .map(d => {
                const date = d.date;

                const momentD = moment(date, LOCALE_TZ_FORMAT[timezone]);
                return { ...d, date, hour: momentD.hour(), day: momentD.day() };
            });
        const hourlyCallsGroupByHour = groupBy(hourlyCalls, d => d.hour);
        const hourlyCallsGroupByDay = groupBy(hourlyCalls, d => d.day);

        let hourlyData: UnitDataT = {};
        let dailyData: UnitDataT = {};
        Object.keys(hourlyCallsGroupByHour).forEach(k => {
            hourlyData[k] = hourlyCallsGroupByHour[k].reduce(
                (prev, c) => {
                    return {
                        totalCost: prev.totalCost + c.totalAccountWiseCost,
                        totalConversation:
                            prev.totalConversation + c.totalConversation,
                        totalDuration: prev.totalDuration + c.totalDuration
                    };
                },
                { totalCost: 0, totalConversation: 0, totalDuration: 0 }
            );
        });
        Object.keys(hourlyCallsGroupByDay).forEach(k => {
            dailyData[k] = hourlyCallsGroupByDay[k].reduce(
                (prev, c) => {
                    return {
                        totalCost: prev.totalCost + c.totalAccountWiseCost,
                        totalConversation:
                            prev.totalConversation + c.totalConversation,
                        totalDuration: prev.totalDuration + c.totalDuration
                    };
                },
                { totalCost: 0, totalConversation: 0, totalDuration: 0 }
            );
        });
        for (let i = 0; i < 7; i++) {
            if (!(i in dailyData))
                dailyData[i] = {
                    totalCost: 0,
                    totalConversation: 0,
                    totalDuration: 0
                };
        }

        setCurrentMonthData({
            averageCost: callAggregatesRes?.averageCost,
            totalCost: callAggregatesRes?.totalCost,
            averageCostPerHour: callAggregatesRes?.averageCostPerHour || 0,
            averageCostPerDay: callAggregatesRes?.averageCostPerDay || 0,
            averageInfo,
            predictedInfo,
            hourlyBarData: {
                labels: Object.keys(hourlyCallsGroupByHour),
                datasets: [
                    {
                        label: 'Total',
                        data: Object.keys(hourlyData).map(
                            k => hourlyData[k].totalCost / 100
                        ),
                        backgroundColor: COLORS.interface_dark_accent_01,
                        borderColor: COLORS.interface_dark_accent_01,
                        yAxisID: 'y1',
                        type: 'bar'
                    },
                    {
                        label: 'Average/Call',
                        data: Object.keys(hourlyData).map(k =>
                            hourlyData[k].totalConversation > 0
                                ? round(
                                      hourlyData[k].totalCost /
                                          (100 *
                                              hourlyData[k].totalConversation)
                                  )
                                : 0
                        ),
                        backgroundColor: COLORS.interface_dark_accent_02,
                        borderColor: COLORS.interface_dark_accent_02,
                        yAxisID: 'y2',
                        type: 'line'
                    }
                ],
                isCurrency: true
            },
            dailyBarData: {
                labels: DAILY_BAR_LABELS,
                datasets: [
                    {
                        label: 'Total',
                        data: Object.keys(dailyData).map(
                            k => dailyData[k].totalCost / 100
                        ),
                        backgroundColor: COLORS.interface_dark_accent_01,
                        borderColor: COLORS.interface_dark_accent_01,
                        yAxisID: 'y1',
                        type: 'bar'
                    },
                    {
                        label: 'Average/Call',
                        data: Object.keys(dailyData).map(k =>
                            dailyData[k].totalConversation > 0
                                ? round(
                                      dailyData[k].totalCost /
                                          (100 * dailyData[k].totalConversation)
                                  )
                                : 0
                        ),
                        backgroundColor: COLORS.interface_dark_accent_02,
                        borderColor: COLORS.interface_dark_accent_02,
                        yAxisID: 'y2',
                        type: 'line'
                    }
                ],
                isCurrency: true
            }
        });
    }, [callAggregatesRes]);

    if (!currentMonthData) return null;

    return (
        <WithTitleAndViewing
            title={sectionTitle || ''}
            buttons={buttons}
            hideTitle={!sectionTitle}
            onSeeAllClick={goBillingDashboard}
        >
            <div className="row overview-cards-bg w-25 min-h-380">
                <OverviewCard
                    title="PER CALL"
                    value={`$${currentMonthData?.averageCost}`}
                    arrow="down"
                >
                    <PureTextContent items={currentMonthData?.averageInfo} />
                </OverviewCard>
                <div className="divide-line" />
                <OverviewCard
                    title="HOURLY"
                    value={`$${currentMonthData?.averageCostPerHour}`}
                    childClassName="h-100-end"
                    arrow="down"
                >
                    <MultiBarChart data={currentMonthData?.hourlyBarData} />
                </OverviewCard>
                <div className="divide-line" />
                <OverviewCard
                    title="DAILY"
                    value={`$${currentMonthData.averageCostPerDay}`}
                    childClassName="h-100-end"
                    arrow="down"
                >
                    <MultiBarChart data={currentMonthData?.dailyBarData} />
                </OverviewCard>
                <div className="divide-line" />
                <OverviewCard
                    title="OVERALL SPEND"
                    value={`$${currentMonthData?.totalCost}`}
                    arrow="down"
                    additionalTitleView={
                        <div className="overview-card-title">THIS MONTH</div>
                    }
                >
                    <PureTextContent items={currentMonthData?.predictedInfo} />
                </OverviewCard>
            </div>
        </WithTitleAndViewing>
    );
};

export default BillingOverview;
