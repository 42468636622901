import { ReactNode } from 'react';
import Pager, { PagerProps } from 'src/components/shared/pagination/Pager';
import styled from 'styled-components';

type PaginationProps = PagerProps & {
    children: ReactNode;
};

const Pagination = (props: PaginationProps) => {
    const { children, ...rest } = props;

    return (
        <PaginationWrap className="pagination-wrap">
            {children}
            <Pager {...rest} />
            {/* <div className="pages-div">
                第 <span>{props.pageIndex + 1}</span> {`页 / 总 ${props.pageCount} 页`}
            </div> */}
        </PaginationWrap>
    );
};

export default Pagination;

const PaginationWrap = styled.div`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: white;
`;
