import React, { useState, useEffect, useContext } from 'react';
import {
    LoginView,
    PageWrap,
    Portion,
    Welcome
} from 'src/components/Login/Views';
import { Footer } from 'src/components/shared/Footer';
import { Input } from 'src/components/shared/Inputs';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { setAuthToken } from 'src/utils/ls';
import { SWITCH_ROUTES } from 'src/constants/routes';
import ClientIdBotIdContext from 'src/components/sidebar/clientIdBotIdContext';

const initialState = { username: '', password: '' };

export default function LoginPage() {
    const { setClientId, setBotId, setRegion, setTimezone, setAccountId } =
        useContext(ClientIdBotIdContext);
    const [formState, setFormState] = useState(initialState);
    const [errorMsg, setErrorMsg] = useState('');
    const navigate = useNavigate();

    const getTokenAndRedirect = async () => {
        const data = (await Auth.currentSession()).getIdToken().getJwtToken();

        if (data !== '') {
            setAuthToken(data);
            navigate(SWITCH_ROUTES.DASHBOARD);
        }
    };
    useEffect(() => {
        getTokenAndRedirect();
    }, []);

    function setInput(key: any, value: any) {
        setFormState({ ...formState, [key]: value });
    }
    const login = () => {
        try {
            // clear context before logging user in
            setClientId('');
            setBotId('');
            setRegion('');
            setTimezone('');
            setAccountId('');

            setErrorMsg('');
            if (!formState.username || !formState.password) return;
            const { username, password } = { ...formState };
            Auth.signIn({
                username: username,
                password: password
            })
                .then(async user => {
                    await getTokenAndRedirect();
                })
                .catch(err => {
                    setErrorMsg(err?.message || err);
                });
            //setFormState(initialState);
        } catch (err) {
            console.log('login catch error:', err);
        }
    };
    function navigateFrgtPwd() {
        navigate('/forgotPassword');
    }
    return (
        <PageWrap>
            <Portion>
                <Welcome />
                <div />
                <LoginView>
                    <>
                        <p>Login</p>
                        <p>
                            To access your billing, reporting and analytics
                            please log in using your email address and password.
                        </p>
                        <div className="input-wrap">
                            <Input
                                onChange={event =>
                                    setInput('username', event.target.value)
                                }
                                value={formState.username}
                                placeholder="Username"
                            />
                            <Input
                                onChange={event =>
                                    setInput('password', event.target.value)
                                }
                                value={formState.password}
                                type="password"
                                placeholder="Password"
                            />
                        </div>
                        {errorMsg && <p className="error-msg">{errorMsg}</p>}
                        <div className="buttons">
                            <p onClick={navigateFrgtPwd}>Forgot Password</p>
                            <button onClick={login}>Submit</button>
                        </div>
                    </>
                </LoginView>
            </Portion>
            <Footer classname="fixed-footer" />
        </PageWrap>
    );
}
