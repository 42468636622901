import React from 'react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GlobalErrors = () => {
    return (
        <GlobalErrorsWrap>
            <ToastContainer theme="colored" />
        </GlobalErrorsWrap>
    );
};

export default GlobalErrors;

const GlobalErrorsWrap = styled.div`
    .Toastify__toast-theme--light {
        color: white;
    }
    .Toastify__close-button--light {
        color: white;
    }
`;
