import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsSankey from 'highcharts/modules/sankey';

import HighchartsArcDiagram from 'highcharts/modules/arc-diagram';
HighchartsExporting(Highcharts);
HighchartsSankey(Highcharts);
HighchartsAccessibility(Highcharts);
HighchartsArcDiagram(Highcharts);

const hideExportingMenu = `
    .highcharts-exporting-group {
        display: none !important;
    }
`;
export type SankeyChartDataT = [string, string, number][];
type HighchartsSankeyProps = {
    data?: SankeyChartDataT;
};
export default function HighchartsSankeyChart(props: HighchartsSankeyProps) {
    const { data } = props;
    const handleClick = (event: any) => {
        // Access clicked point data
        const point = event.point;
        // console.log('Clicked point:', point);
        // console.log('Clicked formatPrefix:', point.formatPrefix);
        if (point.formatPrefix === 'node') {
            console.log(`Clicked id:${point.id} name:${point.name}`);
        } else if (point.formatPrefix === 'point') {
            console.log(
                `Clicked from:${point.from} to:${point.to}, weight:${point.weight}`
            );
        }
    };

    const defaultOptions = {
        chart: {
            type: 'sankey', //arcdiagram
            backgroundColor: 'rgb(12,29,77)' //COLORS.interface_dark_gradient_01,
        },

        credits: {
            enabled: false // Disable the Highcharts credits
        },
        accessibility: {
            enabled: false // Disable the accessibility features
        },
        title: {
            text: ''
        },
        plotOptions: {
            series: {
                states: {
                    inactive: {
                        linkOpacity: 0.4,
                        opacity: 0.4
                    }
                }
            }
        },
        series: [
            {
                keys: ['from', 'to', 'weight'],
                dataLabels: {
                    // format: '{point.fromNode.name} → {point.toNode.name}: {point.weight}',
                    format: '{point.weight}',
                    nodeFormat: '{point.name}',
                    color: 'black'
                },
                cursor: 'pointer', // Enable pointer cursor on nodes
                events: {
                    click: handleClick // Assign click event handler
                },
                linkOpacity: 0.8,
                name: '', //default: Series 1
                data: [
                    ['Login', 'Purchase', 3],
                    ['Login', 'Return', 1],
                    ['ALogin1', 'Cancel', 2],
                    ['Purchase', 'Ask', 3],
                    ['Ask', 'GetUserId', 2],
                    ['Ask', 'fail', 1],
                    ['GetUserId', 'success', 3],
                    ['GetUserId', 'fail', 2],
                    ['Return', 'GetOrderNumber', 1],
                    ['Cancel', 'GetOrderNumber', 2],
                    ['GetOrderNumber', 'GetUserId', 3]
                ]
            }
        ]
    };

    let options = defaultOptions;
    if (data) {
        options.series[0].data = [...data];
    }

    return (
        <div style={{ height: 500 }}>
            <style>{hideExportingMenu}</style>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                containerProps={{
                    style: {
                        height: '100%',
                        padding: '30px 0px'
                    }
                }}
            />
        </div>
    );
}
