import React, { ReactNode, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import {
    CALL_STATUS_MAP,
    CallStatusTypes,
    ChannelTypes,
    LOG_MAP
} from 'src/constants/statusMap';
import { COLORS } from 'src/constants/colors';
import moment from 'moment';
import { getDateComponents, getLocaleDateTimeString } from 'src/utils/utils';
import AudioPlayer from './AudioPlayer';
import { getAudioCallLog } from 'src/api/invoice';
import ClientIdBotIdContext from '../sidebar/clientIdBotIdContext';
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';

type CallLogDetailT = {
    text: string;
    fromBot: boolean;
    events: string;
};
export type CallLogT = {
    startDate: string;
    localStartDate: string;
    conversationId: string;
    callingNumber?: string;
    channelType: ChannelTypes;
    details: CallLogDetailT[];
    handover: boolean; // true=Handover, false=Resolved
    clientCallID: string;
    // parse data
    displayStartTime?: string;
    messagesCount?: number;
    typeText?: string;
    typeImg?: ReactNode;
    callStatusText?: string;
    callStatusImg?: ReactNode;
};
type CallLogContentT = {
    items?: CallLogT[];
    onItemClick?: (d: CallLogT) => void;
    hideMoreImg?: boolean;
};

const VoiceImg = <ReactSVG wrapper="span" src={LOG_MAP.voice.svgSrc} />;
const ChatImg = <ReactSVG wrapper="span" src={LOG_MAP.chat.svgSrc} />;
const ResolvedImg = (
    <ReactSVG wrapper="span" src={CALL_STATUS_MAP.resolved.svgSrc} />
);
const HandoverImg = (
    <ReactSVG wrapper="span" src={CALL_STATUS_MAP.handover.svgSrc} />
);
const getTypeData = (type: ChannelTypes) => {
    let img = null;
    switch (type) {
        case ChannelTypes.Voice:
            img = VoiceImg;
            break;
        case ChannelTypes.Chat:
            img = ChatImg;
            break;
        default:
            img = VoiceImg;
            break;
    }

    return { typeText: LOG_MAP[type].text, typeImg: img };
};
const getCallStatusData = (handover: boolean) => {
    const type = handover ? CallStatusTypes.Handover : CallStatusTypes.Resolved;
    let img = null;
    switch (type) {
        case CallStatusTypes.Resolved:
            img = ResolvedImg;
            break;
        case CallStatusTypes.Handover:
            img = HandoverImg;
            break;
    }

    return { callStatusText: CALL_STATUS_MAP[type].text, callStatusImg: img };
};

const LIVE = 'LIVE';
export const parseCallLog = (c: CallLogT, botId: string, timezone: string) => {
    const { typeText, typeImg } = getTypeData(c.channelType);
    const { callStatusText, callStatusImg } = getCallStatusData(c.handover);
    return {
        ...c,
        displayStartTime: c.startDate
            ? getLocaleDateTimeString(c.startDate, '', timezone)
            : LIVE,
        localStartDate: c.startDate
            ? getLocaleDateTimeString(c.startDate, 'local', '')
            : LIVE,
        messagesCount: c.details.length,
        typeText,
        typeImg,
        callStatusText,
        callStatusImg
    };
};

export function CallLogContent(props: CallLogContentT) {
    const [audioPlayer, setAudioPlayer] = useState<string>('');
    const { region } = useContext(ClientIdBotIdContext);
    const { items, onItemClick, hideMoreImg } = props;
    if (!items) return null;

    return (
        <>
            {items.map((i, idx) => {
                const {
                    conversationId,
                    // startTime,
                    callingNumber,
                    channelType,
                    // handover,
                    displayStartTime,
                    localStartDate,
                    messagesCount,
                    typeText,
                    typeImg,
                    callStatusText,
                    callStatusImg,
                    startDate
                    // details,
                } = i;
                return (
                    <CallLogContentWrap
                        hideMoreImg={hideMoreImg}
                        key={`calllog-${idx}${conversationId}`}
                    >
                        <div title={`AEST ${localStartDate}`}>
                            {displayStartTime === LIVE ? (
                                <span className="call-log-live">Live</span>
                            ) : (
                                <span>{`Start ${displayStartTime}`}</span>
                            )}
                        </div>
                        <div>
                            {channelType === ChannelTypes.Voice
                                ? `Customer number ${callingNumber}`
                                : ''}
                        </div>
                        <div className="duration-wrap">
                            {typeImg}
                            <span>{typeText}</span>
                            {channelType === ChannelTypes.Voice ? (
                                <span>{`Messages ${messagesCount}`}</span>
                            ) : (
                                <span>{`Messages ${messagesCount}`}</span>
                            )}
                        </div>
                        <CallStatus>
                            {callStatusImg}
                            <span>{callStatusText}</span>
                        </CallStatus>
                        <div>
                            <ReactSVG
                                wrapper="span"
                                src="/images/dashboard/audio.svg"
                                onClick={() => {
                                    // call API to download Audio file

                                    const { year, month, date } =
                                        getDateComponents(startDate);

                                    getAudioCallLog(
                                        {
                                            year,
                                            month,
                                            date,
                                            conversationId
                                        },
                                        region
                                    )
                                        .then(data => {
                                            if (data.code === 200) {
                                                setAudioPlayer(data.data);
                                            }
                                        })
                                        .catch(e => {
                                            toast.error(
                                                'Could not load call recording. Please try again later.'
                                            );
                                        });
                                }}
                            />
                        </div>
                        {!hideMoreImg ? (
                            <div style={{ marginLeft: '20px' }}>
                                <ReactSVG
                                    wrapper="span"
                                    className="more-svg"
                                    src={
                                        '/images/dashboard/arrow-more-blue.svg'
                                    }
                                    onClick={() =>
                                        onItemClick && onItemClick(i)
                                    }
                                />
                            </div>
                        ) : null}
                    </CallLogContentWrap>
                );
            })}
            {audioPlayer ? (
                <AudioPlayer
                    show={!!audioPlayer}
                    onClose={() => {
                        setAudioPlayer('');
                    }}
                    src={audioPlayer}
                />
            ) : null}
        </>
    );
}

export function CallLogContentBig(props: CallLogContentT) {
    const [authUser, setAuthUser] = useState<{
        attributes: {
            'custom:Role': string;
        };
    }>();
    const [authLoadingStatus, setAuthLoadingStatus] = useState('UNINIT');

    const getAuthuser = async () => {
        try {
            setAuthLoadingStatus('IN_PROGRESS');
            const user = await Auth.currentAuthenticatedUser();
            setAuthUser(user);
            setAuthLoadingStatus('SUCCESS');
        } catch (e) {
            setAuthLoadingStatus('ERROR');
        }
    };

    useEffect(() => {
        getAuthuser();
    }, []);

    const [audioPlayer, setAudioPlayer] = useState<string>('');
    const { region } = useContext(ClientIdBotIdContext);
    const { items, onItemClick, hideMoreImg } = props;
    if (!items) return null;

    return (
        <>
            <CallLogContentBigWrap
                showConvId={['VERSA_ADMIN', 'VERSA'].includes(
                    authUser?.attributes['custom:Role'] || ''
                )}
            >
                <div>
                    <span>
                        <strong>Time</strong>
                    </span>
                </div>
                {['VERSA_ADMIN', 'VERSA'].includes(
                    authUser?.attributes['custom:Role'] || ''
                ) ? (
                    <div>
                        <span>
                            <strong>Conversation Id</strong>
                        </span>
                    </div>
                ) : null}
                <div>
                    <span>
                        <strong>Call Id</strong>
                    </span>
                </div>
                <div>
                    <span>
                        <strong>Calling Number</strong>
                    </span>
                </div>
                <div>
                    <span>
                        <strong>Messages</strong>
                    </span>
                </div>
                <div>
                    <span>
                        <strong>Status</strong>
                    </span>
                </div>
                <div>
                    <span>
                        <strong>Recording</strong>
                    </span>
                </div>
                <div>
                    <span></span>
                </div>
            </CallLogContentBigWrap>
            {items.map((i, idx) => {
                const {
                    conversationId,
                    // startTime,
                    callingNumber,
                    channelType,
                    // handover,
                    clientCallID,
                    displayStartTime,
                    localStartDate,
                    messagesCount,
                    typeText,
                    typeImg,
                    callStatusText,
                    callStatusImg,
                    startDate
                    // details,
                } = i;
                return (
                    <CallLogContentBigWrap
                        showConvId={['VERSA_ADMIN', 'VERSA'].includes(
                            authUser?.attributes['custom:Role'] || ''
                        )}
                        hideMoreImg={hideMoreImg}
                        key={`calllog-${idx}${conversationId}`}
                    >
                        <div title={`AEST ${localStartDate}`}>
                            {displayStartTime === LIVE ? (
                                <span className="call-log-live">Live</span>
                            ) : (
                                <span>{`Start ${displayStartTime}`}</span>
                            )}
                        </div>
                        {['VERSA_ADMIN', 'VERSA'].includes(
                            authUser?.attributes['custom:Role'] || ''
                        ) ? (
                            <div>{conversationId}</div>
                        ) : null}
                        <div style={{ wordWrap: 'break-word' }}>
                            {channelType === ChannelTypes.Voice &&
                            clientCallID != null
                                ? `${clientCallID}`
                                : ''}
                        </div>
                        <div>
                            {channelType === ChannelTypes.Voice
                                ? `Customer number ${callingNumber}`
                                : ''}
                        </div>
                        <div className="duration-wrap">
                            {typeImg}
                            <span>{typeText}</span>
                            {channelType === ChannelTypes.Voice ? (
                                <span>{`Messages ${messagesCount}`}</span>
                            ) : (
                                <span>{`Messages ${messagesCount}`}</span>
                            )}
                        </div>
                        <CallStatus>
                            {callStatusImg}
                            <span>{callStatusText}</span>
                        </CallStatus>
                        <div>
                            <ReactSVG
                                wrapper="span"
                                src="/images/dashboard/audio.svg"
                                onClick={() => {
                                    // call API to download Audio file

                                    const { year, month, date } =
                                        getDateComponents(startDate);

                                    getAudioCallLog(
                                        {
                                            year,
                                            month,
                                            date,
                                            conversationId
                                        },
                                        region
                                    )
                                        .then(data => {
                                            if (data.code === 200) {
                                                setAudioPlayer(data.data);
                                            }
                                        })
                                        .catch(e => {
                                            toast.error(
                                                'Could not load call recording. Please try again later.'
                                            );
                                        });
                                }}
                            />
                        </div>
                        {!hideMoreImg ? (
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '20%' }}>
                                <ReactSVG
                                    wrapper="span"
                                    className="more-svg"
                                    src={
                                        '/images/dashboard/arrow-more-blue.svg'
                                    }
                                    onClick={() =>
                                        onItemClick && onItemClick(i)
                                    }
                                />
                            </div>
                        ) : null}
                    </CallLogContentBigWrap>
                );
            })}
            {audioPlayer ? (
                <AudioPlayer
                    show={!!audioPlayer}
                    onClose={() => {
                        setAudioPlayer('');
                    }}
                    src={audioPlayer}
                />
            ) : null}
        </>
    );
}

const CallStatus = styled.div`
    display: flex;
    gap: 8px;
    white-space: nowrap;
    > span:first-child {
        margin-top: 2px;
    }
`;

const CallLogContentWrap = styled.div<{ hideMoreImg?: boolean }>`
    display: grid;
    gap: 10px;
    color: white;
    grid-template-columns: 25% 30% 15% 15% 2% 12%;
    > div {
        cursor: pointer;
    }
    .call-log-live {
        border: 1px solid ${COLORS.Interface_dark_glow};
        border-radius: 5px;
        padding: 4px 6px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    }

    .duration-wrap {
        position: relative;
        display: flex;
        padding-left: 25px;
        flex-direction: column;

        > span:first-child {
            position: absolute;
            left: 0px;
            top: 5px;
            max-width: 25px;

            svg {
                path {
                    fill: ${COLORS.primary_blue};
                }
            }
        }
    }
`;

const CallLogContentBigWrap = styled.div<{
    hideMoreImg?: boolean;
    showConvId?: boolean;
}>`
    display: grid;
    gap: 10px;
    color: white;
    grid-template-columns: ${({ showConvId }) =>
        showConvId
            ? '16% 18% 14% 15% 12% 10% 3% auto'
            : '20% 20% 14% 15% 14% 3% 3%'};
    > div {
        cursor: pointer;
    }
    .call-log-live {
        border: 1px solid ${COLORS.Interface_dark_glow};
        border-radius: 5px;
        padding: 4px 6px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    }

    .duration-wrap {
        position: relative;
        display: flex;
        padding-left: 25px;
        flex-direction: column;

        > span:first-child {
            position: absolute;
            left: 0px;
            top: 5px;
            max-width: 25px;

            svg {
                path {
                    fill: ${COLORS.primary_blue};
                }
            }
        }
    }
`;
