import {
    REGION,
    USER_POOL_ID,
    USER_POOL_APP_CLIENT_ID
} from 'src/constants/config';

const awsExports = {
    REGION,
    USER_POOL_ID,
    USER_POOL_APP_CLIENT_ID
};

export default awsExports;
