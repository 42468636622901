import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function FullPage() {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
        // redirect(`/sample/${params.id}`);
    };

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%'
            }}
        >
            <p>Full Page</p>
            <button onClick={goBack}>Go Back</button>
        </div>
    );
}
