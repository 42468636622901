import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { COLORS } from 'src/constants/colors';

export type ButtonT = {
    svgSrc?: string;
    text: string;
    selected?: boolean;
    onClick?: () => void;
    classname?: string;
};
export const Button = (props: ButtonT) => {
    const { selected, text, svgSrc, onClick, classname } = props;
    return (
        <ButtonWrap className={classname} selected={selected} onClick={onClick}>
            {svgSrc && <ReactSVG wrapper="span" src={svgSrc} />}
            <span>{text}</span>
        </ButtonWrap>
    );
};

const ButtonWrap = styled.div<{ selected?: boolean }>`
    display: inline-flex;
    gap: 5px;
    color: ${({ selected }) => (selected ? COLORS.primary_blue : 'white')};
    padding: 8px 12px;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    background: ${({ selected }) =>
        selected
            ? COLORS.interface_button_selected
            : COLORS.interface_button_default};
    cursor: pointer;

    &:hover {
        opacity: ${({ selected }) => (selected ? '1' : '0.8')};
    }
    > span {
        svg {
            margin-top: 2px;
        }
        ${({ selected }) =>
            selected &&
            `
            path {
                fill: ${COLORS.primary_blue};
            }
        `};
    }
`;
