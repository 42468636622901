import React, { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { Z_INDEXS } from 'src/constants/numbers';

const MODAL_VIEW_ID = 'view-for-modal';
const ModalWrap = styled.div`
    box-sizing: border-box;
    // z-index: ${Z_INDEXS.MODAL};
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    padding: 0;
    padding-bottom: 10vh;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    background: rgb(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    .no-padding {
        padding: 0px;
    }
`;

export type BaseModalProps = {
    show: boolean;
    handleOutsideClick?: (e: React.MouseEvent<HTMLElement>) => void;
    children?: ReactNode;
};

const BaseModal = (props: BaseModalProps) => {
    const { show, handleOutsideClick, children } = props;
    const mountView = document.getElementById(MODAL_VIEW_ID);
    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        if (handleOutsideClick) handleOutsideClick(e);
        e.stopPropagation();
    };
    if (!show) return null;

    const widget = <ModalWrap onClick={handleClick}>{children}</ModalWrap>;
    return mountView ? createPortal(widget, mountView) : widget;
};

export const ModalContainer = () => (
    <div id={MODAL_VIEW_ID} style={{ zIndex: Z_INDEXS.MODAL }} />
);

export default BaseModal;
