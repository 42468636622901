import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import {
    saveTestcase,
    deleteTestCase,
    getAllTestCases,
    updateTestcase,
    getTestCase,
    getAllTestCaseCategory,
    saveTestcaseCategory,
    engineWorkflow
} from 'src/api/user';
import ClientIdBotIdContext from 'src/components/sidebar/clientIdBotIdContext';
import {
    DeleteTestCasePayload,
    GetAllTestCasesPlayload,
    SaveTestCaseCategoryPayload,
    SaveTestCasePayload
} from 'src/types/testcase';

export const useSaveTestCaseHook = () => {
    const [status, setStatus] = useState<string>();
    const [data, setData] = useState();

    const fn = async (
        payload: SaveTestCasePayload,
        region: string,
        cb: Function | undefined
    ) => {
        setStatus('IN_PROGRESS');
        try {
            const resp = await saveTestcase(payload, region);
            if (resp?.code === 200) {
                setData(resp.data);
                setStatus('SUCCESS');
                if (cb) {
                    cb();
                }
                toast.success('Testcase saved successfully.');
            } else {
                toast.error('Could not save testcase.');
                setStatus('ERROR');
            }
        } catch (e: any) {
            toast.error(e.message);
            setStatus('ERROR');
        }
    };

    return {
        status,
        data,
        fn
    };
};

export const useUpdateTestCaseHook = () => {
    const [status, setStatus] = useState<string>();
    const [data, setData] = useState();

    const fn = async (
        payload: SaveTestCasePayload,
        region: string,
        testId: string,
        cb: Function | undefined
    ) => {
        setStatus('IN_PROGRESS');
        try {
            const resp = await updateTestcase(payload, testId, region);
            if (resp?.code === 200) {
                setData(resp.data);
                setStatus('SUCCESS');
                if (cb) {
                    cb();
                }
                toast.success('Testcase updated successfully.');
            } else {
                toast.error('Could not update testcase.');
                setStatus('ERROR');
            }
        } catch (e: any) {
            toast.error(e.message);
            setStatus('ERROR');
        }
    };

    return {
        status,
        data,
        fn
    };
};

export const useDeleteTestCaseHook = () => {
    const [status, setStatus] = useState<string>();
    const [data, setData] = useState();

    const fn = async (
        payload: DeleteTestCasePayload,
        region: string,
        cb: Function
    ) => {
        setStatus('IN_PROGRESS');
        try {
            const resp = await deleteTestCase(payload, region);
            if (resp?.code === 200) {
                setData(resp.data);
                setStatus('SUCCESS');
                if (cb) {
                    cb();
                }
            } else {
                toast.error('Could not delete testcase.');
                setStatus('ERROR');
            }
        } catch (e: any) {
            toast.error(e.message);
            setStatus('ERROR');
        }
    };

    return {
        status,
        data,
        fn
    };
};

export const useGetTestCaseHook = () => {
    const [status, setStatus] = useState<string>();
    const [error, setError] = useState<string>();
    const [data, setData] = useState();

    const fn = async (payload: DeleteTestCasePayload, region: string) => {
        setStatus('IN_PROGRESS');
        try {
            const resp = await getTestCase(payload, region);
            if (resp?.code === 200) {
                setData(resp.data);
                setStatus('SUCCESS');
            } else {
                setError('Could not get testcase.');
                setStatus('ERROR');
            }
        } catch (e: any) {
            setError(e.message);
            setStatus('ERROR');
        }
    };

    return {
        status,
        data,
        error,
        fn
    };
};

export const useGetAllTestCasesHook = () => {
    const [status, setStatus] = useState<string>();
    const [error, setError] = useState<string>();
    const [data, setData] = useState([]);

    const fn = async (payload: GetAllTestCasesPlayload, region: string) => {
        setStatus('IN_PROGRESS');
        try {
            const resp = await getAllTestCases(payload, region);
            if (resp?.code === 200) {
                const dt = resp.data.map((r: any) => ({
                    ...r,
                    testcases: r.testcases.map((tc: any) => ({
                        ...tc,
                        testcase: tc.testcase.map((t: any) => ({
                            ...t,
                            expected: '',
                            bot: ''
                        })),
                        runStatus: 'UNINIT',
                        selectToRun: true
                    })),
                    expanded: true
                }));

                setData(dt);
                setStatus('SUCCESS');
            } else {
                setError('Could not get all testcases.');
                setStatus('ERROR');
            }
        } catch (e: any) {
            setError(e.message);
            setStatus('ERROR');
        }
    };

    return {
        status,
        data,
        setData,
        error,
        fn
    };
};

export const useGetAllTestCaseCategoryHook = () => {
    const [status, setStatus] = useState<string>();
    const [error, setError] = useState<string>();
    const [data, setData] = useState([]);

    const fn = async (payload: GetAllTestCasesPlayload, region: string) => {
        setStatus('IN_PROGRESS');
        try {
            const resp = await getAllTestCaseCategory(payload, region);
            if (resp?.code === 200) {
                setData(resp.data);
                setStatus('SUCCESS');
            } else {
                setError('Could not get all testcase category.');
                setStatus('ERROR');
            }
        } catch (e: any) {
            setError(e.message);
            setStatus('ERROR');
        }
    };

    return {
        status,
        data,
        error,
        fn
    };
};

export const useSaveTestCaseCategoryHook = () => {
    const [status, setStatus] = useState<string>();

    const fn = async (
        payload: SaveTestCaseCategoryPayload,
        region: string,
        cb: Function | undefined
    ) => {
        setStatus('IN_PROGRESS');
        try {
            const resp = await saveTestcaseCategory(payload, region);
            if (resp?.code === 200) {
                setStatus('SUCCESS');
                if (cb) {
                    cb();
                }
                toast.success('Testcase category saved successfully.');
            } else {
                toast.error('Could not save testcase category.');
                setStatus('ERROR');
            }
        } catch (e: any) {
            toast.error(e.message);
            setStatus('ERROR');
        }
    };

    return {
        status,
        fn
    };
};

const getIndexes = (testCases: Array<any>, testId: string) => {
    let indexes = [] as number[];

    testCases.forEach((t: any, i: number) => {
        t.testcases.forEach((c: any, ii: number) => {
            if (c._id === testId) {
                indexes = [i, ii];
            }
        });
    });

    return indexes;
};

export const useRunTestCases = () => {
    const { botId, region } = useContext(ClientIdBotIdContext);
    const [runStatus, setRunStatus] = useState<string>('UNINIT');

    const fn = (testCases: Array<any>, setTestCases: Function) => {
        const promises = [] as any;

        const dup = JSON.parse(JSON.stringify(testCases));

        for (let i = 0; i < dup.length; i++) {
            for (let j = 0; j < dup[i].testcases.length; j++) {
                if (dup[i].testcases[j].selectToRun) {
                    dup[i].testcases[j].runStatus = 'IN_PROGRESS';
                    promises.push({
                        workflow: {
                            engineWorkflow: {
                                botId,
                                assertions: Array.isArray(
                                    dup[i].testcases[j].testcase
                                )
                                    ? dup[i].testcases[j].testcase.map(
                                          (a: any) => ({
                                              userSays: a.userSays,
                                              botSays: a.botSays
                                          })
                                      )
                                    : []
                            },
                            region
                        },
                        testcaseId: dup[i].testcases[j]._id
                    });
                }
            }
        }
        setTestCases(dup);

        if (promises.length > 0) {
            setRunStatus('IN_PROGRESS');

            Promise.allSettled(
                promises.map((p: any) =>
                    engineWorkflow(p.workflow.engineWorkflow, p.workflow.region)
                )
            )
                .then(data => {
                    data.forEach((d, i: number) => {
                        const [outer, inner] = getIndexes(
                            dup,
                            promises[i].testcaseId
                        );
                        if (d.status === 'fulfilled' && d?.value?.results) {
                            const sts = [] as boolean[];

                            d.value.results.forEach((r: any) =>
                                sts.push(r.expected === r.bot)
                            );

                            dup[outer].testcases[inner].runStatus = sts.every(
                                s => s
                            )
                                ? 'SUCCESS'
                                : 'ERROR';
                            dup[outer].testcases[inner].testcase =
                                d.value.results.map((r: any) => ({
                                    userSays: r.user,
                                    botSays: r.expected,
                                    bot: r.bot
                                }));
                        } else {
                            dup[outer].testcases[inner].runStatus = 'ERROR';
                        }
                        setTestCases(dup);
                    });
                    setRunStatus('SUCCESS');
                })
                .catch(e => {
                    console.log('---- In catch', e);
                });
        } else {
            toast.warning('Please select atleast one testcase to execute.');
        }
    };

    return {
        runStatus,
        fn
    };
};
