import React, { ChangeEvent, useEffect, useState } from 'react';
import { SWITCH_ROUTES } from 'src/constants/routes';
import { BillingDashboardWrap } from '../dashboard/BillingDashboard';
import {
    Breadcrumb,
    ScrollArea,
    WithDateRangeTitle
} from 'src/components/dashboard/Views';
import moment from 'moment';
import { CallLogWrap } from 'src/components/dashboard/CallLogPagination';
import { Button } from 'src/components/shared/Button';
import styled from 'styled-components';
import CreateUser from './components/CreateUser';
import { useGetUserAccounts } from './hooks';
import { PageTitle } from '../dashboard/Invoice';
import { COLORS } from 'src/constants/colors';
import { ReactSVG } from 'react-svg';
import Dropdown from 'src/components/shared/Dropdown';
import { Auth } from 'aws-amplify';

export const BREADCRUMB_ITEMS = [
    {
        text: 'Home',
        redirectUrl: SWITCH_ROUTES.DASHBOARD,
        svgSrc: '/images/sidebar/home.svg'
    },
    { text: 'User Management', redirecUrl: '' }
];

export default function UserMgmt() {
    const [authUser, setAuthUser] = useState<{
        attributes: {
            'custom:Role': string;
        };
    }>();
    const [conversationDate, setConversationDate] = useState({
        startDate: moment().subtract(1, 'month'),
        endDate: moment()
    });
    const [showCreate, setShowCreate] = useState<boolean>(false);
    const [showEdit, setShowEdit] = useState<string>('');
    const [authLoadingStatus, setAuthLoadingStatus] = useState('UNINIT');

    const [region, setRegion] = useState('AUS');

    const getAuthuser = async () => {
        try {
            setAuthLoadingStatus('IN_PROGRESS');
            const user = await Auth.currentAuthenticatedUser();
            setAuthUser(user);
            setAuthLoadingStatus('SUCCESS');
        } catch (e) {
            setAuthLoadingStatus('ERROR');
        }
    };

    useEffect(() => {
        getAuthuser();
    }, []);

    const {
        status: userAccountStatus,
        data: userAccounts,
        error: userAccountsError,
        fn: userAccountsFn
    } = useGetUserAccounts() as {
        status: string;
        data: Array<{
            attributes: {
                sub: string;
                email_verified: string;
                given_name: string;
                family_name: string;
                email: string;
                'custom:clientid': string;
                'custom:Role': string;
                'custom:company': string;
                phone_number: string;
            };
            enabled: true;
            userCreateDate: string;
            userLastModifiedDate: string;
            userStatus: string;
            username: string;
            clientIds: Array<string>;
        }>;
        error: any;
        fn: (region: string | undefined) => Promise<void>;
    };

    useEffect(() => {
        if (authUser && region) {
            userAccountsFn(region);
        }
    }, [authUser, region]);

    let regionBody = (<></>) as JSX.Element | null;

    if (authLoadingStatus === 'SUCCESS') {
        regionBody = (
            <div className="dp-container">
                <label>Select Region</label>
                <Dropdown
                    value={region}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                        setRegion(e.target.value)
                    }
                >
                    <option value="US">US</option>
                    <option value="AUS">AUS</option>
                </Dropdown>
            </div>
        );
    } else if (authLoadingStatus === 'ERROR') {
        regionBody = <p>Could not load regions.</p>;
    }

    let body = (<></>) as JSX.Element | Array<JSX.Element>;

    if (
        userAccountStatus === 'IN_PROGRESS' ||
        authLoadingStatus === 'IN_PROGRESS'
    ) {
        body = <p>Loading...</p>;
    } else if (userAccountStatus === 'SUCCESS') {
        body = userAccounts.map(d => (
            <InvoiceContentWrap key={d.attributes.sub}>
                <div>
                    <span>{d.clientIds.join(', ')}</span>
                </div>
                <div>
                    <span>{d.username}</span>
                </div>
                <div>
                    <span>{d.attributes.email}</span>
                </div>
                <div>
                    <span>
                        {d.attributes.given_name} {d.attributes.family_name}
                    </span>
                </div>
                <div>
                    <span>{d.attributes['custom:Role']}</span>
                </div>
                <div>
                    <span>{d.userStatus}</span>
                </div>
                <div>
                    <span
                        onClick={() => {
                            // Handle Edit User
                            setShowEdit(d.username);
                        }}
                    >
                        <ReactSVG
                            className="operation-icon"
                            wrapper="span"
                            src={'/images/shared/edit.svg'}
                        />
                        &nbsp;Edit
                    </span>
                </div>
            </InvoiceContentWrap>
        ));
    } else if (userAccountStatus === 'ERROR') {
        body = (
            <p>
                Error -{' '}
                {userAccountsError.message ||
                    'Could not load users, please try again later.'}
            </p>
        );
    }

    return (
        <>
            <BillingDashboardWrap>
                <Breadcrumb items={BREADCRUMB_ITEMS} />
                <ScrollArea>
                    <SubHeaderSection>
                        {regionBody}
                        <Button
                            text="Create User"
                            onClick={() => setShowCreate(true)}
                        />
                    </SubHeaderSection>
                    <WithDateRangeTitle
                        {...conversationDate}
                        hideDateRange={true}
                        setDates={setConversationDate}
                        childClassname="h-100 users-table-container"
                        customTitle={<PageTitle>Users</PageTitle>}
                        customFilterViews={null}
                        hideIcon
                        noFilter={true}
                    >
                        <CallLogWrap>
                            <InvoiceContentWrap>
                                <div>
                                    <span>
                                        <strong>Client ID</strong>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <strong>Username</strong>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <strong>Email</strong>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <strong>Name</strong>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <strong>Role</strong>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <strong>User Status</strong>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <strong>Action</strong>
                                    </span>
                                </div>{' '}
                            </InvoiceContentWrap>
                            {body}
                        </CallLogWrap>
                    </WithDateRangeTitle>
                </ScrollArea>
            </BillingDashboardWrap>
            {showCreate ? (
                <CreateUser
                    usernameToEdit={''}
                    show={showCreate}
                    onClose={() => setShowCreate(false)}
                    region={region}
                    role={authUser?.attributes['custom:Role']}
                    refresh={() => {
                        userAccountsFn(region);
                    }}
                />
            ) : null}
            {showEdit ? (
                <CreateUser
                    usernameToEdit={showEdit}
                    show={!!showEdit}
                    region={region}
                    role={authUser?.attributes['custom:Role']}
                    onClose={() => setShowEdit('')}
                    refresh={() => {
                        userAccountsFn(region);
                    }}
                />
            ) : null}
        </>
    );
}

export const SubHeaderSection = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 10px 0px;
    align-items: end;
    gap: 5px;

    > .dp-container {
        > label {
            font-size: 12px;
        }
    }
`;

export const InvoiceContentWrap = styled.div<{ hideMoreImg?: boolean }>`
    display: flex;
    align-items: flex-start;
    gap: 10px;
    color: white;
    > div {
        cursor: pointer;
    }
    > div:first-child {
        width: 14%;
    }
    > div:nth-child(2) {
        width: 14%;
    }
    > div:nth-child(3) {
        width: 15%;
        overflow-wrap: break-word;
    }
    > div:nth-child(4) {
        width: 14%;
        display: flex;
        gap: 8px;
        white-space: nowrap;
        > span:first-child {
            margin-top: 2px;
        }
    }
    > div:nth-child(5) {
        position: relative;
        width: 14%;
    }
    > div:nth-child(6) {
        position: relative;
        width: 14%;
    }
    > div:nth-child(7) {
        width: 14%;

        > span:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .duration-wrap {
        position: relative;
        display: flex;
        padding-left: 25px;
        flex-direction: column;

        > span:first-child {
            position: absolute;
            left: 0px;
            top: 5px;
            max-width: 25px;

            svg {
                path {
                    fill: ${COLORS.primary_blue};
                }
            }
        }
    }

    .more-svg {
        position: absolute;
        right: 20px;
    }
`;
