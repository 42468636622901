import React, { useContext, useEffect } from 'react';
import Dropdown from '../shared/Dropdown';
import ClientIdBotIdContext from './clientIdBotIdContext';
import { useFetchAllClientIds } from './hooks';
import styled from 'styled-components';

export default function ClientBotFilter() {
    const {
        clientId,
        setClientId,
        botId,
        setBotId,
        setRegion,
        setTimezone,
        setAccountId
    } = useContext(ClientIdBotIdContext);

    const {
        status: cStatus,
        error: cError,
        data: cData,
        fetchFn: cFetch
    } = useFetchAllClientIds() as {
        status: string;
        error: string;
        data: Array<{
            clientName: string;
            clientId: string;
            accountId: string;
            accountName: string;
            region: string;
            timezone: string;
            bots: Array<{
                botId: string;
                botPhoneNumber: string;
                description: string;
                channels: string;
            }>;
        }>;
        fetchFn: () => void;
    };

    useEffect(() => {
        cFetch();
    }, []);

    const ifSingleSubAccountAndBot = () => {
        return cData.length === 1 && cData[0].bots.length === 1;
    };

    let ClientIdBody = (<></>) as JSX.Element | null;

    if (cStatus === 'IN_PROGRESS') {
        ClientIdBody = <p>Loading...</p>;
    } else if (cStatus === 'SUCCESS') {
        ClientIdBody = !ifSingleSubAccountAndBot() ? (
            <>
                <div className="dp-container">
                    <label>Account</label>
                    <Dropdown
                        value={clientId}
                        onChange={(e: any) => {
                            setClientId(e.target.value);
                            setRegion(
                                cData.find(c => c.clientId === e.target.value)
                                    ?.region
                            );
                            setTimezone(
                                cData.find(c => c.clientId === e.target.value)
                                    ?.timezone
                            );
                            setBotId(
                                cData.find(c => c.clientId === e.target.value)
                                    ?.bots[0]?.botId
                            );
                            setAccountId(
                                cData.find(c => c.clientId === e.target.value)
                                    ?.accountId
                            );
                        }}
                    >
                        {cData.map((c: any) => (
                            <option key={c.clientId} value={c.clientId}>
                                {c.clientName}
                            </option>
                        ))}
                    </Dropdown>
                </div>
                <div className="dp-container">
                    <label>Digital Assistant</label>
                    <Dropdown
                        value={botId}
                        onChange={(e: any) => setBotId(e.target.value)}
                    >
                        {/* <option value=""></option> */}
                        {cData
                            .find(c => c.clientId === clientId)
                            ?.bots.map(b => (
                                <option key={b.botId} value={b.botId}>
                                    {`${b.description}`}
                                </option>
                            ))}
                    </Dropdown>
                </div>
            </>
        ) : null;
    } else if (cStatus === 'ERROR') {
        ClientIdBody = <p>{cError || 'Could not load sub accounts.'}</p>;
    }

    return <FilterContainer>{ClientIdBody}</FilterContainer>;
}

const FilterContainer = styled.div`
    padding: 10px;
    select {
        width: 100%;
    }
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 120px;
    .dp-container {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
`;
