import React, { ChangeEvent, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BaseModal from 'src/components/shared/modals/BaseModal';
import styled from 'styled-components';
import { COLORS } from 'src/constants/colors';
import { ReactSVG } from 'react-svg';
import Dropdown from 'src/components/shared/Dropdown';
import { Button } from 'src/components/shared/Button';
import { toast } from 'react-toastify';
import {
    useCreateUser,
    useGetMasterAccounts,
    useGetUserDetail,
    useUpdateUser
} from '../hooks';
import {
    REGION,
    USER_POOL_APP_CLIENT_ID,
    USER_POOL_ID
} from 'src/constants/config';

type CreateUserProps = {
    show: boolean;
    onClose: Function;
    usernameToEdit: string | undefined;
    region: string;
    role: string | undefined;
    refresh: () => void;
};

export default function CreateUser({
    show,
    onClose,
    usernameToEdit,
    region,
    role,
    refresh
}: CreateUserProps) {
    // need to fetch all clients.

    const [form, setForm] = useState<{
        masterAccount: string;
        clientIds: Array<{
            id: string;
            value: string;
        }>;
        company: string;
        role: string;
        email: string;
        familyName: string;
        givenName: string;
        phoneNumber: string;
        website: string;
        password: string;
        userName: string;
    }>({
        masterAccount: '',
        company: '',
        clientIds: [],
        role: '',
        email: '',
        familyName: '',
        givenName: '',
        phoneNumber: '',
        website: '',
        password: '',
        userName: ''
    });

    const {
        status: fetchStatus,
        data,
        error: fetchError,
        fn: fetchFn
    } = useGetMasterAccounts() as {
        status: string;
        data: any;
        error: string;
        fn: Function;
    };

    const {
        status: userDetailStatus,
        error: userDetailError,
        fn: userDetailFn
    } = useGetUserDetail();

    const { status: updateStatus, fn: updateFn } = useUpdateUser();

    useEffect(() => {
        fetchFn(region);
        // check if usernameToEdit is present, then make an API call and pull of the user details
        if (usernameToEdit) {
            userDetailFn(
                usernameToEdit,
                (data: any) => {
                    setForm({
                        ...form,
                        ...data
                    });
                },
                region
            );
        }
    }, []);

    const { status: saveStatus, fn: saveFn } = useCreateUser() as {
        status: string;
        fn: (
            payload: any,
            cb: Function,
            region: string | undefined
        ) => Promise<void | undefined>;
    };

    let body = <></>;

    if (fetchStatus === 'IN_PROGRESS') {
        if (usernameToEdit) {
            if (userDetailStatus === 'IN_PROGRESS') {
                body = <p>Loading...</p>;
            }
        } else {
            body = <p>Loading...</p>;
        }
    } else if (fetchStatus === 'SUCCESS') {
        const bdy = (
            <>
                <FormContainer>
                    <div className="form-row-item">
                        <div className="dp-container">
                            <label>Select Master Account</label>
                            <Dropdown
                                value={form.masterAccount}
                                disabled={!!usernameToEdit}
                                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                                    setForm({
                                        ...form,
                                        masterAccount: e.target.value
                                    })
                                }
                            >
                                <option value="">Choose</option>
                                {data.map((d: any) => (
                                    <option
                                        value={d.masteraccountname}
                                        key={d.masteraccountname}
                                    >
                                        {d.masteraccountname}
                                    </option>
                                ))}
                            </Dropdown>
                        </div>
                    </div>
                    <div className="form-row-item">
                        <div className="dp-container">
                            {form.masterAccount ? (
                                <label>Select Clients</label>
                            ) : null}
                            <div className="client-chkboxes">
                                {data
                                    .find(
                                        (d: any) =>
                                            d.masteraccountname ===
                                            form.masterAccount
                                    )
                                    ?.clients.map((c: any) => (
                                        <div>
                                            <input
                                                id={c.clientid}
                                                type="checkbox"
                                                disabled={!!usernameToEdit}
                                                checked={
                                                    form.clientIds.findIndex(
                                                        (cl: any) =>
                                                            cl.id === c.clientid
                                                    ) > -1
                                                }
                                                onChange={() => {
                                                    // check if clientid exists
                                                    if (
                                                        form.clientIds.findIndex(
                                                            (cl: any) =>
                                                                cl.id ===
                                                                c.clientid
                                                        ) > -1
                                                    ) {
                                                        // remove it from the list
                                                        setForm({
                                                            ...form,
                                                            clientIds:
                                                                form.clientIds.filter(
                                                                    cd =>
                                                                        cd.id !==
                                                                        c.clientid
                                                                )
                                                        });
                                                    } else {
                                                        // add it to the list
                                                        setForm({
                                                            ...form,
                                                            clientIds: [
                                                                ...form.clientIds,
                                                                {
                                                                    id: c.clientid,
                                                                    value: c.clientname
                                                                }
                                                            ]
                                                        });
                                                    }
                                                }}
                                            />
                                            <label htmlFor={c.clientid}>
                                                {c.clientname}
                                            </label>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                    <div className="form-row-item">
                        <div className="dp-container">
                            <label>Company</label>
                            <input
                                type="text"
                                disabled={!!usernameToEdit}
                                value={form.company}
                                placeholder="Enter company Name"
                                onChange={e =>
                                    setForm({
                                        ...form,
                                        company: e.target.value
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="form-row-item">
                        <div className="dp-container">
                            <label>Role</label>
                            <Dropdown
                                value={form.role}
                                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                                    setForm({
                                        ...form,
                                        role: e.target.value
                                    })
                                }
                            >
                                <option value="">Choose</option>
                                {['VERSA', 'CLIENT'].map(c => (
                                    <option key={c}>{c}</option>
                                ))}
                            </Dropdown>
                        </div>
                    </div>
                    <div className="form-row-item flex-50">
                        <div className="dp-container">
                            <label>Given Name*</label>
                            <input
                                type="text"
                                value={form.givenName}
                                placeholder="Enter given Name"
                                onChange={e =>
                                    setForm({
                                        ...form,
                                        givenName: e.target.value
                                    })
                                }
                            />
                        </div>
                        <div className="dp-container">
                            <label>Family Name*</label>
                            <input
                                type="text"
                                value={form.familyName}
                                placeholder="Enter family Name"
                                onChange={e =>
                                    setForm({
                                        ...form,
                                        familyName: e.target.value
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="form-row-item">
                        <div className="dp-container">
                            <label>Email*</label>
                            <input
                                type="email"
                                autoComplete="new-email"
                                value={form.email}
                                placeholder="Enter email"
                                onChange={e =>
                                    setForm({
                                        ...form,
                                        email: e.target.value
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="form-row-item flex-50">
                        <div className="dp-container">
                            <label>Username*</label>
                            <input
                                type="email"
                                disabled={!!usernameToEdit}
                                autoComplete="new-username"
                                value={form.userName}
                                placeholder="Enter username"
                                onChange={e =>
                                    setForm({
                                        ...form,
                                        userName: e.target.value
                                    })
                                }
                            />
                        </div>
                        <div className="dp-container">
                            <label>Password*</label>
                            <input
                                type="password"
                                disabled={!!usernameToEdit}
                                autoComplete="new-password"
                                value={form.password}
                                placeholder="Enter password"
                                onChange={e =>
                                    setForm({
                                        ...form,
                                        password: e.target.value
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="form-row-item flex-50">
                        <div className="dp-container">
                            <label>Phone Number*</label>
                            <input
                                type="text"
                                value={form.phoneNumber}
                                placeholder="Enter phone number"
                                onChange={e =>
                                    setForm({
                                        ...form,
                                        phoneNumber: e.target.value
                                    })
                                }
                            />
                        </div>
                        <div className="dp-container">
                            <label>Website</label>
                            <input
                                type="text"
                                disabled={!!usernameToEdit}
                                value={form.website}
                                placeholder="Enter website"
                                onChange={e =>
                                    setForm({
                                        ...form,
                                        website: e.target.value
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="form-row-item flex-50">
                        <div>
                            <Button
                                text={
                                    usernameToEdit
                                        ? updateStatus === 'IN_PROGRESS'
                                            ? 'Editing User...'
                                            : 'Edit user'
                                        : saveStatus === 'IN_PROGRESS'
                                        ? 'Creating User...'
                                        : 'Create User'
                                }
                                onClick={() => {
                                    // write handler to handle action to save.
                                    if (usernameToEdit) {
                                        if (
                                            form.userName &&
                                            form.givenName &&
                                            form.email
                                        ) {
                                            updateFn(
                                                usernameToEdit,
                                                {
                                                    given_name: form.givenName,
                                                    family_name:
                                                        form.familyName,
                                                    email: form.email,
                                                    role: form.role,
                                                    userPoolId: USER_POOL_ID,
                                                    userPoolClientid:
                                                        USER_POOL_APP_CLIENT_ID,
                                                    cognitoRegion: REGION
                                                },
                                                () => {
                                                    onClose();
                                                    refresh();
                                                },
                                                region
                                            );
                                        } else {
                                            toast.warning(
                                                'Please add Given name, Last name and email to proceed.'
                                            );
                                        }
                                    } else {
                                        if (saveStatus !== 'IN_PROGRESS') {
                                            if (
                                                form.userName &&
                                                form.givenName &&
                                                form.familyName &&
                                                form.email &&
                                                form.clientIds.length
                                            ) {
                                                saveFn(
                                                    {
                                                        masterAccount:
                                                            form.masterAccount,
                                                        clients: form.clientIds,
                                                        company: form.company,
                                                        role: form.role,
                                                        givenName:
                                                            form.givenName,
                                                        familyName:
                                                            form.familyName,
                                                        email: form.email,
                                                        phoneNumber:
                                                            form.phoneNumber,
                                                        website: form.website,
                                                        password: form.password,
                                                        userName: form.userName,
                                                        userPoolId:
                                                            USER_POOL_ID,
                                                        cognitoRegion: REGION,
                                                        userPoolClientid:
                                                            USER_POOL_APP_CLIENT_ID // check for userPoolClientid
                                                    },
                                                    () => {
                                                        onClose();
                                                        refresh();
                                                    },
                                                    region
                                                );
                                            } else {
                                                toast.warning(
                                                    'Please add Username, Given name, Last name, ClientId and email to proceed.'
                                                );
                                            }
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                </FormContainer>
            </>
        );
        if (usernameToEdit) {
            if (userDetailStatus === 'SUCCESS') {
                body = bdy;
            }
        } else {
            body = bdy;
        }
    } else if (fetchStatus === 'ERROR' || userDetailStatus === 'ERROR') {
        body = (
            <p>
                Error -{' '}
                {fetchError ||
                    userDetailError ||
                    'Could not load user details.'}
            </p>
        );
    }

    return (
        <BaseModal show={show}>
            <div
                style={{
                    margin: '0 auto',
                    maxHeight: '90vh',
                    overflowY: 'scroll'
                }}
            >
                <CreateContainer>
                    <div>
                        {usernameToEdit ? 'Edit User' : 'Create New User'}
                        <div className="divide-line" />
                        <ReactSVG
                            wrapper="span"
                            src={'/images/shared/close.svg'}
                            className="close-btn"
                            onClick={() => {
                                onClose();
                            }}
                        />
                    </div>
                    {body}
                </CreateContainer>
            </div>
        </BaseModal>
    );
}

export const CreateContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-width: 818px;
    background: linear-gradient(
        rgb(0, 25, 70) 0%,
        rgb(12, 33, 90) 32.81%,
        rgb(0, 25, 70) 100%
    );
    border: 1px solid ${COLORS.Interface_dark_glow};
    overflow-y: scroll;

    > div {
        padding: 20px;
    }
    > div:nth-child(2) {
        padding-top: 10px;
    }
    > div:first-child {
        padding-bottom: 0px;
        position: relative;
        background: ${COLORS.neutrals_black_2_noa};

        .close-btn {
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
        }
    }

    .divide-line {
        background: ${({ theme }) => theme.interface_dark_glow};
        width: 100%;
        height: 1px;
        margin: 20px 0px;
    }
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    .form-row-item.flex-50 {
        display: flex;
        gap: 20px;

        > div {
            flex-basis: 50%;
        }
    }

    .form-row-item {
        .dp-container {
            display: flex;
            flex-direction: column;
            gap: 10px;

            select,
            input[type='text'],
            input[type='email'],
            input[type='password'] {
                width: 100%;
                background: rgb(0, 25, 70);
                border: 1px solid rgb(6, 61, 181);
                height: 30px;
                color: white;
                padding: 6px 8px;
                backdrop-filter: blur(2px);
                border-radius: 4px;
            }

            .client-chkboxes {
                display: flex;
                gap: 20px;
            }
        }
    }
`;

CreateUser.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    usernameToEdit: PropTypes.oneOf([PropTypes.string]),
    region: PropTypes.string,
    role: PropTypes.string
};
