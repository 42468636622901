import { VERSA_CONFIG } from 'src/constants/config';
import {
    SaveTestCaseCategoryPayload,
    SaveTestCasePayload
} from 'src/types/testcase';
import { fetchWrapper } from 'src/utils/fetchWrapper';

export const getUsers = (region: string) => {
    let url = '';
    if (process.env.REACT_APP_ENV === 'development') {
        url = `${VERSA_CONFIG.DEV_API_DOMAIN}/napi/user/users?userPoolId=${VERSA_CONFIG.USER_POOL_ID}&cognitoRegion=${VERSA_CONFIG.REGION}`;
    } else {
        url = `${VERSA_CONFIG.US_API_DOMAIN}/napi/user/users?userPoolId=${VERSA_CONFIG.USER_POOL_ID}&cognitoRegion=${VERSA_CONFIG.REGION}`;
    } 
    return fetchWrapper(url, 'GET');
};

export const getMasterAccounts = (region: string) => {
    let url = '';
    if (process.env.REACT_APP_ENV === 'development') {
        url = `${VERSA_CONFIG.DEV_API_DOMAIN}/napi/user/master-accounts`;
    } else  {
        url = `${VERSA_CONFIG.US_API_DOMAIN}/napi/user/master-accounts`;
    } 
    return fetchWrapper(url, 'GET');
};

export const getUserDetail = (userId: string, region: string) => {
    let url = '';

    if (process.env.REACT_APP_ENV === 'development') {
        url = `${VERSA_CONFIG.DEV_API_DOMAIN}/napi/user/user/${userId}?userPoolId=${VERSA_CONFIG.USER_POOL_ID}&cognitoRegion=${VERSA_CONFIG.REGION}`;
    } else {
        url = `${VERSA_CONFIG.US_API_DOMAIN}/napi/user/user/${userId}?userPoolId=${VERSA_CONFIG.USER_POOL_ID}&cognitoRegion=${VERSA_CONFIG.REGION}`;
    } 

    return fetchWrapper(url, 'GET');
};

export const saveUser = (payload: any, region: string) => {
    let url = '';
    if (process.env.REACT_APP_ENV === 'development') {
        url = `${VERSA_CONFIG.DEV_API_DOMAIN}/napi/user/user`;
    } else {
        url = `${VERSA_CONFIG.US_API_DOMAIN}/napi/user/user`;
    } 

    return fetchWrapper(url, 'POST', payload);
};

export const updateUser = (userId: string, payload: any, region: string) => {
    let url = '';
    if (process.env.REACT_APP_ENV === 'development') {
        url = `${VERSA_CONFIG.DEV_API_DOMAIN}/napi/user/user/${userId}`;
    } else {
        url = `${VERSA_CONFIG.US_API_DOMAIN}/napi/user/user/${userId}`;
    } 

    return fetchWrapper(url, 'PATCH', payload);
};

export const engineWorkflow = (
    payload: {
        botId: string;
        assertions: Array<{ userSays: string; botSays: string }>;
    },
    region: string
) => {
    let url = '';
    if (process.env.REACT_APP_ENV === 'development') {
        url = `${VERSA_CONFIG.DEV_API_DOMAIN}/napi/engineWorkflow`;
    } else {
        url = `${VERSA_CONFIG.US_API_DOMAIN}/napi/engineWorkflow`;
    } 

    return fetchWrapper(url, 'POST', payload);
};

export const saveTestcase = (payload: SaveTestCasePayload, region: string) => {
    // let url = 'http://localhost:4000/napi/engineWorkflow/testcase';
    let url = '';
    if (process.env.REACT_APP_ENV === 'development') {
        url = `${VERSA_CONFIG.DEV_API_DOMAIN}/napi/engineWorkflow/testcase`;
    } else {
        url = `${VERSA_CONFIG.US_API_DOMAIN}/napi/engineWorkflow/testcase`;
    }

    return fetchWrapper(url, 'POST', payload);
};

export const getAllTestCases = (
    payload: {
        botId: string;
    },
    region: string
) => {
    // let url = `http://localhost:4000/napi/engineWorkflow/testcase/all?botId=${payload.botId}`;
    let url = '';
    if (process.env.REACT_APP_ENV === 'development') {
        url = `${VERSA_CONFIG.DEV_API_DOMAIN}/napi/engineWorkflow/testcase/all?botId=${payload.botId}`;
    } else {
        url = `${VERSA_CONFIG.US_API_DOMAIN}/napi/engineWorkflow/testcase/all?botId=${payload.botId}`;
    }

    return fetchWrapper(url, 'GET');
};

export const saveTestcaseCategory = (
    payload: SaveTestCaseCategoryPayload,
    region: string
) => {
    // let url = 'http://localhost:4000/napi/engineWorkflow/testcase/category';
    let url = '';
    if (process.env.REACT_APP_ENV === 'development') {
        url = `${VERSA_CONFIG.DEV_API_DOMAIN}/napi/engineWorkflow/testcase/category`;
    } else  {
        url = `${VERSA_CONFIG.US_API_DOMAIN}/napi/engineWorkflow/testcase/category`;
    } 

    return fetchWrapper(url, 'POST', payload);
};

export const getAllTestCaseCategory = (
    payload: {
        botId: string;
    },
    region: string
) => {
    // let url = `http://localhost:4000/napi/engineWorkflow/testcase/category/all?botId=${payload.botId}`;
    let url = '';
    if (process.env.REACT_APP_ENV === 'development') {
        url = `${VERSA_CONFIG.DEV_API_DOMAIN}/napi/engineWorkflow/testcase/category/all?botId=${payload.botId}`;
    } else {
        url = `${VERSA_CONFIG.US_API_DOMAIN}/napi/engineWorkflow/testcase/category/all?botId=${payload.botId}`;
    } 

    return fetchWrapper(url, 'GET');
};

export const getTestCase = (
    payload: {
        testCaseId: string;
    },
    region: string
) => {
    // let url = `http://localhost:4000/napi/engineWorkflow/testcase/${payload.testCaseId}`;
    let url = '';
    if (process.env.REACT_APP_ENV === 'development') {
        url = `${VERSA_CONFIG.DEV_API_DOMAIN}/napi/engineWorkflow/testcase/${payload.testCaseId}`;
    } else {
        url = `${VERSA_CONFIG.US_API_DOMAIN}/napi/engineWorkflow/testcase/${payload.testCaseId}`;
    } 

    return fetchWrapper(url, 'GET');
};

export const deleteTestCase = (
    payload: {
        testCaseId: string;
    },
    region: string
) => {
    // let url = `http://localhost:4000/napi/engineWorkflow/testcase/${payload.testCaseId}`;
    let url = '';
    if (process.env.REACT_APP_ENV === 'development') {
        url = `${VERSA_CONFIG.DEV_API_DOMAIN}/napi/engineWorkflow/testcase/${payload.testCaseId}`;
    } else {
        url = `${VERSA_CONFIG.US_API_DOMAIN}/napi/engineWorkflow/testcase/${payload.testCaseId}`;
    } 

    return fetchWrapper(url, 'DELETE');
};

export const updateTestcase = (
    payload: SaveTestCasePayload,
    testCaseId: string,
    region: string
) => {
    // let url = `http://localhost:4000/napi/engineWorkflow/testcase/${testCaseId}`;
    let url = '';
    if (process.env.REACT_APP_ENV === 'development') {
        url = `${VERSA_CONFIG.DEV_API_DOMAIN}/napi/engineWorkflow/testcase/${testCaseId}`;
    } else {
        url = `${VERSA_CONFIG.US_API_DOMAIN}/napi/engineWorkflow/testcase/${testCaseId}`;
    } 

    return fetchWrapper(url, 'PATCH', payload);
};

export const downloadConvReport = (key: string, region: string) => {
    // let url = `http://localhost:4000/napi/export/conv/report?key=${key}&region=${region}`;
    let url = '';
    if (process.env.REACT_APP_ENV === 'development') {
        url = `${VERSA_CONFIG.DEV_API_DOMAIN}/napi/export/conv/report?key=${key}&region=${region}`;
    } else {
        url = `${VERSA_CONFIG.US_API_DOMAIN}/napi/export/conv/report?key=${key}&region=${region}`;
    }

    return fetchWrapper(url, 'GET');
};
