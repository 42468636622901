import React, { useState, useEffect } from 'react';
import {
    LoginView,
    PageWrap,
    Portion,
    Welcome
} from 'src/components/Login/Views';
import { Footer } from 'src/components/shared/Footer';
import { Input } from 'src/components/shared/Inputs';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

const initialState = { username: '', code: '', new_password: '' };
export default function ForgotPasswordPage() {
    const navigate = useNavigate();
    const [formState, setFormState] = useState(initialState);
    const [showCode, setShowCode] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    function setInput(key: any, value: any) {
        setFormState({ ...formState, [key]: value });
    }
    function forgotpwd() {
        setErrorMsg('');
        try {
            if (!formState.username) return;
            const { username } = { ...formState };
            Auth.forgotPassword(username)
                .then(data => {
                    console.log(data);
                    setShowCode(true);
                })
                .catch(err => setErrorMsg(err));
            setFormState(initialState);
        } catch (err) {
            console.log('forgotpwd catche error:', err);
        }
    }
    function SubmitNewPassword() {
        setErrorMsg('');
        if (!formState.username || !formState.new_password || !formState.code)
            return;
        const { username, code, new_password } = { ...formState };
        // Collect confirmation code and new password, then
        Auth.forgotPasswordSubmit(username, code, new_password)
            .then(data => console.log(data))
            .catch(err => setErrorMsg(err));
        setFormState(initialState);
        navigateLogin();
    }
    function navigateLogin() {
        navigate('/login');
    }
    return (
        <PageWrap>
            <Portion>
                <Welcome />
                <div />
                <LoginView>
                    <>
                        <p>Forgot Password</p>
                        {!showCode ? (
                            <p>
                                Enter the username below and press ‘Resend’ and
                                follow the instructions in your email.
                            </p>
                        ) : (
                            <p>
                                Enter the username,code, new password below and
                                press 'Change Password'
                            </p>
                        )}
                        <div className="input-wrap">
                            <Input
                                onChange={event =>
                                    setInput('username', event.target.value)
                                }
                                value={formState.username}
                                placeholder="Username"
                            />
                            {showCode ? (
                                <Input
                                    onChange={event =>
                                        setInput('code', event.target.value)
                                    }
                                    value={formState.code}
                                    placeholder="Code"
                                />
                            ) : null}
                            {showCode ? (
                                <Input
                                    type="password"
                                    onChange={event =>
                                        setInput(
                                            'new_password',
                                            event.target.value
                                        )
                                    }
                                    value={formState.new_password}
                                    placeholder="New Password"
                                />
                            ) : null}
                        </div>
                        {errorMsg && <p className="error-msg">{errorMsg}</p>}
                        {showCode && <p className="error-msg">Password must contain a combination of upper case, lower case, numbers and special character.</p>}
                        <div className="buttons">
                            <p onClick={navigateLogin}>Login</p>
                            {!showCode ? (
                                <button onClick={forgotpwd}>Resend</button>
                            ) : (
                                <button onClick={SubmitNewPassword}>
                                    Change Password
                                </button>
                            )}
                        </div>
                    </>
                </LoginView>
            </Portion>
            <Footer classname="fixed-footer" />
        </PageWrap>
    );
}
