export const SIDEBAR_WIDTH_EXPAND = 300;
export const SIDEBAR_WIDTH_COLLAPSE = 80;
export const MENU_LOGO_VIEW_HEIGHT = 105;
export const FOOTER_WRAP_HEIGHT = 70;

export const Z_INDEXS = {
    USER_INFO_FLOATING: 10,
    REACT_DATEPICKER_POPPER: 100,
    MODAL: 800
};
