import styled from 'styled-components';
import BaseModal from 'src/components/shared/modals/BaseModal';
import { ReactSVG } from 'react-svg';
import { COLORS } from 'src/constants/colors';
import { Button } from 'src/components/shared/Button';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Dropdown from 'src/components/shared/Dropdown';
import {
    useGetAllTestCaseCategoryHook,
    useSaveTestCaseHook,
    useUpdateTestCaseHook
} from '../hooks';
import { TestCaseCategory } from 'src/types/testcase';
import ClientIdBotIdContext from 'src/components/sidebar/clientIdBotIdContext';

export default function EditTestCase({
    show,
    onClose,
    testCase,
    refresh,
    agentDetails
}: {
    show: boolean;
    onClose: Function;
    testCase: any;
    refresh: Function;
    agentDetails: any;
}): JSX.Element {
    const { botId, region } = useContext(ClientIdBotIdContext);
    const [n, setN] = useState<string>(testCase?.name || '');
    const [cat, setCat] = useState<string>(testCase?.category || '');

    const { status: saveStatus, fn: saveFn } = useSaveTestCaseHook();
    const { status: updateStatus, fn: updateFn } = useUpdateTestCaseHook();

    // fetch all categories on mount

    const { status, data, error, fn } = useGetAllTestCaseCategoryHook();

    useEffect(() => {
        fn(
            {
                botId
            },
            region
        );
    }, []);

    let body = <></>;

    if (status === 'IN_PROGRESS') {
        body = <p>Loading...</p>;
    } else if (status === 'SUCCESS') {
        body = (
            <>
                <div className="form-row-item flex-50">
                    <div className="dp-container">
                        <label>Choose Category*</label>
                        <Dropdown
                            value={cat}
                            onChange={(
                                e: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                                setCat(e.target.value);
                            }}
                        >
                            <option>--Choose--</option>
                            {data.map((d: TestCaseCategory) => (
                                <option key={d._id} value={d._id}>
                                    {d.name}
                                </option>
                            ))}
                        </Dropdown>
                    </div>
                </div>
                <div className="form-row-item flex-50">
                    <div className="dp-container">
                        <label>TestCase Name</label>
                        <input
                            type="text"
                            value={n}
                            placeholder="Enter testcase name"
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => setN(e.target.value)}
                        />
                    </div>
                </div>
                <div className="form-row-item flex-50">
                    <div>
                        <Button
                            text={
                                saveStatus === 'IN_PROGRESS' ||
                                updateStatus === 'IN_PROGRESS'
                                    ? 'Saving...'
                                    : 'Save'
                            }
                            onClick={() => {
                                if (n) {
                                    // call save or update function here.

                                    if (testCase) {
                                        updateFn(
                                            {
                                                botId,
                                                name: n,
                                                categoryId: cat,
                                                testcase: testCase.testcase.map(
                                                    (t: any) => ({
                                                        userSays: t.userSays,
                                                        botSays: t.botSays
                                                    })
                                                ),
                                                createdBy: undefined
                                            },
                                            region,
                                            testCase._id,
                                            () => {
                                                refresh();
                                            }
                                        );
                                    } else {
                                        saveFn(
                                            {
                                                botId,
                                                name: n,
                                                categoryId: cat,
                                                testcase: [
                                                    {
                                                        userSays: '',
                                                        botSays: ''
                                                    }
                                                ],
                                                createdBy: {
                                                    agentName:
                                                        agentDetails.contactName,
                                                    role: agentDetails.role
                                                }
                                            },
                                            region,
                                            () => {
                                                refresh();
                                            }
                                        );
                                    }
                                } else {
                                    toast.warning(
                                        'Please enter a name to save.'
                                    );
                                }
                            }}
                        />
                    </div>
                </div>
            </>
        );
    } else if (status === 'ERROR') {
        body = (
            <p>
                {error || 'Could not load categories, please try again later.'}
            </p>
        );
    }

    return (
        <BaseModal show={show}>
            <div
                style={{
                    margin: '0 auto',
                    maxHeight: '90vh',
                    overflowY: 'scroll'
                }}
            >
                <BodyContainer>
                    <div>
                        {testCase ? 'Edit TestCase' : 'New TestCase'}
                        <div className="divide-line" />
                        <ReactSVG
                            wrapper="span"
                            src={'/images/shared/close.svg'}
                            className="close-btn"
                            onClick={() => {
                                onClose();
                            }}
                        />
                    </div>
                    {body}
                </BodyContainer>
            </div>
        </BaseModal>
    );
}

export const BodyContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-width: 400px;
    background: linear-gradient(
        rgb(0, 25, 70) 0%,
        rgb(12, 33, 90) 32.81%,
        rgb(0, 25, 70) 100%
    );
    border: 1px solid ${COLORS.Interface_dark_glow};
    overflow-y: scroll;

    > div {
    }
    > div:nth-child(2),
    > div:nth-child(3),
    > div:nth-child(4) {
        padding: 10px;
    }
    > div:first-child {
        padding: 20px;
        padding-bottom: 0px;
        position: relative;
        background: ${COLORS.neutrals_black_2_noa};

        .close-btn {
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
        }
    }

    .divide-line {
        background: ${({ theme }) => theme.interface_dark_glow};
        width: 100%;
        height: 1px;
        margin: 20px 0px;
    }

    select,
    input[type='text'],
    input[type='email'],
    input[type='password'] {
        width: 100%;
        background: rgb(0, 25, 70);
        border: 1px solid rgb(6, 61, 181);
        height: 30px;
        color: white;
        padding: 6px 8px;
        backdrop-filter: blur(2px);
        border-radius: 4px;
        margin: 10px 0px;
    }
`;
