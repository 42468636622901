import { Moment } from 'moment';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { getCallHistory, getInvoice, getInvoices } from 'src/api/invoice';
import ClientIdBotIdContext from 'src/components/sidebar/clientIdBotIdContext';

export const useInvoiceList = () => {
    const [status, setStatus] = useState('UNINIT');
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
    const { region } = useContext(ClientIdBotIdContext);

    const fetchFn: (botId: string) => Promise<void> = async (botId: string) => {
        setStatus('IN_PROGRESS');
        try {
            const invoices = await getInvoices(
                {
                    filter: {
                        ClientId: botId
                    }
                },
                region
            );
            if (invoices?.code === 200) {
                setData(invoices.data[0].data);
                setStatus('SUCCESS');
            } else {
                setError('Could not fetch Invoice list.');
                setStatus('ERROR');
            }
        } catch (e: any) {
            setError(e.message || 'Could not fetch Invoice list.');
            setStatus('ERROR');
        }
    };

    return { status, data, error, fetchFn };
};

export const useCallHistory = () => {
    const [status, setStatus] = useState('UNINIT');
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [error, setError] = useState('');
    const { region } = useContext(ClientIdBotIdContext);

    const fetchFn: (
        clientId: string,
        // invoiceId: string,
        pageNo: number,
        startDate: Moment,
        endDate: Moment,
        timezone: string
    ) => Promise<void> = async (
        clientId: string,
        // invoiceId: string,
        pageNo: number = 1,
        startDate: Moment,
        endDate: Moment,
        timezone: string
    ) => {
        setStatus('IN_PROGRESS');
        try {
            const invoices = await getCallHistory(
                {
                    filter: {
                        // ClientId: clientId
                        ClientId: clientId,
                        SetupTime: {
                            $gte: moment(startDate)
                                .tz(timezone)
                                .set('hour', 0)
                                .set('minute', 0)
                                .set('second', 0)
                                .utc()
                                .format('YYYYMMDDHHmmss'),
                            $lte: moment(endDate)
                                .tz(timezone)
                                .set('hour', 23)
                                .set('minute', 59)
                                .set('second', 59)
                                .utc()
                                .format('YYYYMMDDHHmmss')
                        }
                        // InvoiceId: invoiceId
                    },
                    pagination: {
                        page: pageNo
                    }
                },
                region
            );
            if (invoices?.code === 200) {
                setData(invoices.data.data);
                setCount(invoices.data.totalCount[0]?.total || 0);
                setStatus('SUCCESS');
            } else {
                setError('Could not fetch Call History list.');
                setStatus('ERROR');
            }
        } catch (e: any) {
            setError(e.message || 'Could not fetch Call History list.');
            setStatus('ERROR');
        }
    };

    return { status, data, error, count, fetchFn };
};

export const useGetInvoice = () => {
    const [status, setStatus] = useState('UNINIT');
    const [data, setData] = useState('');
    const [error, setError] = useState('');
    const { region } = useContext(ClientIdBotIdContext);

    const fetchFn: (
        clientId: string,
        invoiceId: string
    ) => Promise<void> = async (clientId: string, invoiceId: string) => {
        setStatus('IN_PROGRESS');
        try {
            const invoice = await getInvoice(
                {
                    ClientId: clientId,
                    InvoiceId: invoiceId
                },
                region
            );
            if (invoice?.code === 200) {
                setData(invoice.data);
                setStatus('SUCCESS');
            } else {
                setError('Could not fetch Invoice.');
                setStatus('ERROR');
            }
        } catch (e: any) {
            setError(e.message || 'Could not fetch Invoice.');
            setStatus('ERROR');
        }
    };

    return { status, data, error, fetchFn };
};

export const useDownloadInvoice = () => {
    const [status, setStatus] = useState('UNINIT');
    const { region } = useContext(ClientIdBotIdContext);

    const fetchFn: (
        clientId: string,
        invoiceId: string
    ) => Promise<void> = async (clientId: string, invoiceId: string) => {
        setStatus('IN_PROGRESS');
        try {
            const invoice = await getInvoice(
                {
                    ClientId: clientId,
                    InvoiceId: invoiceId
                },
                region
            );
            if (invoice?.code === 200) {
                fetch(invoice.data)
                    .then(response => {
                        if (response.ok) {
                            return response.blob();
                        } else {
                            throw new Error('Failed to download file');
                        }
                    })
                    .then(blob => {
                        // Save the file to local machine
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = `${invoiceId}.pdf`;
                        document.body.appendChild(a);
                        a.click();
                        a.remove();

                        toast.success('Invoice downloaded successfully.');
                    })
                    .catch((error: any) => {
                        toast.error(error || 'Could not download Invoice.');
                    });

                setStatus('SUCCESS');
            } else {
                toast.error('Could not fetch Invoice.');
                setStatus('ERROR');
            }
        } catch (e: any) {
            toast.error(e.message || 'Could not fetch Invoice.');
            setStatus('ERROR');
        }
    };

    return { status, fetchFn };
};
