import { SankeyLinkDatum, SankeyNodeDatum } from '@nivo/sankey';

type propsType = {
    data: any; //SankeyNodeDatum | SankeyLinkDatum
};
const TableForSankeyData = (props: propsType) => {
    const { data } = props;
    // const { id, } = props.data
    // id = node

    // if(!props.data) return null
    // console.log(data)
    return (
        <div style={{ height: 'auto', maxHeight: '25vh' }}>
            {/* <p>{`Click: ${id ? `node - ${id}` : 'link'}`}</p> */}
            <div style={{ backgroundColor: '#D0D0D0', padding: '10px 20px' }}>
                Total Counts: {data.length}
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '5px 20px',
                    border: '1px solid #04AA6D',
                    height: '100%',
                    overflowY: 'auto'
                }}
            >
                {data.map((d: any, idx: any) => (
                    <p
                        id={d.text}
                        style={{ margin: '10px 0px' }}
                    >{`${idx}. ${d.text}`}</p>
                ))}
            </div>
        </div>
    );
};

export default TableForSankeyData;
