import { ReactNode } from 'react';

export enum CallAggregatesTypes {
    '1HOUR' = '1HOUR',
    '15MIN' = '15MIN'
}
export type CallAggregatesT = {
    id: string;
    clientID: string;
    clientName: string;
    date: string;
    totalConversation: number;
    totalDuration: number;
    averageDuration: number;
    medianDuration: number;
    averageSentimentScore: string;
    totalAgentHandover: string;
    totalAgentRequests: string;
    totalAccountWiseCost: number;
    totalCost: number;
    averageCost: number;
    medianCost: number;
    type: CallAggregatesTypes;
};

export type CallAggregatesResT = {
    callAggregates: CallAggregatesT[];
    averageCost: number;
    averageDuration: number;
    totalCoversation: number;
    totalCost: number;
    totalDuration: number;
    averageCallsPerDay: number;
    averageCallsPerWeek: number;
    averageCallsPerMonth: number;
    averageCostPerDay: number;
    averageDurationPerDay: number;
    averageCostPerWeek: number;
    averageDurationPerWeek: number;
    averageCostPerHour: number;
    totalCostPredictedQtrly: number;
    totalCostPredictedAnnually: number;
    //custom
    hour?: number;
    day?: number;
    dateStr?: string;
} | null;

export type ArrowType = 'up' | 'down';
export type OverviewCardT = {
    title?: string;
    value: string | number;
    arrow?: ArrowType;
    customTitle?: ReactNode;
    additionalTitleView?: ReactNode;
    hideTypeIcon?: boolean;
    className?: string;
    children: ReactNode;
    childClassName?: string;
};
export type PureTextContentItemT = {
    key: string;
    value?: string;
    arrow?: ArrowType;
    subItems?: {
        key: string;
        value: string;
        arrow?: ArrowType;
    }[];
};
export type PureTextContentT = {
    items: PureTextContentItemT[];
};
type BarChartDatasetsT = {
    label: string;
    data: number[];
    backgroundColor: string;
    borderColor?: string;
};
export type BarChartDataT = {
    labels: string[];
    datasets: BarChartDatasetsT[];
};
type MultiBarChartDatasetsT = {
    label: string;
    data: number[];
    backgroundColor: string;
    borderColor?: string;
    yAxisID: string;
    type: string
};
export type MultiBarChartDataT = {
    labels: string[];
    datasets: MultiBarChartDatasetsT[];
    isCurrency: boolean
};
export type DoughnutChartDataT = {
    labels?: string[];
    datasets?:
        | {
              data: number[];
              backgroundColor: string[];
          }[]
        | [];
};

export type HourlyCallAggregatesT = {
    averageCost: number;
    averageDuration: number;
    averageSentimentScore: string;
    clientID: string;
    clientName: string;
    date: string;
    id: string;
    medianCost: number;
    medianDuration: number;
    totalAgentHandover: string;
    totalAgentRequests: string;
    totalConversation: number;
    totalCost: number;
    totalAccountWiseCost: number,
    totalDuration: number;
    type: CallAggregatesTypes;
}[];
export type AnalyticsResT = {
    averageCallsPerHour: number;
    averageDuration: number;
    hourlyCallAggregates: HourlyCallAggregatesT;
    todayDurationThisMonth: number;
    todayDurationThisWeek: number;
    todayDurationToday: number;
    totalCoversation: number;
    totalDuration: number;
    totalHandover: number;
    totalResolved: number;
};
