import { Auth } from 'aws-amplify';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Logout() {
    const navigate = useNavigate();
    useEffect(() => {
        const logout = async () => {
            localStorage.clear();
            Auth.signOut()
            navigate('/login');
        };
        logout().catch(console.error);
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%'
            }}
        >
            <p>Logging out...</p>
        </div>
    );
}
