import React, { useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import { NavigateFunction } from 'react-router-dom';
import { ItemsT } from 'src/components/sidebar/types';
import { SIDEBAR_ROLE_MAPPING } from 'src/utils/constants';

const ExpandSideBarItem = (props: ItemsT & { navigate: NavigateFunction } & {uRole: string}) => {
    const { iconPath, title, redirectPath, subItems, navigate, uRole } = props;
    const [open, setOpen] = useState(false);
    const onItemClick = () => {
        if (redirectPath) navigate(redirectPath);
        else if (subItems) setOpen(i => !i);
    };

    return (
        <SidebarGroupWrap
            isOpen={open}
            className={classNames('', { open })}
            onClick={onItemClick}
        >
            <div className="sb-item-wrap">
                <div>
                    <ReactSVG
                        // alt={title}
                        wrapper="span"
                        className="sb-itme-icon"
                        src={iconPath}
                    />
                </div>
                <div />
                <p>{title}</p>
            </div>

            {subItems && (
                <div className="sb-item-child-wrap">
                    {subItems &&
                        subItems.map(i => 
                            SIDEBAR_ROLE_MAPPING[
                                uRole
                            ].includes(i.key) ? 
                            (<div
                                key={`${i.title}`}
                                className="sb-item-child"
                                onClick={() => navigate(i.redirectPath)}
                            >
                                <p>{i.title}</p>
                            </div>
                        ): null
                        )}
                </div>
            )}
        </SidebarGroupWrap>
    );
};

const SidebarGroupWrap = styled.div<{ isOpen: boolean }>`
    box-sizing: border-box;
    height: ${({ isOpen }) => (isOpen ? 'auto' : '60px')};
    background: linear-gradient(180deg, #183f84 0%, #000714 100%);
    box-shadow: 0px 0px 2px #000000;
    border-radius: 10px;
    margin: 10px;
    cursor: pointer;

    &:hover {
        ${({ isOpen }) =>
            isOpen
                ? ''
                : `
            background: linear-gradient(180deg, #051e49 0%, #0f3373 100%);
            box-shadow: inset 0px 1px 5px #000000;
            border-radius: 5px;
        `};
    }

    .sb-item-wrap {
        display: inline-flex;
        align-items: center;
        height: 60px;
        > div:first-child {
            width: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        > div:nth-child(2) {
            width: 1px;
            background: white;
            height: 30px;
            margin-right: 10px;
        }
        .sb-itme-icon {
            mask-position: center;
            path {
                fill: white;
            }
        }
    }

    .sb-item-child-wrap {
        margin-left: 60px;
        cursor: pointer;
        .sb-item-child {
            > p {
                padding-left: 5px;
            }
            > p:before {
                content: url(/images/sidebar/arrow-return.png);
                margin-right: 5px;
            }
        }
    }

    &:not(.open) {
        .sb-item-child-wrap {
            height: 0px;
            overflow: hidden;
        }
    }
    &.open {
        .sb-item-child-wrap {
            height: auto;
            padding-bottom: 10px;
        }
    }
`;

export default ExpandSideBarItem;
