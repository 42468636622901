import { useContext, useState } from 'react';
import { getClientIds } from 'src/api/invoice';
import ClientIdBotIdContext from './clientIdBotIdContext';
import { VERSA_CONFIG } from 'src/constants/config';
import { checkIfTokenExpired } from 'src/utils/utils';
import { SWITCH_ROUTES } from 'src/constants/routes';

export type ClientIdSchema = Array<{
    clientName: string;
    clientId: string;
    accountId: string;
    accountName: string;
    region: string;
    bots: Array<{
        botId: string;
        botPhoneNumber: string;
        description: string;
        channels: string;
    }>;
}>;

export const useFetchAllClientIds = () => {
    const {
        setClientId,
        setBotId,
        setRegion,
        clientId,
        botId,
        region,
        timezone,
        setTimezone,
        accountId,
        setAccountId
    } = useContext(ClientIdBotIdContext);
    const [status, setStatus] = useState<string>();
    const [error, setError] = useState<string>();
    const [data, setData] = useState<ClientIdSchema>();

    if (process.env.REACT_APP_ENV === 'development') {
        const fetchFn = () => {
            if (checkIfTokenExpired()) {
                window.location.href = SWITCH_ROUTES.LOGOUT;
            }

            const promises = [getClientIds(VERSA_CONFIG.DEV_API_DOMAIN)];
            try {
                setStatus('IN_PROGRESS');
                Promise.allSettled(promises)
                    .then((resp: any) => {
                        resp[0]?.value.data.forEach(
                            (d: any) => (d.region = 'AUS')
                        );

                        const combinedDt = [...resp[0]?.value.data];

                        if (
                            !clientId &&
                            !botId &&
                            !region &&
                            !timezone &&
                            !accountId
                        ) {
                            setClientId(combinedDt[0]?.clientId || '');
                            setBotId(combinedDt[0]?.bots[0]?.botId || '');
                            setRegion(combinedDt[0]?.region || '');
                            setTimezone(combinedDt[0]?.timezone || '');
                            setAccountId(combinedDt[0]?.accountId || '');
                        }

                        setData(combinedDt);

                        setStatus('SUCCESS');
                    })
                    .catch((e: any) => {
                        setError(e.message || 'Could not fetch ClientIds.');
                        setStatus('ERROR');
                    });
            } catch (e: any) {
                setError(e.message || 'Could not fetch ClientIds.');
                setStatus('ERROR');
            }
        };

        return {
            status,
            error,
            data,
            fetchFn
        };
    } else {
        const fetchFn = () => {
            if (checkIfTokenExpired()) {
                window.location.href = SWITCH_ROUTES.LOGOUT;
            }
            const promises = [getClientIds(VERSA_CONFIG.US_API_DOMAIN)];
            try {
                setStatus('IN_PROGRESS');
                Promise.allSettled(promises)
                    .then((resp: any) => {
                        resp[0]?.value.data.forEach(
                            (d: any) => (d.region = 'US')
                        );

                        const combinedDt = [
                            ...resp[0]?.value?.data,
                        ];

                        if (
                            !clientId &&
                            !botId &&
                            !region &&
                            !timezone &&
                            !accountId
                        ) {
                            setClientId(combinedDt[0]?.clientId || '');
                            setBotId(combinedDt[0]?.bots[0]?.botId || '');
                            setRegion(combinedDt[0]?.region || '');
                            setTimezone(combinedDt[0]?.timezone || '');
                            setAccountId(combinedDt[0]?.accountId || '');
                        }

                        setData(combinedDt);

                        setStatus('SUCCESS');
                    })
                    .catch((e: any) => {
                        setError('Could not fetch ClientIds.');
                        setStatus('ERROR');
                    });
            } catch (e: any) {
                setError('Could not fetch ClientIds.');
                setStatus('ERROR');
            }
        };

        return {
            status,
            error,
            data,
            fetchFn
        };
    }
};
