import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { COLORS } from 'src/constants/colors';

type DropdownT = {
    // name: string;
    value: string;
    onChange: (v: any) => void;
    children: ReactNode;
    disabled?: boolean;
};
const Dropdown = (props: DropdownT) => {
    const { value, onChange, children, disabled } = props;
    return (
        <DropdownWrap>
            <select
                // name={name}
                value={value}
                onChange={onChange}
                disabled={disabled}
            >
                {children}
            </select>
        </DropdownWrap>
    );
};

export default Dropdown;

const DropdownWrap = styled.div`
    select {
        background: ${COLORS.interface_dark_darkblue};
        border: 1px solid ${COLORS.Interface_dark_glow};
        height: 37px;
        color: white;
        padding: 6px 8px;
        backdrop-filter: blur(2px);
        border-radius: 4px;
    }
`;
