import moment from 'moment-timezone';
import { LOCALE_TZ } from './constants';

interface DateRange {
    fromDate: string;
    toDate: string;
    startDateOfWeek: string;
    endDateOfWeek: string;
    year: number;
}

export function secondsToTimeStr(seconds?: number) {
    if (!seconds) return '';

    if (seconds < 60) return `${seconds}s`;

    const mins = Math.floor(seconds / 60);
    if (mins < 60) return `${mins}m ${Math.round(seconds % 60)}s`;

    const hours = Math.floor(mins / 60);
    return `${hours}h ${mins % 60}m ${Math.round(seconds % 60)}s`;
}

export function round(num: number) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return (Math.round(m) / 100) * Math.sign(num);
}

export function objectToQueryString(object: Object) {
    if (!object) return '';
    let str = '';
    if (typeof object === 'object') {
        str += '?';
        const suffix = Object.keys(object)
            .map(item => `${item}=${object[item as keyof Object]}&`)
            .join('');
        str += suffix;
        str = str.substring(0, str.length - 1);
        return str;
    }
    console.error(`use objectToString but parameter's type isn't object`);
    return str;
}

/* eslint-disable */
export function lowerCaseObjectKeys(obj: object | object[]) {
    let str = JSON.stringify(obj);
    const pattern = /\"([a-z0-9_-]{0,})\"\:/gi;
    str = str.replace(pattern, function () {
        return `"${arguments[0]
            .charAt(1)
            .toLowerCase()}${arguments[0].slice(2)}`;
    });
    return JSON.parse(str);
}

export const getLocaleDateTimeString = (
    utcDateString: string,
    // region: string
    botid: string,
    timezone: string
) => {
    // Convert UTC string to a Date object
    const utcDate = new Date(
        `${utcDateString.slice(0, 4)}-${utcDateString.slice(
            4,
            6
        )}-${utcDateString.slice(6, 8)}T${utcDateString.slice(
            8,
            10
        )}:${utcDateString.slice(10, 12)}:${utcDateString.slice(12, 14)}Z`
    );

    if (botid === 'local') {
        return utcDate.toLocaleString('en-AU', {
            timeZone: 'Australia/Melbourne'
        });
    }
    return utcDate.toLocaleString(LOCALE_TZ[timezone], {
        timeZone: timezone
    });
};

export const getDateComponents = (utcDateString: string) => {
    return {
        year: utcDateString.slice(0, 4),
        month: utcDateString.slice(4, 6),
        date: utcDateString.slice(6, 8)
    };
};

function decodeJWT(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
}

export function checkIfTokenExpired() {
    // Decode JWT token
    if (localStorage.getItem('token')) {
        const decodedToken = decodeJWT(localStorage.getItem('token') || '');

        // Extract expiration time from decoded token
        const expirationTimeInSeconds = decodedToken.exp;

        // Convert expiration time to JavaScript Date object
        const expirationDate = new Date(expirationTimeInSeconds * 1000);

        // Get current time
        const currentTime = new Date();

        // Check if the token is expired
        const isTokenExpired = expirationDate <= currentTime;
        return isTokenExpired;
    }
    return true;
}

export function getPreviousTenYearsWithCurrentReverse(): number[] {
    const currentYear = new Date().getFullYear();
    const previousTenYears: number[] = [];

    for (let i = currentYear - 9; i <= currentYear; i++) {
        previousTenYears.push(i);
    }

    return previousTenYears.reverse();
}

export function generateWeeklyDateRange(timezone: string): DateRange {
    const now = moment().tz(timezone);
    const startOfWeek = now.clone().startOf('week');
    const endOfWeek = now.clone().endOf('week');

    const startDateOfWeek = startOfWeek.format('D-MMM');
    const endDateOfWeek = endOfWeek.format('D-MMM');

    const startOfDay = now.clone().startOf('day');
    const endOfDay = now.clone().endOf('day');

    const fromDate = startOfDay.format('YYYY-MM-DD') + ' 00:00:00';
    const toDate = endOfDay.format('YYYY-MM-DD') + ' 23:59:59';
    const year = now.year();

    const fromDateUTC = moment.utc(fromDate).format('YYYY-MM-DD HH:mm:ss');
    const toDateUTC = moment.utc(toDate).format('YYYY-MM-DD HH:mm:ss');

    return {
        fromDate: fromDateUTC,
        toDate: toDateUTC,
        startDateOfWeek,
        endDateOfWeek,
        year
    };
}

export function generateWeeklyDateRangePreviousWeek(
    timezone: string
): DateRange {
    const now = moment().tz(timezone);
    const startOfPreviousWeek = now.clone().subtract(1, 'week').startOf('week');
    const endOfPreviousWeek = now.clone().subtract(1, 'week').endOf('week');

    const startDateOfWeek = startOfPreviousWeek.format('D-MMM');
    const endDateOfWeek = endOfPreviousWeek.format('D-MMM');

    const startOfDay = startOfPreviousWeek.startOf('day');
    const endOfDay = endOfPreviousWeek.endOf('day');

    const fromDate = startOfDay.format('YYYY-MM-DD') + ' 00:00:00';
    const toDate = endOfDay.format('YYYY-MM-DD') + ' 23:59:59';
    const year = startOfPreviousWeek.year();

    const fromDateUTC = moment.utc(fromDate).format('YYYY-MM-DD HH:mm:ss');
    const toDateUTC = moment.utc(toDate).format('YYYY-MM-DD HH:mm:ss');

    return {
        fromDate: fromDateUTC,
        toDate: toDateUTC,
        startDateOfWeek,
        endDateOfWeek,
        year
    };
}

export function generateYears() {
    const currentYear = new Date().getFullYear();
    const startYear = 2024;
    const years = [];

    for (let year = startYear; year <= currentYear; year++) {
        years.push(year);
    }

    return years;
}

export function getCurrentMonth() {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    const currentMonthIndex = new Date().getMonth();
    const currentMonth = months[currentMonthIndex];

    return currentMonth;
}

export function parseConversation(data: Array<any>) {
    // Check if any LOGS entry contains inferenceResponse in the parsed Data.Log
    const logsWithInferenceResponse = data.some(
        entry =>
            entry.Type === 'LOGS' &&
            'inferenceResponse' in JSON.parse(entry.Data.Log)
    );

    // Filter entries
    const result = data.filter(entry => {
        if (
            ['NODE', 'FLOW', 'TAGS', 'TALENTS', 'AGENTHANDOVER'].includes(
                entry.Type
            )
        ) {
            return false;
        }
        if (logsWithInferenceResponse && entry.Type === 'INTENT') {
            return false;
        }
        if (entry.Type === 'LOGS') {
            entry.Data.Log = JSON.parse(entry.Data.Log);
        }
        return true;
    });

    return result;
}
