import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { COLORS } from 'src/constants/colors';
import classNames from 'classnames';

export type PagerProps = {
    pageCount: number;
    pageIndex: number;
    turnPage: (n: number) => void;
};
function getPages(index: number, count: number) {
    let start;
    let end;
    const result = [];
    const left = index - 2 >= 0;
    const right = index + 2 <= count - 1;
    if (left && right) {
        start = index - 2;
        end = index + 2;
    } else if (right) {
        start = 0;
        end = count >= 5 ? 4 : count - 1;
    } else if (left) {
        end = count - 1;
        start = end - 4 >= 0 ? end - 4 : 0;
    } else {
        start = 0;
        end = count - 1;
    }

    for (let i = start; i <= end; i = i + 1) {
        result.push(i);
    }
    return result;
}
const Pager = (props: PagerProps) => {
    const { pageCount: propPageCount, pageIndex, turnPage } = props;
    const [pageCount, setPageCount] = useState(propPageCount || 0);
    useEffect(() => {
        setPageCount(propPageCount);
    }, [propPageCount]);

    const goPage = (num: number) => {
        turnPage(num);
    };
    const pages = getPages(pageIndex, pageCount);
    const displayFirstPageNum = pageIndex > 2 && !pages.includes(0);
    const displayLastPageNum =
        pageCount - (pageIndex + 1) > 2 && !pages.includes(pageCount - 1);

    if (pageCount < 1) return null;
    return (
        <PagerWrap1 className="pager-wrap">
            <div
                onClick={() => {
                    if (pageIndex > 0) goPage(pageIndex - 1);
                }}
                className={classNames('pg-button rotate-180 mr-40', {
                    'pg-button-disable': pageIndex === 0
                })}
            >
                <ReactSVG
                    wrapper="span"
                    src={'/images/dashboard/arrow-more-blue.svg'}
                />
            </div>

            <div className="pg-fixed-width">
                {displayFirstPageNum && (
                    <>
                        <span onClick={() => goPage(0)} className="page-num">
                            1
                        </span>
                        <span className="pg-no-use">
                            <span>...</span>
                        </span>
                    </>
                )}

                {pages.map(num => (
                    <span
                        key={num}
                        className={
                            num === pageIndex ? 'page-num-on' : 'page-num'
                        }
                        onClick={() => goPage(num)}
                    >
                        {num + 1}
                    </span>
                ))}

                {displayLastPageNum && (
                    <>
                        <span className="pg-no-use">
                            <span>...</span>
                        </span>
                        <span
                            onClick={() => goPage(pageCount - 1)}
                            className="page-num"
                        >
                            {pageCount}
                        </span>
                    </>
                )}
            </div>
            <span
                onClick={() => {
                    if (pageIndex < pageCount - 1) goPage(pageIndex + 1);
                }}
                className={classNames('pg-button ml-40', {
                    'pg-button-disable': pageIndex === pageCount - 1
                })}
            >
                {' '}
                <ReactSVG
                    wrapper="span"
                    src={'/images/dashboard/arrow-more-blue.svg'}
                />
            </span>
        </PagerWrap1>
    );
};

export default Pager;
const PagerWrap1 = styled.div`
    display: flex;
    justify-content: center;
    height: 75px;
    box-sizing: border-box;
    border-top: 1px solid ${COLORS.Interface_dark_glow};
    margin: 0px 20px;
    align-items: center;

    .pg-fixed-width {
        width: 280px;
        overflow: hidden;
        display: flex;
        gap: 10px;
        justify-content: center;
        font-size: 16px;

        > span {
            cursor: pointer;
            border: none;
            padding: 5px;
            color: ;
        }
        .page-num-on {
            color: ${COLORS.primary_blue};
        }
        .pg-no-use {
            cursor: default;
        }
    }

    .pg-button {
        cursor: pointer;
        box-sizing: border-box;
        padding: 10px 14px;
        border-radius: 5px;
        background: ${COLORS.interface_button_default};
        box-shadow: ${COLORS.button_drop_shadow};
        svg {
            rect {
                fill: white;
            }
        }
    }
    .pg-button-disable {
        cursor: no-drop;
        svg {
            rect {
                fill: ${COLORS.Interface_dark_glow};
            }
        }
    }
    .rotate-180 {
        transform: rotate(180deg);
    }

    .mr-40 {
        margin-right: 40px;
    }

    .ml-40 {
        margin-left: 40px;
    }
`;
