import React, { useState } from 'react';
import { GlobalContextProviderProps } from 'src/hooks/useSidebarMenu';

const ClientIdBotIdContext = React.createContext({
    clientId: '',
    setClientId: (e: any) => {},
    botId: '',
    setBotId: (e: any) => {},
    region: '',
    setRegion: (e: any) => {},
    timezone: '',
    setTimezone: (e: any) => {},
    accountId: '',
    setAccountId: (e: any) => {}
});

export const ClientIdBotIdProvider = ({
    children
}: GlobalContextProviderProps) => {
    const [clientId, setClientId] = useState<string>('');
    const [botId, setBotId] = useState<string>('');
    const [region, setRegion] = useState<string>('');
    const [timezone, setTimezone] = useState<string>('');
    const [accountId, setAccountId] = useState<string>('');

    const value = {
        clientId,
        setClientId,
        botId,
        setBotId,
        region,
        setRegion,
        timezone,
        setTimezone,
        accountId,
        setAccountId
    };

    return (
        <ClientIdBotIdContext.Provider value={value}>
            {children}
        </ClientIdBotIdContext.Provider>
    );
};

export default ClientIdBotIdContext;
