import { useEffect, useState } from 'react';
import Pagination from '../shared/pagination/Pagination';
import { COLORS } from 'src/constants/colors';
import styled from 'styled-components';
import {
    CallLogT, CallLogContentBig
} from 'src/components/dashboard/CalllogsView';

type CallLogPaginationProps = {
    items: CallLogT[];
    onItemClick: (d: CallLogT) => void;
};
const PAGE_SIZE = 13;
const CallLogPagination = (props: CallLogPaginationProps) => {
    const { items: allItems, onItemClick } = props;
    const [pageIndex, setPageIndex] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [paginationList, setPaginationList] = useState<CallLogT[] | []>([]);

    useEffect(() => {
        setPageIndex(0);
        setPageCount(Math.ceil(allItems.length / PAGE_SIZE));
        setPaginationList(allItems.slice(0, PAGE_SIZE));
    }, [allItems]);
    useEffect(() => {
        if (Array.isArray(allItems)) {
            const paginationList = allItems.slice(
                pageIndex * PAGE_SIZE,
                pageIndex * PAGE_SIZE + PAGE_SIZE
            );
            setPaginationList(paginationList);
        }
    }, [pageIndex]);

    return (
        <Pagination
            pageIndex={pageIndex}
            pageCount={pageCount}
            turnPage={setPageIndex}
        >
            <CallLogWrap>
                <CallLogContentBig
                    items={paginationList}
                    onItemClick={onItemClick}
                />
            </CallLogWrap>
        </Pagination>
    );
};

export default CallLogPagination;

export const CallLogWrap = styled.div<{ clickable?: boolean }>`
    display: flex;
    flex-direction: column;
    flex: 1;

    > div {
        box-sizing: border-box;
        padding: 15px 0px;
        border-bottom: 1px solid ${COLORS.interface_dark_darkblue};
        cursor: pointer;
    }
`;
