import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
// import { Auth } from 'aws-amplify';
import { OverviewCard } from 'src/components/dashboard/OverviewCard';
import {
    CallLogContent, CallLogT,
    parseCallLog
} from 'src/components/dashboard/CalllogsView';
import { COLORS } from 'src/constants/colors';
import {
    Breadcrumb,
    CallLogsTitle,
    ScrollArea,
    WithTitleAndViewing
} from 'src/components/dashboard/Views';
import DoughnutChart from 'src/components/dashboard/DoughnutChart';
import MultiBarChart from 'src/components/dashboard/MultiBarChart';
import {
    getAnalytics,
    getCallAggregates,
    getConversations
} from 'src/api/dashboard';
import {
    AnalyticsResT,
    CallAggregatesResT,
    MultiBarChartDataT
} from 'src/types/dashboardT';
import BillingOverview from 'src/components/dashboard/BillingOverview';
import LoadingView from 'src/components/shared/LoadingView';
import { SWITCH_ROUTES } from 'src/constants/routes';
import { getLineChartDataFromCallAggregates } from 'src/routes/dashboard/ReportAndAnalytics';
import { BUTTONS } from './dashboard/BillingDashboard';
import CoversactionDetailModal from 'src/components/dashboard/CoversactionDetailModal';
import ClientIdBotIdContext from 'src/components/sidebar/clientIdBotIdContext';
import { getLocaleDateTimeString } from 'src/utils/utils';

const BREADCRUMB_ITEMS = [
    { text: 'Home', redirectUrl: '', svgSrc: '/images/sidebar/home.svg' }
];

type hourlyCallAggItem = {
    date: string;
};

const BOHome = () => {
    const navigate = useNavigate();
    const { botId, region, timezone } = useContext(ClientIdBotIdContext);
    const [callAggregatesRes, setCallAggregatesRes] =
        useState<CallAggregatesResT>(null);
    const [coversationsData, setCoversationsData] = useState<CallLogT[] | []>(
        []
    );
    const [analyticsData, setAnalyticsData] = useState<AnalyticsResT | null>(
        null
    );
    const [hourlyLineChartData, setHourlyLineChartData] =
        useState<MultiBarChartDataT | null>(null);
    const [selectedCallLog, setSelectedCallLog] = useState<CallLogT | null>(
        null
    );

    const [fetchBillingStatus, setFetchBillingStatus] = useState('UNINIT');
    const [fetchRepOverviewStatus, setFetchRepOverviewStatus] =
        useState('UNINIT');

    const fetchBillingOverviewData = async () => {
        // const user = await Auth.currentAuthenticatedUser();
        setFetchBillingStatus('IN_PROGRESS');
        const result = await getCallAggregates(
            {
                botid: botId,
                fromdate: moment().startOf('month'),
                todate: moment()
            },
            region
        );
        setFetchBillingStatus('SUCCESS');
        if (result) {
            const { code, data } = result;
            if (code === 200) {
                data.callAggregates.forEach((c: hourlyCallAggItem) => {
                    c.date = getLocaleDateTimeString(c.date, botId, timezone);
                });

                setCallAggregatesRes(data as CallAggregatesResT);
            }
        }
    };
    const fetchReportOverviewData = async () => {
        // const user = await Auth.currentAuthenticatedUser();
        setFetchRepOverviewStatus('IN_PROGRESS');
        const payload = {
            botid: botId,
            fromdate: moment().startOf('month'),
            todate: moment()
        };
        const conversationsRes = await getConversations(payload, region);
        setFetchRepOverviewStatus('SUCCESS');
        if (conversationsRes.code === 200 && conversationsRes.data)
            setCoversationsData(
                conversationsRes.data.map((d: any) =>
                    parseCallLog(d, botId, timezone)
                )
            );

        const analyticsRes = await getAnalytics(payload, region);
        if (analyticsRes.code === 200) {
            // if hourly call Aggregates, update the response with local TZ.
            analyticsRes.data.hourlyCallAggregates.forEach(
                (d: hourlyCallAggItem) => {
                    d.date = getLocaleDateTimeString(d.date, botId, timezone);
                }
            );

            setAnalyticsData(analyticsRes.data);
        }
    };

    useEffect(() => {
        if (botId && region) {
            fetchBillingOverviewData();
            fetchReportOverviewData();
        }
    }, [botId, region]);

    useEffect(() => {
        setHourlyLineChartData(
            getLineChartDataFromCallAggregates(
                timezone,
                analyticsData?.hourlyCallAggregates
            )
        );
    }, [analyticsData]);

    const renderRAOverview = () => {
        const doughnutD = {
            labels: ['Handover', 'Resolved'],
            datasets: analyticsData
                ? [
                      {
                          data: [
                              analyticsData?.totalHandover,
                              analyticsData?.totalResolved
                          ],
                          backgroundColor: [
                              COLORS.interface_dark_accent_01,
                              COLORS.interface_dark_accent_02
                          ]
                      }
                  ]
                : []
        };
        return (
            <WithTitleAndViewing
                title="Reporting & Analytics Overview"
                buttons={BUTTONS}
                onSeeAllClick={() => navigate(SWITCH_ROUTES.DASHBOARD_RA)}
            >
                <div className="row overview-cards-bg min-h-480">
                    <OverviewCard
                        title="TOTAL CALLS"
                        value={analyticsData?.totalCoversation || ''}
                        arrow="down"
                        className="w-20"
                        childClassName="h-100"
                        additionalTitleView={
                            <div className="overview-card-title">
                                THIS MONTH
                            </div>
                        }
                    >
                        <DoughnutChart {...doughnutD} />
                    </OverviewCard>
                    <div className="divide-line" />
                    <OverviewCard
                        title="HOURLY CALLS"
                        value={''}
                        className="w-30"
                        childClassName="h-100"
                    >
                        <MultiBarChart
                            data={hourlyLineChartData || undefined}
                        />
                    </OverviewCard>
                    <div className="divide-line" />
                    <OverviewCard
                        className="w-50"
                        childClassName="column-10"
                        title=""
                        value=""
                        customTitle={<CallLogsTitle>Call Log</CallLogsTitle>}
                    >
                        <CallLogContent
                            items={coversationsData}
                            onItemClick={setSelectedCallLog}
                        />
                    </OverviewCard>
                </div>
            </WithTitleAndViewing>
        );
    };

    return (
        <BOHomeWrap>
            <Breadcrumb items={BREADCRUMB_ITEMS} />
            {fetchBillingStatus === 'IN_PROGRESS' ||
            fetchRepOverviewStatus === 'IN_PROGRESS' ? (
                <LoadingView />
            ) : fetchBillingStatus === 'SUCCESS' &&
              fetchRepOverviewStatus === 'SUCCESS' ? (
                <ScrollArea>
                    <BillingOverview
                        sectionTitle="Billing Overview"
                        buttons={BUTTONS}
                        callAggregatesRes={callAggregatesRes}
                    />
                    {renderRAOverview()}
                </ScrollArea>
            ) : null}
            {selectedCallLog ? (
                <CoversactionDetailModal
                    item={selectedCallLog}
                    onClose={() => setSelectedCallLog(null)}
                    showNavBtns={false}
                    onNext={() => {}}
                    onPrev={() => {}}
                />
            ) : null}
        </BOHomeWrap>
    );
};
export default BOHome;

const BOHomeWrap = styled.div`
    box-sizing: border-box;
    overflow: hidden;

    .row {
        display: flex;
        width: 100%;
    }

    .w-25 {
        > div:nth-child(odd) {
            width: 25%;
        }
    }
    .w-20 {
        width: 20% !important;
    }
    .w-30 {
        width: 30% !important;
    }
    .w-50 {
        width: 50% !important;
    }
    .overview-cards-bg {
        background: ${COLORS.interface_dark_gradient_01};

        .divide-line {
            height: 100%;
            width: 2px;
            background: ${COLORS.interface_dark_fade};
        }
    }
    .column-10 {
        display: flex;
        flex-direction: column;
        > div {
            border-bottom: 1px solid ${COLORS.interface_dark_darkblue};
            padding: 10px 0px;
        }
        > div:last-child {
            border-bottom: none;
        }
    }
`;
