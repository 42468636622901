import { ResponsiveSankey, SankeyMouseHandler } from '@nivo/sankey';
// import TEST_DATA from './test-data.json';
// import TEST_DATA2 from './test-data2.json';

// import { generateSankeyData, randColor } from '@nivo/generators'
// const sankeyData = generateSankeyData({ nodeCount: 11, maxIterations: 2 })
// randColor()
// https://nivo.rocks/storybook/?path=/docs/sankey--docs
type propsType = {
    onClick: SankeyMouseHandler;
    data: any;
};
const Sankey = (props: propsType) => {
    const { data, onClick } = props;
    // console.log(TEST_DATA)
    return (
        <ResponsiveSankey
            onClick={onClick}
            data={data}
            margin={{ top: 40, right: 160, bottom: 40, left: 50 }}
            align="justify"
            colors={{ scheme: 'category10' }}
            nodeOpacity={1}
            linkOpacity={1}
            nodeHoverOthersOpacity={0.35}
            nodeThickness={18}
            nodeSpacing={24}
            nodeBorderWidth={0}
            nodeBorderColor={{
                from: 'color',
                modifiers: [['darker', 0.8]]
            }}
            nodeBorderRadius={3}
            linkHoverOthersOpacity={0.1}
            linkContract={3}
            enableLinkGradient={true}
            labelPosition="outside"
            labelOrientation="vertical"
            labelPadding={16}
            labelTextColor={{
                from: 'color',
                modifiers: [['darker', 1]]
            }}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    translateX: 130,
                    itemWidth: 100,
                    itemHeight: 14,
                    itemDirection: 'right-to-left',
                    itemsSpacing: 2,
                    itemTextColor: '#999',
                    symbolSize: 14,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000'
                            }
                        }
                    ]
                }
            ]}
        />
    );
};

export default Sankey;
