import BaseModal from 'src/components/shared/modals/BaseModal';
import { ReactSVG } from 'react-svg';
import { Button } from 'src/components/shared/Button';
import { BodyContainer } from './EditTestCase';
import { useDeleteTestCaseHook } from '../hooks';
import ClientIdBotIdContext from 'src/components/sidebar/clientIdBotIdContext';
import { useContext } from 'react';

export default function DeleteTestCase({
    show,
    onClose,
    testCaseToDelete,
    refresh
}: {
    show: boolean;
    onClose: Function;
    testCaseToDelete: any;
    refresh: any;
}): JSX.Element {
    const { region } = useContext(ClientIdBotIdContext);

    const { status, fn } = useDeleteTestCaseHook();

    return (
        <BaseModal show={show}>
            <div
                style={{
                    margin: '0 auto',
                    maxHeight: '90vh',
                    overflowY: 'scroll'
                }}
            >
                <BodyContainer>
                    <div>
                        {'Delete TestCase'}
                        <div className="divide-line" />
                        <ReactSVG
                            wrapper="span"
                            src={'/images/shared/close.svg'}
                            className="close-btn"
                            onClick={() => {
                                onClose();
                            }}
                        />
                        <div className="form-row-item flex-50">
                            <p>
                                Are you sure you want to delete this testcase?
                            </p>
                        </div>
                        <div className="form-row-item flex-50">
                            {status === 'IN_PROGRESS' ? (
                                <p>Deleting...</p>
                            ) : (
                                <Button
                                    text="Delete"
                                    onClick={() => {
                                        fn(
                                            {
                                                testCaseId: testCaseToDelete
                                            },
                                            region,
                                            () => {
                                                refresh();
                                            }
                                        );
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </BodyContainer>
            </div>
        </BaseModal>
    );
}
