import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { COLORS } from 'src/constants/colors';
import { DoughnutChartDataT } from 'src/types/dashboardT';

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
    type: 'doughnut',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'bottom' as const,
            labels: {
                color: 'white'
            }
        },
        tooltip: {
            backgroundColor: COLORS.white_9,
            titleColor: 'black',
            bodyColor: 'black'
        }
    },
    cutout: '90%'
};
const FAKE_DATA = {
    labels: ['Resolved', 'Handover', 'Hang-up'],
    datasets: [
        {
            // label: '# of Votes',
            data: [12, 19, 5],
            backgroundColor: [
                COLORS.interface_dark_accent_01,
                COLORS.interface_dark_accent_02,
                COLORS.interface_dark_accent_03
            ],
            // borderColor: [
            //   'rgba(255, 99, 132, 1)',
            //   'rgba(54, 162, 235, 1)',
            //   'rgba(255, 206, 86, 1)',
            // ],
            borderWidth: 0,
            hoverOffset: 4,
            percentageInnerCutout: 40,
            cutoutPercentage: 80
        }
    ]
};

const INIT_DATASET_SETTING = {
    borderWidth: 0,
    hoverOffset: 4,
    percentageInnerCutout: 40,
    cutoutPercentage: 80
};
const DoughnutChart = (props: DoughnutChartDataT) => {
    const { labels, datasets } = props;
    let display = FAKE_DATA;

    if (labels && datasets && datasets.length > 0) {
        display = {
            labels,
            datasets: datasets.map(d => {
                return { ...INIT_DATASET_SETTING, ...d };
            })
        };
    }
    return <Doughnut options={options} data={display} />;
};

export default DoughnutChart;
