let AUS_API_DOMAIN = '';
let US_API_DOMAIN = '';
let DEV_API_DOMAIN = '';
let REGION = '';
let USER_POOL_ID = '';
let USER_POOL_APP_CLIENT_ID = '';

switch (process.env.REACT_APP_ENV) {
    case 'prod':
    default:
        AUS_API_DOMAIN = 'https://us-dashboard.versaconnects.io';
        US_API_DOMAIN = 'https://us-dashboard.versaconnects.io';
        REGION = 'us-east-1';
        USER_POOL_ID = 'us-east-1_EpgV4BnZu';
        USER_POOL_APP_CLIENT_ID = '1l37pdg6k92otrsvdeftujo5h0';
        break;
    case 'development':
        DEV_API_DOMAIN = 'https://devdashboard.versaconnects.link';
        REGION = 'ap-southeast-2';
        USER_POOL_ID = 'ap-southeast-2_sJkvDbsge';
        USER_POOL_APP_CLIENT_ID = '5e2fjec24gfoj24mfga2m9amci';
        break;
}

const VERSA_CONFIG = {
    AUS_API_DOMAIN: AUS_API_DOMAIN,
    US_API_DOMAIN: US_API_DOMAIN,
    DEV_API_DOMAIN: DEV_API_DOMAIN,
    REGION: REGION,
    USER_POOL_ID: USER_POOL_ID,
    USER_POOL_APP_CLIENT_ID: USER_POOL_APP_CLIENT_ID
};

export { REGION, USER_POOL_ID, USER_POOL_APP_CLIENT_ID, VERSA_CONFIG };
