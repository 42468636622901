import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import BaseModal from 'src/components/shared/modals/BaseModal';
import { COLORS } from 'src/constants/colors';
import { CallLogT, CallLogContent } from './CalllogsView';

import { JsonView, allExpanded, darkStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import { getCallLogDetail } from 'src/api/dashboard';
import ClientIdBotIdContext from '../sidebar/clientIdBotIdContext';
import { Auth } from 'aws-amplify';
import { parseConversation } from 'src/utils/utils';
import { Button } from '../shared/Button';
// import { USER_CONVERSATION_MOCK } from 'src/__mocks__';

type CoversactionDetailModalProps = {
    item: null | CallLogT;
    onClose: () => void;
    showNavBtns: boolean;
    onNext: () => void;
    onPrev: () => void;
};
type ConversationItemProps = {
    idx: string;
    fromBot: boolean;
    text: string;
    events: string;
    role: string;
};

const ConversationItem = (props: ConversationItemProps) => {
    const { idx, fromBot, text, events, role } = props;
    const [expanded, setExpanded] = useState<boolean>();
    return (
        <>
            <CoversationItemWrap key={`conversation-${idx}`} fromBot={fromBot}>
                <div>
                    <div>
                        <p>{fromBot ? 'AGENT' : 'USER'}</p>
                    </div>
                    <p>{text}</p>
                    {!fromBot && role !== 'CLIENT' ? (
                        <div>
                            <ReactSVG
                                wrapper="span"
                                className="more-svg"
                                src={'/images/dashboard/arrow-more-blue.svg'}
                                onClick={() => {
                                    setExpanded(!expanded);
                                }}
                                afterInjection={svg => {
                                    if (expanded) {
                                        svg.classList.add('rotate-90');
                                    }
                                }}
                            />
                        </div>
                    ) : null}
                </div>
                {expanded && !fromBot ? (
                    <div className="expanded-conversation">
                        <JsonView
                            data={parseConversation(JSON.parse(events))}
                            shouldExpandNode={allExpanded}
                            style={darkStyles}
                        />
                    </div>
                ) : null}
            </CoversationItemWrap>
        </>
    );
};
const CoversactionDetailModal = ({
    item,
    onClose,
    showNavBtns,
    onNext,
    onPrev
}: CoversactionDetailModalProps) => {
    const { region, botId } = useContext(ClientIdBotIdContext);

    const [status, setStatus] = useState('UNINIT');
    const [data, setData] = useState([]);

    const [authUser, setAuthUser] = useState<{
        attributes: {
            'custom:Role': string;
        };
    }>();
    const [authLoadingStatus, setAuthLoadingStatus] = useState('UNINIT');
    const getAuthuser = async () => {
        try {
            setAuthLoadingStatus('IN_PROGRESS');
            const user = await Auth.currentAuthenticatedUser();
            setAuthUser(user);
            setAuthLoadingStatus('SUCCESS');
        } catch (e) {
            setAuthLoadingStatus('ERROR');
        }
    };
    useEffect(() => {
        getAuthuser();
    }, []);

    useEffect(() => {
        setStatus('IN_PROGRESS');
        getCallLogDetail(botId, item?.conversationId, region)
            .then(({ data }) => {
                if (data.details) {
                    setData(data.details);
                    setStatus('SUCCESS');
                }
            })
            .catch(e => {
                setStatus('ERROR');
            });
    }, [item?.conversationId]);

    let body = <></>;

    if (status === 'IN_PROGRESS' && authLoadingStatus === 'IN_PROGRESS') {
        body = <p>Loading...</p>;
    } else if (status === 'SUCCESS' && authLoadingStatus === 'SUCCESS') {
        body = (
            <div className="conversations-wrap">
                {data?.map((d: ConversationItemProps, i) => (
                    <ConversationItem
                        {...d}
                        idx={`${item?.conversationId}-${i}`}
                        role={authUser?.attributes['custom:Role'] || ''}
                    />
                ))}
            </div>
        );
    } else if (status === 'ERROR' && authLoadingStatus === 'ERROR') {
        body = <p>Could not load conversation details.</p>;
    }

    return (
        <BaseModal show={item !== null}>
            <CoversactionDetailWrap>
                <div>
                    Conversation - {item?.conversationId}
                    <div className="divide-line" />
                    {showNavBtns ? (
                        <div className="nav-btns-container">
                            <Button text="Previous" onClick={onPrev} />
                            <Button text="Next" onClick={onNext} />
                        </div>
                    ) : null}
                    {item && <CallLogContent items={[item]} hideMoreImg />}
                    <ReactSVG
                        wrapper="span"
                        src={'/images/shared/close.svg'}
                        className="close-btn"
                        onClick={onClose}
                    />
                </div>
                <div>{body}</div>
            </CoversactionDetailWrap>
        </BaseModal>
    );
};

export default CoversactionDetailModal;

const CoversactionDetailWrap = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-width: 960px;
    width: 60vw;
    background: ${COLORS.neutrals_black_2};
    border: 1px solid ${COLORS.Interface_dark_glow};

    > div {
        padding: 20px;
    }
    > div:first-child {
        position: relative;
        background: ${COLORS.neutrals_black_2_noa};

        .close-btn {
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
        }
    }
    > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        background: ${COLORS.interface_dark_gradient_01};
        padding: 20px;
        .conversations-wrap {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-height: 60vh;
            overflow-y: scroll;
        }
    }

    .divide-line {
        background: ${({ theme }) => theme.interface_dark_glow};
        width: 100%;
        height: 1px;
        margin: 20px 0px;
    }

    .nav-btns-container {
        display: flex;
        justify-content: center;
        gap: 15px;
        margin-bottom: 20px;
    }
`;

const CoversationItemWrap = styled.div<{ fromBot?: boolean }>`
    > div:first-child {
        display: flex;

        p {
            margin: 0px;
            width: 100%;
        }
        > div:first-child {
            margin-right: 28px;
            p {
                border: 1px solid ${COLORS.primary_blue};
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
                border-radius: 5px;
                padding: 4px 0px;
                width: 80px;
                text-align: center;
            }
        }
        .rotate-90 {
            transform: rotate(90deg);
        }
        svg {
            cursor: pointer;
        }
    }
    padding: 16px 20px;
    color: white;
    background: ${COLORS.interface_dark_darkblue};
    ${({ fromBot }) => {
        if (fromBot)
            return `
            color: ${COLORS.primary_blue};
            background: ${COLORS.interface_dark_navbg};
        `;
    }}
    .expanded-conversation {
        display: flex;
        gap: 20px;
        padding: 10px;
        padding-left: 0px;
    }
`;
