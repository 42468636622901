import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import moment, { Moment } from 'moment';
import { useNavigate } from 'react-router-dom';
import { COLORS } from 'src/constants/colors';
import { DateRangePicker } from 'src/components/shared/DatePicker';
import { CallIcon } from 'src/components/dashboard/OverviewCard';
import { Button, ButtonT } from 'src/components/shared/Button';

type WithTitleAndViewingT = {
    title: string;
    buttons: ButtonT[];
    children: ReactNode;
    hideTitle?: boolean;
    onSeeAllClick?: () => void;
    selectAll?: boolean;
};
type BreadcrumbT = {
    items: {
        text: string;
        redirectUrl?: string;
        svgSrc?: string;
    }[];
};
type WithDateRangeTitleT = {
    children: ReactNode;
    startDate: Moment;
    endDate: Moment;
    childClassname?: string;
    customTitle?: ReactNode;
    customFilterViews?: ReactNode;
    hideIcon?: boolean;
    setDates: ({
        startDate,
        endDate
    }: {
        startDate: Moment;
        endDate: Moment;
    }) => void;
    hideDateRange?: boolean;
    noFilter?: boolean;
    // ref?: React.RefObject<HTMLDivElement>;
};

export const WithTitleAndViewing = (props: WithTitleAndViewingT) => {
    const { title, buttons, hideTitle, onSeeAllClick, children, selectAll } =
        props;
    return (
        <WithTitleAndViewingWrap>
            <div>
                <div className="withtitle-and-viewing-title">
                    {!hideTitle && (
                        <>
                            {title}
                            <span onClick={onSeeAllClick}>
                                {`See all`}
                                <ReactSVG
                                    wrapper="span"
                                    className="more-svg"
                                    src={
                                        '/images/dashboard/arrow-more-blue.svg'
                                    }
                                />
                            </span>
                        </>
                    )}
                </div>

                {buttons && (
                    <div className="withtitle-and-viewing-buttons">
                        Viewing:
                        {buttons.map(b => (
                            <Button
                                key={b.text}
                                {...b}
                                selected={selectAll ? true : b.text === 'Voice'}
                            />
                        ))}
                    </div>
                )}
            </div>

            {children}
        </WithTitleAndViewingWrap>
    );
};
export const Breadcrumb = (props: BreadcrumbT) => {
    const { items } = props;
    const navigate = useNavigate();
    const handleClick = (url?: string) => () => {
        if (!url) return;
        navigate(url);
    };
    return (
        <BreadcrumbWrap>
            <ol>
                {items.map((item, index) => (
                    <li
                        key={`breadcrumb-${index}`}
                        data-step={index}
                        onClick={handleClick(item.redirectUrl)}
                    >
                        {item.svgSrc && (
                            <ReactSVG wrapper="span" src={item.svgSrc} />
                        )}
                        {item.text}
                    </li>
                ))}
            </ol>
        </BreadcrumbWrap>
    );
};
export const WithDateRangeTitle = (props: WithDateRangeTitleT) => {
    const {
        startDate,
        endDate,
        setDates,
        childClassname,
        children,
        customTitle,
        hideIcon,
        customFilterViews,
        hideDateRange,
        noFilter
    } = props;
    const onApply = (result: any) => {
        const { startDate, endDate } = result;
        if (!startDate || !endDate) return;

        setDates({
            startDate: moment(startDate),
            endDate: moment(endDate)
        });
    };
    const onChange = (dates: any) => {
        const [start, end] = dates;
        onApply({ startDate: start, endDate: end });
    };
    return (
        <WithDateRangeTitleWrwap>
            {customTitle}

            {!noFilter ? (
                <div className="with-date-range-title-wrap">
                    <p>DISPLAY</p>

                    {customFilterViews}
                    {!hideDateRange ? (
                        <DateRangePicker
                            startDate={startDate.toDate()}
                            endDate={endDate.toDate()}
                            // minDate={MIN_180_DAYS_TABS.includes(currentTab) ? MIN_180_DAYS.toDate() : MIN_DATE}
                            maxDate={new Date()}
                            onChange={onChange}
                            maxDateSetting={{ amount: 12, unit: 'months' }}
                        />
                    ) : null}

                    {!hideIcon && <CallIcon classname="call-icon-center" />}
                </div>
            ) : null}

            <div className={`with-date-range-title-child ${childClassname}`}>
                {children}
            </div>
        </WithDateRangeTitleWrwap>
    );
};

export const CallLogsTitle = styled.p`
    font-size: 18px;
    width: 100%;
    padding-left: 10px;
    margin: 0px;
    > span {
        color: ${COLORS.primary_blue};
        cursor: pointer;
        padding-left: 10px;
    }
`;

const WithTitleAndViewingWrap = styled.div`
    color: white;
    margin-bottom: 16px;
    box-sizing: border-box;
    > div:first-child {
        display: flex;
        padding-bottom: 16px;
        box-sizing: border-box;
        justify-content: space-between;
        align-items: center;
    }

    .withtitle-and-viewing-title {
        > span {
            color: ${COLORS.primary_blue};
            padding-left: 10px;
            font-size: 18px;
            cursor: pointer;
        }

        .more-svg {
            margin-left: 10px;
        }
    }

    .withtitle-and-viewing-buttons {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .min-h-380 {
        min-height: 380px;
        max-height: 380px;
    }
    .min-h-480 {
        min-height: 480px;
        max-height: 480px;
    }
    .h-100-end {
        display: flex;
        align-items: flex-end;
        height: 100%;
    }
    .h-500 {
        width: 100%;
        min-height: 500px;
        position: relative;
        height: 40vh;
    }
    .h-100 {
        height: 100%;
    }
`;
const BreadcrumbWrap = styled.div`
    > ol {
        display: flex;
        flex-wrap: wrap;
        padding: 0.75rem 0rem;
        margin-bottom: 1rem;
        list-style: none;
        font-size: 18px;
        li {
            padding-right: 0.5rem;
            color: #849dac;
            cursor: pointer;
            padding-bottom: 10px;
            border-bottom: 2px solid ${COLORS.primary_blue};
            > span {
                padding-right: 5px;
                svg {
                    height: 17px;
                    path {
                        fill: ${COLORS.primary_blue};
                    }
                }
            }
        }
        li:hover {
            color: #00a7d6;
            cursor: pointer;
        }
        li:last-child:hover {
            // color: #849dac;
            cursor: default;
        }
        li:first-child::before {
            padding-right: 0;
            content: '';
        }
        li::before {
            display: inline-block;
            padding-right: 0.5rem;
            color: #849dac;
            box-sizing: border-box;
            padding-top: 1px;
            content: url(/images/dashboard/arrow-more-blue.svg);
        }
        li:last-child {
            color: white;
        }
        li:first-child {
            color: ${COLORS.primary_blue};
        }
    }
`;
const WithDateRangeTitleWrwap = styled.div`
    color: ${COLORS.primary_blue};
    box-sizing: border-box;
    background: ${COLORS.interface_dark_gradient_01};
    margin-bottom: 16px;
    min-height: 430px;

    // background: white;
    .with-date-range-title-wrap {
        position: relative;
        display: flex;
        box-sizing: border-box;
        background: ${COLORS.neutrals_black_2};
        padding: 16px 20px;
        gap: 20px;
        // justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid ${COLORS.interface_dark_fade};
        .call-icon-center {
            top: auto;
        }
    }

    .with-date-range-title-child {
        position: relative;
        max-height: 30vh;
        padding: 0px 20px;
    }

    .h-100-end {
        display: flex;
        align-items: flex-end;
        height: 100%;
    }
    .max-h-none {
        max-height: none;
    }
`;
export const ScrollArea = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100%;
    overflow-y: auto;
    padding-bottom: 70px;
`;
