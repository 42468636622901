import { Moment } from 'moment';
import { VERSA_CONFIG } from 'src/constants/config';
import { fetchWrapper } from 'src/utils/fetchWrapper';
import { objectToQueryString } from 'src/utils/utils';

const getCallAggregatesURL = (region: string): string => {
    let url = '';
    if (process.env.REACT_APP_ENV === 'development') {
        url = `${VERSA_CONFIG.DEV_API_DOMAIN}/api/v2/dashboard`;
    } else{
        url = `${VERSA_CONFIG.US_API_DOMAIN}/api/v2/dashboard`; 
    }

    return url;
};
const getSankeyFlowURL = (region: string): string => {
    let url = '';
    if (process.env.REACT_APP_ENV === 'development') {
        url = `${VERSA_CONFIG.DEV_API_DOMAIN}/api/v2/dashboard/sankey/talent`;
    } else  {
        url = `${VERSA_CONFIG.US_API_DOMAIN}/api/v2/dashboard/sankey/talent`; // flow
    }

    return url;
};
const getAnalyticsURL = (region: string) => {
    let url = '';
    if (process.env.REACT_APP_ENV === 'development') {
        url = `${VERSA_CONFIG.DEV_API_DOMAIN}/api/v2/dashboard/analytics`;
    } else {
        url = `${VERSA_CONFIG.US_API_DOMAIN}/api/v2/dashboard/analytics`;
    } 

    return url;
};
const getConversationsURL = (region: string) => {
    let url = '';
    if (process.env.REACT_APP_ENV === 'development') {
        url = `${VERSA_CONFIG.DEV_API_DOMAIN}/api/v2/dashboard/conversations`;
    } else  {
        url = `${VERSA_CONFIG.US_API_DOMAIN}/api/v2/dashboard/conversations`;
    } 

    return url;
};

const getCallLogDetailsURL = (region: string) => {
    let url = '';
    if (process.env.REACT_APP_ENV === 'development') {
        url = `${VERSA_CONFIG.DEV_API_DOMAIN}/api/v2/dashboard/conversations`;
    } else {
        url = `${VERSA_CONFIG.US_API_DOMAIN}/api/v2/dashboard/conversations`;
    } 

    return url;
};

const DATE_FORMAT = 'YYYY-MM-DD';
const DATE_FORMAT2 = 'YYYYMMDD';

type GetCallAggregatesT = {
    botid?: string;
    fromdate: Moment;
    todate: Moment;
};
export const getCallAggregates = (
    payload: GetCallAggregatesT,
    region: string
) => {
    const queryObj = {
        ...payload,
        fromdate: payload.fromdate.format(DATE_FORMAT) + 'T00:00:00Z',
        todate: payload.todate.format(DATE_FORMAT) + 'T23:59:59Z'
    };
    const url = `${getCallAggregatesURL(region)}${objectToQueryString(
        queryObj
    )}`;
    return fetchWrapper(url, 'GET');
};

export const getSankeyFlow = (payload: GetCallAggregatesT, region: string) => {
    const queryObj = {
        ...payload,
        fromdate: payload.fromdate.format(DATE_FORMAT2),
        todate: payload.todate.format(DATE_FORMAT2)
    };
    const url = `${getSankeyFlowURL(region)}/${payload.botid}/${queryObj.fromdate
        }/${queryObj.todate}`;
    return fetchWrapper(url, 'GET');
};

export const getAnalytics = (payload: GetCallAggregatesT, region: string) => {
    const queryObj = {
        ...payload,
        fromdate: payload.fromdate.format(DATE_FORMAT2),
        todate: payload.todate.format(DATE_FORMAT2)
    };
    const url = `${getAnalyticsURL(region)}/${payload.botid}/${queryObj.fromdate
        }/${queryObj.todate}`;
    return fetchWrapper(url, 'GET');
};

export const getConversations = (
    payload: GetCallAggregatesT,
    region: string
) => {
    const queryObj = {
        ...payload,
        fromdate: payload.fromdate.format(DATE_FORMAT2),
        todate: payload.todate.format(DATE_FORMAT2)
    };
    const url = `${getConversationsURL(region)}/${payload.botid}/${queryObj.fromdate
        }/${queryObj.todate}`;
    return fetchWrapper(url, 'GET');
};

export const getCallLogDetail = (
    botid: string,
    conversationId: string | undefined,
    region: string
) => {
    const url = `${getCallLogDetailsURL(region)}/${botid}/${conversationId}`;

    return fetchWrapper(url, 'GET');
};
