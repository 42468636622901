import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { COLORS } from 'src/constants/colors';
import { OverviewCardT, PureTextContentT } from 'src/types/dashboardT';

export const CallIcon = ({ classname }: { classname?: string }) => {
    return (
        <CallIconWrap className={classname}>
            <ReactSVG
                wrapper="span"
                // className="call-icon"
                src={'/images/dashboard/voice.svg'}
            />
        </CallIconWrap>
    );
};
const ArrowIcon = (props: Pick<OverviewCardT, 'arrow'>) => {
    const { arrow } = props;
    if (!arrow) return null;

    const isUp = arrow === 'up';
    return (
        <ReactSVG
            wrapper="span"
            className={`arrow-icon ${isUp ? 'arrow-icon-up' : ''}`}
            src={'/images/dashboard/arrow-down-yellow.svg'}
        />
    );
};

export function OverviewCard(props: OverviewCardT) {
    const {
        title,
        value,
        arrow,
        additionalTitleView,
        customTitle,
        hideTypeIcon,
        className,
        childClassName,
        children
    } = props;
    return (
        <OverviewCardWrap className={className}>
            <div>
                {customTitle ? (
                    customTitle
                ) : (
                    <>
                        <p className="overview-card-title">{title}</p>
                        <div className="value-wrap">
                            {/* {<ArrowIcon arrow={arrow} />} */}
                            <span className="value-text">{`${value}`}</span>
                        </div>
                    </>
                )}
                {additionalTitleView}
                {!hideTypeIcon && <CallIcon />}
            </div>
            <div className={childClassName}>{children}</div>
        </OverviewCardWrap>
    );
}

export function PureTextContent(props: PureTextContentT) {
    const { items } = props;

    return (
        <>
            {items?.map((i, idx) => {
                return (
                    <div key={`${i.key}-${idx}`}>
                        <PureTextContentWrap>
                            <p>{i.key}</p>
                            <p>
                                {i.value}
                                {/* {i.arrow && <ArrowIcon arrow={i.arrow} />} */}
                            </p>
                        </PureTextContentWrap>

                        {i.subItems?.map(sub => {
                            return (
                                <PureTextContentWrap isSub={true} key={sub.key}>
                                    <p>{sub.key}</p>
                                    <p>
                                        {sub.value}
                                        {/* {sub.arrow && (
                                            <ArrowIcon arrow={sub.arrow} />
                                        )} */}
                                    </p>
                                </PureTextContentWrap>
                            );
                        })}
                    </div>
                );
            })}
        </>
    );
}

const CallIconWrap = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    background: ${COLORS.interface_dark_darkblue};
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.7);
    padding: 12px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    box-sizing: border-box;
    // cursor: pointer;

    svg {
        path {
            fill: ${COLORS.primary_blue};
        }
    }
`;
const OverviewCardWrap = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;

    > div:first-child {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 18px 10px 16px;
        background: ${COLORS.neutrals_black_2};

        .value-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            .value-text {
                font-size: 40px;
                padding-left: 5px;
            }
        }
    }

    > div:nth-child(2) {
        padding: 20px;
        overflow-y: auto;
    }
    .overview-card-title {
        font-size: 16px;
        margin: 0px;
        color: ${COLORS.primary_blue};
    }

    .arrow-icon-up {
        svg {
            transform: rotate(3.142rad);
        }
        path {
            fill: ${COLORS.interface_dark_bad};
        }
    }
    .h-100 {
        height: 100%;
    }
    .p-doughnut {
        padding: 40px 80px !important;
    }
`;
const PureTextContentWrap = styled.div<{ isSub?: boolean }>`
    display: flex;
    font-size: 16px;
    padding-bottom: 8px;

    > p {
        margin: 0px;
    }
    > p:first-child {
        flex: 2;
        text-align: left;
    }
    > p:nth-child(2) {
        flex: 1;
        text-align: right;
    }

    .arrow-icon {
        svg {
            height: 5px;
            width: 10px;
        }
        padding-left: 6px;
    }

    ${({ isSub }) =>
        isSub &&
        `
        font-size: 12px;
        color: ${COLORS.primary_blue};
    `}
`;
