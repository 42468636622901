import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
// import { Auth } from 'aws-amplify';
import moment from 'moment';
import { groupBy } from 'lodash';
import { OverviewCard } from 'src/components/dashboard/OverviewCard';
import { COLORS } from 'src/constants/colors';
import {
    Breadcrumb,
    ScrollArea,
    WithDateRangeTitle,
    WithTitleAndViewing
} from 'src/components/dashboard/Views';
import DoughnutChart from 'src/components/dashboard/DoughnutChart';
import LineChart from 'src/components/dashboard/LineChart';
import MultiBarChart from 'src/components/dashboard/MultiBarChart';
import {
    MultiBarChartDataT,
    CallAggregatesResT,
    CallAggregatesTypes
} from 'src/types/dashboardT';
import BillingOverview from 'src/components/dashboard/BillingOverview';
import { round } from 'src/utils/utils';
import { SWITCH_ROUTES } from 'src/constants/routes';
import { getCallAggregates } from 'src/api/dashboard';
import LoadingView from 'src/components/shared/LoadingView';
import ClientIdBotIdContext from 'src/components/sidebar/clientIdBotIdContext';
import { getLocaleDateTimeString } from 'src/utils/utils';
import { LOCALE_TZ_FORMAT } from 'src/utils/constants';

export const BUTTONS = [
    { svgSrc: '/images/dashboard/voice.svg', text: 'Voice' }
    // { svgSrc: '/images/dashboard/chat.svg', text: 'Chat' },
    // { svgSrc: '/images/dashboard/whatsApp.svg', text: 'WhatsApp' },
    // { svgSrc: '/images/dashboard/sms.svg', text: 'SMS' }
];
export const BREADCRUMB_ITEMS = [
    {
        text: 'Home',
        redirectUrl: SWITCH_ROUTES.DASHBOARD,
        svgSrc: '/images/sidebar/home.svg'
    },
    { text: 'Billing Dashboard', redirectUrl: '' }
];
type hourlyCallAggItem = {
    date: string;
};
export type UnitDataT = {
    [k: string]: {
        totalCost: number;
        totalConversation: number;
        totalDuration: number;
    };
};
export default function BillingDashboard() {
    const { botId, region, timezone } = useContext(ClientIdBotIdContext);
    const [currentMonthCallAggregatesRes, setCurrentMonthCallAggregatesRes] =
        useState<CallAggregatesResT>(null);
    const [customDateCallAggregatesRes, setcustomDateCallAggregatesRes] =
        useState<CallAggregatesResT>(null);
    const [averageLinechartData, setAverageLinechartData] =
        useState<MultiBarChartDataT>();
    const [costLnechartData, setCostLnechartData] =
        useState<MultiBarChartDataT>();
    const [customDate, setCustomDate] = useState({
        startDate: moment().subtract(30, 'day'),
        endDate: moment()
    });

    const [fetchTMDStatus, setFetchTMDStatus] = useState('UNINIT');
    const [fetchCustomTD, setFetchCustomTD] = useState('UNINIT');

    const fetchThisMonthData = async () => {
        // const user = await Auth.currentAuthenticatedUser();
        setFetchTMDStatus('IN_PROGRESS');
        const monthRes = await getCallAggregates(
            {
                botid: botId,
                fromdate: moment().startOf('month'),
                todate: moment()
            },
            region
        );
        setFetchTMDStatus('SUCCESS');
        if (monthRes) {
            const { code, data } = monthRes;
            if (code === 200) {
                data.callAggregates.forEach((c: hourlyCallAggItem) => {
                    c.date = getLocaleDateTimeString(c.date, botId, timezone);
                });
                setCurrentMonthCallAggregatesRes(data as CallAggregatesResT);
            }
        }
    };
    const fetchCustomTimeData = async () => {
        // const user = await Auth.currentAuthenticatedUser();
        setFetchCustomTD('IN_PROGRESS');
        const custRes = await getCallAggregates(
            {
                botid: botId,
                fromdate: customDate.startDate,
                todate: customDate.endDate
            },
            region
        );
        setFetchCustomTD('SUCCESS');
        if (custRes) {
            const { code, data } = custRes;
            if (code === 200) {
                data.callAggregates.forEach((c: hourlyCallAggItem) => {
                    c.date = getLocaleDateTimeString(c.date, botId, timezone);
                });
                setcustomDateCallAggregatesRes(data as CallAggregatesResT);
            }
        }
    };
    useEffect(() => {
        if (botId && region) {
            fetchThisMonthData();
        }
    }, [botId, region]);
    useEffect(() => {
        if (botId && region) {
            fetchCustomTimeData();
        }
    }, [customDate, botId, region]);

    useEffect(() => {
        if (!customDateCallAggregatesRes) return;
        const calls = customDateCallAggregatesRes.callAggregates
            .filter(d => d.type === CallAggregatesTypes['1HOUR'])
            .map(d => {
                const date = d.date;
                const momentD = moment(date, LOCALE_TZ_FORMAT[timezone]);
                return { ...d, date, dateStr: momentD.format('YYYYMMDD') };
            });
        const dailyCalls = groupBy(calls, d => d.dateStr);

        let dailyData: UnitDataT = {};
        Object.keys(dailyCalls).forEach(k => {
            dailyData[k] = dailyCalls[k].reduce(
                (prev, c) => {
                    return {
                        totalCost: prev.totalCost + c.totalAccountWiseCost,
                        totalConversation:
                            prev.totalConversation + c.totalConversation,
                        totalDuration: prev.totalDuration + c.totalDuration
                    };
                },
                { totalCost: 0, totalConversation: 0, totalDuration: 0 }
            );
        });

        const labels = Object.keys(dailyData).map(
            d => `${d.substring(6, 8)}/${d.substring(4, 6)}`
        );
        setAverageLinechartData({
            labels,
            datasets: [
                {
                    label: 'Total Cost',
                    data: Object.keys(dailyData).map(
                        k => dailyData[k].totalCost / 100
                    ),
                    backgroundColor: COLORS.interface_dark_accent_01,
                    borderColor: COLORS.interface_dark_accent_01,
                    yAxisID: 'y1',
                    type: 'line'
                },
                {
                    label: 'Average Per Call',
                    data: Object.keys(dailyData).map(k =>
                        dailyData[k].totalConversation > 0
                            ? round(
                                  dailyData[k].totalCost /
                                      (100 * dailyData[k].totalConversation)
                              )
                            : 0
                    ),
                    backgroundColor: COLORS.interface_dark_accent_02,
                    borderColor: COLORS.interface_dark_accent_02,
                    yAxisID: 'y2',
                    type: 'line'
                }
            ],
            isCurrency: true
        });
        setCostLnechartData({
            labels,
            datasets: [
                {
                    label: 'Cost',
                    data: Object.keys(dailyData).map(
                        k => dailyData[k].totalCost / 100
                    ),
                    backgroundColor: COLORS.interface_dark_accent_01,
                    borderColor: COLORS.interface_dark_accent_01,
                    yAxisID: 'y1',
                    type: 'bar'
                },
                {
                    label: 'Conversations',
                    data: Object.keys(dailyData).map(
                        k => dailyData[k].totalConversation
                    ),
                    backgroundColor: COLORS.interface_dark_accent_03,
                    borderColor: COLORS.interface_dark_accent_03,
                    yAxisID: 'y2',
                    type: 'line'
                }
            ],
            isCurrency: false
        });
    }, [customDateCallAggregatesRes]);

    const renderThirdArea = () => {
        const doughnutData: any = {
            labels: ['Voice'],
            datasets: [
                {
                    data: [customDateCallAggregatesRes?.totalCost],
                    backgroundColor: [COLORS.interface_dark_accent_01]
                }
            ]
        };

        return (
            <WithTitleAndViewing title="" buttons={BUTTONS} hideTitle>
                <div className="row overview-cards-bg third-area min-h-480">
                    <OverviewCard
                        title="TOTAL SPEND"
                        value={`$${customDateCallAggregatesRes?.totalCost}`}
                        arrow="down"
                        childClassName="h-100 p-doughnut"
                        hideTypeIcon
                    >
                        <DoughnutChart {...doughnutData} />
                    </OverviewCard>
                    <div className="divide-line" />
                    <OverviewCard
                        customTitle={
                            <TotalSpendLineTitle>
                                TOTAL CONVERSATIONS
                                <p>{`${customDateCallAggregatesRes?.totalCoversation}`}</p>
                            </TotalSpendLineTitle>
                        }
                        value={`${customDateCallAggregatesRes?.totalCoversation}`}
                        childClassName="h-100"
                        hideTypeIcon
                    >
                        <MultiBarChart data={costLnechartData} />
                    </OverviewCard>
                </div>
            </WithTitleAndViewing>
        );
    };

    return (
        <BillingDashboardWrap>
            <Breadcrumb items={BREADCRUMB_ITEMS} />
            {fetchTMDStatus === 'IN_PROGRESS' ||
            fetchCustomTD === 'IN_PROGRESS' ? (
                <LoadingView />
            ) : fetchTMDStatus === 'SUCCESS' && fetchCustomTD === 'SUCCESS' ? (
                <ScrollArea>
                    <BillingOverview
                        buttons={BUTTONS}
                        callAggregatesRes={currentMonthCallAggregatesRes}
                    />

                    <WithDateRangeTitle
                        {...customDate}
                        setDates={setCustomDate}
                        childClassname="h-100-end"
                    >
                        <MultiBarChart data={averageLinechartData} />
                    </WithDateRangeTitle>

                    {renderThirdArea()}
                </ScrollArea>
            ) : null}
        </BillingDashboardWrap>
    );
}

export const BillingDashboardWrap = styled.div`
    box-sizing: border-box;
    overflow: hidden auto;
    display: flex;
    flex-direction: column;

    .users-table-container {
        max-height: 47vh;
        overflow: scroll;
    }

    .row {
        display: flex;
        width: 100%;
    }

    .w-25 {
        > div:nth-child(odd) {
            width: 25%;
        }
    }
    .overview-cards-bg {
        background: ${COLORS.interface_dark_gradient_01};

        .divide-line {
            height: 100%;
            width: 2px;
            background: ${COLORS.interface_dark_fade};
        }
    }

    .third-area {
        > div:first-child {
            width: 25%;
        }
        > div:nth-child(3) {
            width: 75%;
        }
    }
`;
export const TotalSpendLineTitle = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
    color: ${COLORS.primary_blue};
    > p {
        margin: 0px;
        font-size: 40px;
        padding-left: 5px;
        color: white;
    }
`;
