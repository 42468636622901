const ROUTES = {
    ROOT: '/',
    BO: '/',
    LOING: 'login',
    FORGOT_PASSWORD: 'forgotPassword',
    FULL_PAGE: 'fullpage',
    SANKEY: 'sankey',
    SAMPLE_PAGE: 'sample',
    DASHBOARD: 'dashboard',
    LOGOUT: 'logout'
};
export const DASHBOARD_CHILD_ROUTES = {
    BILLING: 'billing',
    REPORT_AND_ANALYTICS: 'reportandanalytics',
    ADMIN_BILLING: 'adminBilling',
    INVOICE: 'invoice'
};

export const SWITCH_ROUTES = {
    ROOT: ROUTES.ROOT,
    LOING: `/${ROUTES.LOING}`,
    LOGOUT: `/${ROUTES.LOGOUT}`,
    FORGOT_PASSWORD: `/${ROUTES.FORGOT_PASSWORD}`,
    BO: ROUTES.BO,
    FULL_PAGE: `/${ROUTES.FULL_PAGE}`,
    SANKEY: `${ROUTES.BO}${ROUTES.SANKEY}`,
    DASHBOARD: `${ROUTES.BO}${ROUTES.DASHBOARD}`,
    SAMPLE_PAGE: `${ROUTES.BO}${ROUTES.SAMPLE_PAGE}`,
    DASHBOARD_BILLING: `${ROUTES.BO}${ROUTES.DASHBOARD}/${DASHBOARD_CHILD_ROUTES.BILLING}`,
    DASHOARD_ADMIN_BILLING: `${ROUTES.BO}${ROUTES.DASHBOARD}/${DASHBOARD_CHILD_ROUTES.ADMIN_BILLING}`,
    DASHBOARD_RA: `${ROUTES.BO}${ROUTES.DASHBOARD}/${DASHBOARD_CHILD_ROUTES.REPORT_AND_ANALYTICS}`,
    INVOICE: `${ROUTES.BO}${ROUTES.DASHBOARD}/${DASHBOARD_CHILD_ROUTES.BILLING}/${DASHBOARD_CHILD_ROUTES.INVOICE}`,
    USER_MANAGEMENT: `${ROUTES.BO}${ROUTES.DASHBOARD}/user-management`,
    ENGINE: `${ROUTES.BO}engine-workflow`
};

export default ROUTES;
