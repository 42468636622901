import React from 'react';
import PropTypes from 'prop-types';
import BaseModal from '../shared/modals/BaseModal';
import { CreateContainer } from 'src/routes/UserMgmt/components/CreateUser';
import { ReactSVG } from 'react-svg';
import Player from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

type AudioPlayerProps = {
    show: boolean;
    onClose: Function;
    src: string;
};

export default function AudioPlayer({ show, onClose, src }: AudioPlayerProps) {
    return (
        <BaseModal show={show}>
            <div
                style={{
                    margin: '0 auto',
                    maxHeight: '90vh',
                    overflowY: 'scroll'
                }}
            >
                <CreateContainer>
                    <div>
                        Call Recording
                        <div className="divide-line" />
                        <ReactSVG
                            wrapper="span"
                            src={'/images/shared/close.svg'}
                            className="close-btn"
                            onClick={() => {
                                onClose();
                            }}
                        />
                    </div>
                    <Player
                        autoPlay
                        src={src}
                        onPlay={(e: any) => console.log('onPlay')}
                        // other props here
                    />
                </CreateContainer>
            </div>
        </BaseModal>
    );
}

AudioPlayer.propTypes = {
    show: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    src: PropTypes.string.isRequired
};
