import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import faker from 'faker';
import { BarChartDataT } from 'src/types/dashboardT';
import { COLORS } from 'src/constants/colors';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
        intersect: false,
        mode: 'index'
    },
    scales: {
        x: {
            color: 'white',
            ticks: {
                color: 'white'
            }
            // display: false
        },
        y: {
            color: 'white',
            ticks: {
                color: 'white'
            }
        }
    },
    plugins: {
        legend: {
            position: 'bottom' as const,
            labels: {
                color: 'white'
            }
        },
        tooltip: {
            backgroundColor: COLORS.white_9,
            titleColor: 'black',
            bodyColor: 'black',
            callbacks: {
                label: function (tooltipItem: any) {
                    return `${tooltipItem.dataset.label} ${tooltipItem.formattedValue}`;
                }
            }
        }
        // title: {
        //     display: true,
        //     text: 'Chart.js Line Chart'
        // }
    }
};

const FAKE_LABELS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July'
];
const FAKE_DATA = {
    labels: FAKE_LABELS,
    datasets: [
        {
            label: 'Dataset 1',
            data: FAKE_LABELS.map(() =>
                faker.datatype.number({ min: -1000, max: 1000 })
            ),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)'
        },
        {
            label: 'Dataset 2',
            data: FAKE_LABELS.map(() =>
                faker.datatype.number({ min: -1000, max: 1000 })
            ),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)'
        }
    ]
};

type LineChartProps = {
    data?: BarChartDataT;
};

const LineChart = (props: LineChartProps) => {
    const { data } = props;
    return <Line options={options as any} data={data || FAKE_DATA} />;
};

export default LineChart;
