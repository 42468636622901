import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { useNavigate } from 'react-router-dom';
import { COLORS } from 'src/constants/colors';
import { Z_INDEXS } from 'src/constants/numbers';
import { UserInfoT } from 'src/hooks/useCheckUserState';
import { SWITCH_ROUTES } from 'src/constants/routes';
import EditProfileModal from 'src/components/shared/modals/EditProfileModal';
import { Auth } from 'aws-amplify';
import ClientIdBotIdContext from '../sidebar/clientIdBotIdContext';
const INIT_FORM_STATE = {
    companyName: '',
    contactName: '',
    email: '',
    website: ''
};
export const UserInfoFloating = (props: { info: UserInfoT }) => {
    const { setClientId, setBotId, setRegion, setTimezone, setAccountId } =
        useContext(ClientIdBotIdContext);
    const [showEditProfileModal, setShowEditProfileModal] = useState(false);
    const [formState, setFormState] = useState(INIT_FORM_STATE);
    const [simple, setSimple] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        const fetchUser = async () => {
            const user = await Auth.currentAuthenticatedUser();
            if (user !== null) {
                // console.log(user);
                setFormState({
                    companyName: user.attributes['custom:company'],
                    contactName: user.attributes.given_name,
                    email: user.attributes.email,
                    website: user.attributes.website
                });
            }
        };
        fetchUser().catch(console.error);
    }, []);

    useEffect(() => {
        setSimple(!location.pathname.endsWith(SWITCH_ROUTES.DASHBOARD));
    }, [location]);

    if (!props.info) return null;
    //const { email, family_name, given_name, username } = props.info;
    //const name = username ? username : `${given_name} ${family_name}`;
    const logout = () => {
        // clear context
        setClientId('');
        setBotId('');
        setRegion('');
        setTimezone('');
        setAccountId('');
        navigate(SWITCH_ROUTES.LOGOUT);
    };
    return (
        <>
            <UserInfoFloatingWrap simple={simple}>
                <div>
                    <div className="user-info-logo">
                        <ReactSVG
                            wrapper="span"
                            src={'/images/shared/logo.svg'}
                        />
                    </div>
                </div>
                <div className="user-info-wrap">
                    <p>Welcome {formState.companyName}</p>
                    {!simple && (
                        <>
                            <p>Contact Name: {formState.contactName}</p>
                            <p>Email: {formState.email}</p>
                        </>
                    )}
                </div>

                <div className="user-info-operation">
                    <div onClick={() => setShowEditProfileModal(true)}>
                        <ReactSVG
                            className="operation-icon"
                            wrapper="span"
                            src={'/images/shared/edit.svg'}
                        />
                        <span>Edit your details</span>
                    </div>
                    <div className="blue" onClick={logout}>
                        <ReactSVG
                            wrapper="span"
                            src={'/images/shared/logout.svg'}
                        />
                        <span>Log out</span>
                    </div>
                </div>
            </UserInfoFloatingWrap>
            <EditProfileModal
                show={showEditProfileModal}
                onClose={() => setShowEditProfileModal(false)}
            />
        </>
    );
};

const UserInfoFloatingWrap = styled.div<{ simple: boolean }>`
    z-index: ${Z_INDEXS.USER_INFO_FLOATING};
    position: fixed;
    top: 0px;
    right: 4rem;
    display: flex;
    padding: 20px;
    background: ${COLORS.interface_dark_gradient_01};
    box-shadow: rgb(0, 25, 70, 0.3) 0px 10px 1px;

    .user-info-logo {
        background: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: ${({ simple }) => (simple ? '30px' : '60px')};
        width: ${({ simple }) => (simple ? '30px' : '60px')};
        box-sizing: border-box;
        svg {
            height: ${({ simple }) => (simple ? '15px' : '30px')};
            width: ${({ simple }) => (simple ? '15px' : '30px')};
        }
    }

    .user-info-wrap {
        padding-left: 20px;
        padding-right: 7rem;
        > p {
            margin: 0px;
            font-size: 12px;
            color: ${COLORS.primary_blue};
        }
        > p:first-child {
            font-size: 16px;
            color: white;
        }
    }

    .user-info-operation {
        display: flex;
        flex-direction: column;
        gap: 10px;
        > div {
            cursor: pointer;
        }
        svg {
            padding-right: 3px;
            margin-bottom: -3px;
            height: 17px;
            width: 17px;
        }

        .blue {
            color: ${COLORS.primary_blue};
            svg {
                path {
                    fill: ${COLORS.primary_blue};
                }
            }
        }
    }
`;
