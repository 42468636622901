import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { NavigateFunction } from 'react-router-dom';
import { ItemsT } from 'src/components/sidebar/types';

const CollapseSideBarItem = (
    props: ItemsT & { navigate: NavigateFunction; onToggleMenu: () => void }
) => {
    const { iconPath, title, redirectPath, subItems, navigate, onToggleMenu } =
        props;
    const onItemClick = () => {
        if (redirectPath) navigate(redirectPath);
        else if (subItems) onToggleMenu();
    };

    return (
        <CollapseSideBarItemWrap>
            <div className="sb-item-wrap" onClick={onItemClick}>
                <div>
                    <ReactSVG
                        // alt={title}
                        wrapper="span"
                        className="sb-itme-icon"
                        src={iconPath}
                    />
                </div>
                <div />
            </div>
        </CollapseSideBarItemWrap>
    );
};

const CollapseSideBarItemWrap = styled.div`
    box-sizing: border-box;
    height: 60px;
    background: linear-gradient(180deg, #183f84 0%, #000714 100%);
    box-shadow: 0px 0px 2px #000000;
    border-radius: 10px;
    margin: 10px;
    cursor: pointer;

    &:hover {
        background: linear-gradient(180deg, #051e49 0%, #0f3373 100%);
        box-shadow: inset 0px 1px 5px #000000;
        border-radius: 5px;
    }

    .sb-item-wrap {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
`;

export default CollapseSideBarItem;
