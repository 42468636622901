export const parseDateAndTime = (s: string) => {
    return `${s.substring(8, 10)}:${s.substring(10, 12)} ${s.substring(
        6,
        8
    )}/${s.substring(4, 6)}/${s.substring(0, 4)}`;
};

export const parseDate = (s: string) => {
    return `${s.substring(6, 8)}/${s.substring(4, 6)}/${s.substring(0, 4)}`;
};
