import { Auth } from 'aws-amplify';
import { VERSA_CONFIG } from 'src/constants/config';
import { fetchWrapper } from 'src/utils/fetchWrapper';

type ClientId = {
    ClientId: string;
};

type Pagination = {
    page: number;
};

type Filter = {
    // ClientId: string;
    ClientId: string;
    SetupTime: {
        $gte: string;
        $lte: string;
    };
    // InvoiceId: string;
};

type FilterPayload = {
    filter: ClientId;
};

type CallHistoryPayload = {
    filter: Filter;
    pagination: Pagination;
};

type GetInvoicePayload = {
    ClientId: string;
    InvoiceId: string;
};

type GetAudioCallLog = {
    year: string;
    month: string;
    date: string;
    conversationId: string;
};

export const getInvoices = (payload: FilterPayload, region: string) => {
    let url = '';
    if (process.env.REACT_APP_ENV === 'development') {
        url = `${VERSA_CONFIG.DEV_API_DOMAIN}/napi/invoice/invoice-history`;
    } else {
        url = `${VERSA_CONFIG.US_API_DOMAIN}/napi/invoice/invoice-history`;
    } 
    return fetchWrapper(url, 'POST', payload);
};

export const getCallHistory = (payload: CallHistoryPayload, region: string) => {
    let url = '';
    if (process.env.REACT_APP_ENV === 'development') {
        url = `${VERSA_CONFIG.DEV_API_DOMAIN}/napi/invoice/call-history`;
    } else {
        url = `${VERSA_CONFIG.US_API_DOMAIN}/napi/invoice/call-history`;
    } 
    return fetchWrapper(url, 'POST', payload);
};

export const getInvoice = (payload: GetInvoicePayload, region: string) => {
    let url = '';
    if (process.env.REACT_APP_ENV === 'development') {
        url = `${VERSA_CONFIG.DEV_API_DOMAIN}/napi/invoice/get?ClientId=${payload.ClientId}&InvoiceId=${payload.InvoiceId}`;
    } else {
        url = `${VERSA_CONFIG.US_API_DOMAIN}/napi/invoice/get?ClientId=${payload.ClientId}&InvoiceId=${payload.InvoiceId}`;
    } 

    return fetchWrapper(url, 'GET');
};

export const getClientIds = async (domain = '') => {
    const user = await Auth.currentAuthenticatedUser();
    if (process.env.REACT_APP_ENV === 'development') {
        const url = `${domain}/api/v2/dashboard/clients/${user.username}`;
        return fetchWrapper(url, 'GET');
    } else {
        const url = `${VERSA_CONFIG.US_API_DOMAIN}/api/v2/dashboard/clients/${user.username}`;
        return fetchWrapper(url, 'GET');
    }
};

export const getAudioCallLog = (payload: GetAudioCallLog, region: string) => {
    let url = '';
    if (process.env.REACT_APP_ENV === 'development') {
        url = `${VERSA_CONFIG.DEV_API_DOMAIN}/napi/callLog/getAudio`;
    } else {
        url = `${VERSA_CONFIG.US_API_DOMAIN}/napi/callLog/getAudio`;
    } 

    return fetchWrapper(url, 'POST', payload);
};
