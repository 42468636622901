import React, { ElementType, useEffect, useState } from 'react';
// import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExpandState, useSidebarMenu } from 'src/hooks/useSidebarMenu';
import { SWITCH_ROUTES } from 'src/constants/routes';
import {
    MENU_LOGO_VIEW_HEIGHT,
    SIDEBAR_WIDTH_COLLAPSE,
    SIDEBAR_WIDTH_EXPAND
} from 'src/constants/numbers';
import ExpandSideBarItem from 'src/components/sidebar/ExpandSideBarItem';
import CollapseSideBarItem from 'src/components/sidebar/CollapseSideBarItem';
import { COLORS } from 'src/constants/colors';
import ClientBotFilter from './ClientBotFilter';
import { Auth } from 'aws-amplify';
import { SIDEBAR_ROLE_MAPPING } from 'src/utils/constants';
import AllClientBotFilter from './AllClientBotFilter';

const MENU_ITEMS = [
    {
        iconPath: '/images/sidebar/home.svg',
        title: 'HOME',
        key: 'HOME',
        redirectPath: SWITCH_ROUTES.BO
    },
    // {
    //     iconPath: '/images/sidebar/account.svg',
    //     title: 'ACCOUNT DETAILS',
    //     redirectPath: SWITCH_ROUTES.FULL_PAGE
    // },
    {
        iconPath: '/images/sidebar/billing.svg',
        title: 'BILLING DASHBOARD',
        key: 'BILLING_DASHBOARD',
        // redirectPath: `${SWITCH_ROUTES.DASHBOARD_BILLING}`,
        subItems: [
            {
                title: 'Billing',
                key: 'BILLING',
                redirectPath: `${SWITCH_ROUTES.DASHBOARD_BILLING}`
            },
            {
                title: 'Invoice',
                key: 'INVOICE',
                redirectPath: SWITCH_ROUTES.INVOICE
            }
        ]
        // redirectPath: `${SWITCH_ROUTES.SAMPLE_PAGE}/1`
    },
    {
        iconPath: '/images/sidebar/reporting-analytics.svg',
        title: 'REPORTING & ANALYTICS',
        key: 'REPORTING_AND_ANALYTICS',
        redirectPath: `${SWITCH_ROUTES.DASHBOARD_RA}`
        // subItems: [
        //     { title: 'Report 01', redirectPath: SWITCH_ROUTES.DASHBOARD_RA },
        //     { title: 'Report 02', redirectPath: SWITCH_ROUTES.DASHBOARD },
        //     { title: 'Report 03', redirectPath: SWITCH_ROUTES.SANKEY }
        // ]
    },
    {
        title: 'USER MANAGEMENT',
        key: 'USER_MANAGEMENT',
        iconPath: '/images/sidebar/account.svg',
        redirectPath: `${SWITCH_ROUTES.USER_MANAGEMENT}`
    },
    {
        title: 'ENGINE WORKFLOW',
        key: 'ENGINE',
        iconPath: '/images/sidebar/reporting-analytics.svg',
        redirectPath: `${SWITCH_ROUTES.ENGINE}`
    }
    // {
    //     iconPath: '/images/sidebar/logout.svg',
    //     title: 'Logout',
    //     redirectPath: SWITCH_ROUTES.LOGOUT
    // }
];
export const Sidebar = () => {
    const [authUser, setAuthUser] = useState<{
        attributes: {
            'custom:Role': string;
        };
    }>();
    const { isExpand, onToggle, setMenuExpandState } = useSidebarMenu();
    const navigate = useNavigate();
    const location = useLocation();
    const [authLoadingStatus, setAuthLoadingStatus] = useState('UNINIT');
    // const renderExtraButtons = () => {
    //     return (
    //         <>
    //             <button onClick={onToggle}>Toggle Menu</button>
    //         </>
    //     );
    // };

    const getAuthuser = async () => {
        try {
            setAuthLoadingStatus('IN_PROGRESS');
            const user = await Auth.currentAuthenticatedUser();
            setAuthUser(user);
            setAuthLoadingStatus('SUCCESS');
        } catch (e) {
            setAuthLoadingStatus('ERROR');
        }
    };

    useEffect(() => {
        getAuthuser();
    }, []);

    let dropDownBody = (<></>) as React.ReactElement | null;

    if (authLoadingStatus === 'IN_PROGRESS') {
        dropDownBody = <p>Loading...</p>;
    } else if (authLoadingStatus === 'SUCCESS') {
        dropDownBody = !location.pathname.includes('user-management') ? (
            authUser?.attributes['custom:Role'] === 'VERSA' ? (
                <AllClientBotFilter />
            ) : (
                <ClientBotFilter />
            )
        ) : null;
    } else if (authLoadingStatus === 'ERROR') {
        dropDownBody = <p>Could not Client filters.</p>;
    }

    return (
        <Wrapper isExpand={isExpand}>
            <div className="logo-wrap">
                <img alt="logo" src="/images/sidebar/logo.svg" />
                <img alt="logo-text" src="/images/sidebar/logo-text.svg" />

                {/* <ReactSVG
                    wrapper="span"
                    src={'/images/shared/close.svg'}
                    className="close-btn"
                    onClick={() => setMenuExpandState(ExpandState.COLLAPSE)}
                /> */}
            </div>
            {dropDownBody}
            <div className="sidebar-items">
                <div className="sidebar-expand-wrap">
                    {authUser &&
                        MENU_ITEMS.map(m =>
                            SIDEBAR_ROLE_MAPPING[
                                authUser.attributes['custom:Role']
                            ].includes(m.key) ? (
                                <ExpandSideBarItem uRole={authUser.attributes['custom:Role']} {...m} navigate={navigate} />
                            ) : null
                        )}
                </div>
                <div className="sidebar-collapse-wrap">
                    {authUser &&
                        MENU_ITEMS.map(m =>
                            SIDEBAR_ROLE_MAPPING[
                                authUser.attributes['custom:Role']
                            ].includes(m.key) ? (
                                <CollapseSideBarItem
                                    {...m}
                                    navigate={navigate}
                                    onToggleMenu={onToggle}
                                />
                            ) : null
                        )}
                </div>
                {/* {renderExtraButtons()} */}
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div<{ isExpand: boolean }>`
    width: ${({ isExpand }) =>
        isExpand ? `${SIDEBAR_WIDTH_EXPAND}px` : `${SIDEBAR_WIDTH_COLLAPSE}px`};
    min-width: ${({ isExpand }) =>
        isExpand ? `${SIDEBAR_WIDTH_EXPAND}px` : `${SIDEBAR_WIDTH_COLLAPSE}px`};
    max-width: ${({ isExpand }) =>
        isExpand ? `${SIDEBAR_WIDTH_EXPAND}px` : `${SIDEBAR_WIDTH_COLLAPSE}px`};
    color: white;
    background: #00153a;
    flex-grow: 1;
    border-right: 1px solid ${({ theme }) => theme.sidebar.border};
    .sidebar-expand-wrap {
        display: ${({ isExpand }) => (isExpand ? `block` : `none`)};
    }
    .sidebar-collapse-wrap {
        display: ${({ isExpand }) => (isExpand ? `none` : `block`)};
    }

    .logo-wrap {
        display: flex;
        position: relative;
        height: ${MENU_LOGO_VIEW_HEIGHT}px;
        box-sizing: border-box;
        padding: ${({ isExpand }) =>
            isExpand ? '40px 0px 0px 10px' : '40px 0px 0px 0px'};
        ${({ isExpand }) => !isExpand && 'justify-content: center;'}
        > img:first-child {
            width: 40px;
            height: 30px;
        }
        > img:nth-child(2) {
            display: ${({ isExpand }) => (isExpand ? `block` : `none`)};
            width: auto;
            height: 14px;
            margin-top: 10px;
            margin-left: 8px;
        }

        .close-btn {
            display: ${({ isExpand }) => (isExpand ? `block` : `none`)};
            height: 15px;
            position: absolute;
            right: 10px;
            margin-top: 6px;
            cursor: pointer;
            svg {
                height: 15px;
                rect {
                    fill: ${COLORS.primary_blue};
                }
            }
        }
    }

    .sidebar-items {
        height: calc(100% - ${MENU_LOGO_VIEW_HEIGHT}px);
        max-height: calc(100% - ${MENU_LOGO_VIEW_HEIGHT}px);
        overflow: auto;
        padding-bottom: 20px;
    }

    &:hover {
        width: ${SIDEBAR_WIDTH_EXPAND}px;
        min-width: ${SIDEBAR_WIDTH_EXPAND}px;
        max-width: ${SIDEBAR_WIDTH_EXPAND}px;
        .sidebar-expand-wrap {
            display: block;
        }
        .sidebar-collapse-wrap {
            display: none;
        }
        .logo-wrap {
            position: relative;
            justify-content: flex-start;
            padding: 40px 0px 0px 10px;
            > img:nth-child(2) {
                display: block;
                width: auto;
                height: 14px;
                margin-top: 10px;
                margin-left: 8px;
            }
            .close-btn {
                display: block;
            }
        }
    }
`;
export default Sidebar;
