import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BaseModal from 'src/components/shared/modals/BaseModal';
import styled from 'styled-components';
import { COLORS } from 'src/constants/colors';
import { ReactSVG } from 'react-svg';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { useGetInvoice } from '../hooks';

import '@react-pdf-viewer/core/lib/styles/index.css';

type InvoiceProps = {
    ClientId: string;
    InvoiceId: string;
    onClose: () => void;
};

export default function ViewInvoice(props: InvoiceProps) {
    const { ClientId, InvoiceId, onClose } = props;
    const { status, data, error, fetchFn } = useGetInvoice() as {
        data: string;
        status: string;
        error: string;
        fetchFn: Function;
    };

    useEffect(() => {
        if (ClientId && InvoiceId) {
            fetchFn(ClientId, InvoiceId);
        }
    }, [ClientId, InvoiceId]);

    let body = <></>;

    if (status === 'IN_PROGRESS') {
        body = <p>Loading...</p>;
    } else if (status === 'SUCCESS') {
        body = (
            <div
                style={{
                    border: '1px solid rgba(0, 0, 0, 0.3)',
                    height: '660px'
                    // width: '818px'
                }}
            >
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer fileUrl={data} defaultScale={1.5} />
                </Worker>
            </div>
        );
    } else if (status === 'ERROR') {
        body = <p>{error}</p>;
    }

    return (
        <BaseModal show={!!(ClientId && InvoiceId)}>
            <InvoiceDetailWrap>
                <div>
                    Invoice
                    <div className="divide-line" />
                    {body}
                    <ReactSVG
                        wrapper="span"
                        src={'/images/shared/close.svg'}
                        className="close-btn"
                        onClick={onClose}
                    />
                </div>
            </InvoiceDetailWrap>
        </BaseModal>
    );
}

ViewInvoice.propTypes = {
    ClientId: PropTypes.string.isRequired,
    InvoiceId: PropTypes.string.isRequired
};

const InvoiceDetailWrap = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 80vw;
    background: ${COLORS.neutrals_black_2};
    border: 1px solid ${COLORS.Interface_dark_glow};
    overflow: scroll;

    > div {
        padding: 20px;
    }
    > div:first-child {
        position: relative;
        background: ${COLORS.neutrals_black_2_noa};

        .close-btn {
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
        }
    }
    > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        background: ${COLORS.interface_dark_gradient_01};
        padding: 20px;
        .conversations-wrap {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-height: 60vh;
            overflow-y: scroll;
        }
    }

    .divide-line {
        background: ${({ theme }) => theme.interface_dark_glow};
        width: 100%;
        height: 1px;
        margin: 20px 0px;
    }
`;
