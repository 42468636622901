import React from 'react';
import {
    createBrowserRouter,
    Navigate,
    RouterProvider
} from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from 'src/theme/GlobalStyles';
import GlobalErrors from 'src/components/shared/GlobalErrors';
import { ModalContainer } from 'src/components/shared/modals/BaseModal';
import FullPage from 'src/routes/FullPage';
import BORoot from 'src/routes/BORoot';
import ErrorPage from 'src/routes/ErrorPage';
import LoginPage from 'src/routes/LoginPage';
import ForgotPasswordPage from 'src/routes/ForgotPasswordPage';
import SamplePage from 'src/routes/SamplePage';
import SankeyPage from 'src/routes/SankeyPage';
// import Dashboard from 'src/routes/Dashboard';
import reportWebVitals from 'src/reportWebVitals';
import ROUTES, { SWITCH_ROUTES } from 'src/constants/routes';
import { theme } from 'src/constants/theme';
import SidebarMenuProvider from 'src/hooks/useSidebarMenu';
import BOHome from 'src/routes/BOHome';
import BillingDashboard from 'src/routes/dashboard/BillingDashboard';
import ReportAndAnalytics from 'src/routes/dashboard/ReportAndAnalytics';
import AdminBillingDashboard from 'src/routes/dashboard/AdminBillingDashboard';
import awsExports from './aws-exports';
import Logout from './routes/Logout';
import Invoice from './routes/dashboard/Invoice';
import { ClientIdBotIdProvider } from './components/sidebar/clientIdBotIdContext';
import UserMgmt from 'src/routes/UserMgmt';
import Engine from './routes/engine';
import Enginev2 from './routes/engingv2';

Amplify.configure({
    Auth: {
        region: awsExports.REGION,
        userPoolId: awsExports.USER_POOL_ID,
        userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID
    }
});
const router = createBrowserRouter([
    {
        path: ROUTES.ROOT,
        element: <Navigate to={ROUTES.DASHBOARD} />,
        errorElement: <ErrorPage />
    },
    {
        path: ROUTES.LOING,
        element: <LoginPage />
    },
    {
        path: ROUTES.FORGOT_PASSWORD,
        element: <ForgotPasswordPage />
    },
    {
        path: ROUTES.BO,
        element: <BORoot />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: ROUTES.SANKEY,
                element: <SankeyPage />
            },
            {
                path: ROUTES.DASHBOARD,
                element: <BOHome />
            },
            {
                path: SWITCH_ROUTES.DASHBOARD_BILLING,
                element: <BillingDashboard />
            },
            {
                path: SWITCH_ROUTES.DASHBOARD_RA,
                element: <ReportAndAnalytics />
            },
            {
                path: SWITCH_ROUTES.DASHOARD_ADMIN_BILLING,
                element: <AdminBillingDashboard />
            },
            {
                path: SWITCH_ROUTES.INVOICE,
                element: <Invoice />
            },
            // {
            //     path: ROUTES.DASHBOARD,
            //     element: <Dashboard />
            // },
            {
                path: `${ROUTES.SAMPLE_PAGE}/:id`,
                element: <SamplePage />
            },
            {
                path: SWITCH_ROUTES.USER_MANAGEMENT,
                element: <UserMgmt />
            },
            // {
            //     path: SWITCH_ROUTES.ENGINE,
            //     element: <Engine />
            // },
            {
                path: SWITCH_ROUTES.ENGINE,
                element: <Enginev2 />
            }
        ]
    },
    {
        path: ROUTES.FULL_PAGE,
        element: <FullPage />
    },
    {
        path: ROUTES.LOGOUT,
        element: <Logout />
    }
]);
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <SidebarMenuProvider>
            <ClientIdBotIdProvider>
                <ThemeProvider theme={theme}>
                    <GlobalStyles />
                    <RouterProvider router={router} />
                    <ModalContainer />
                    <GlobalErrors />
                </ThemeProvider>
            </ClientIdBotIdProvider>
        </SidebarMenuProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
