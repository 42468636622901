export function getKVInLS(key: string) {
    return window.localStorage.getItem(key);
}
export function setKVInLs(key: string, value: string) {
    if (typeof window === 'undefined') return;
    window.localStorage.setItem(key, value);
}

export function getSidebarMode(): 'expand' | 'collapse' {
    const result = getKVInLS('sidebarMode') as 'expand' | 'collapse';
    return result;
    // return result || (window.innerWidth < 1280 ? 'collapse' : 'expand');
}
export function setSidebarMode(type: 'expand' | 'collapse') {
    setKVInLs('sidebarMode', type);
}

const TOKEN_KEY = 'token';
export function setAuthToken(token: string) {
    return setKVInLs(TOKEN_KEY, token);
}
export function getAuthToken(): string | null {
    return getKVInLS(TOKEN_KEY);
}
