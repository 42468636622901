import React, {
    useState,
    createContext,
    ReactNode,
    useContext,
    useMemo
} from 'react';
import { getSidebarMode, setSidebarMode } from 'src/utils/ls';

export enum ExpandState {
    EXPAND = 'expand',
    COLLAPSE = 'collapse'
}
type SidebarMenuProps = {
    isExpand: boolean;
    setMenuExpandState: (s: ExpandState) => void;
    onToggle: () => void;
};

const SidebarMenuContext = createContext<SidebarMenuProps>({
    isExpand: true,
    setMenuExpandState: () => {},
    onToggle: () => {}
});

export type GlobalContextProviderProps = {
    children: ReactNode;
};

const SidebarMenuProvider = ({ children }: GlobalContextProviderProps) => {
    const [isExpand, setIsExpand] = useState(
        getSidebarMode() !== ExpandState.COLLAPSE
    );
    const value = useMemo(() => {
        const setMenuExpandState = (s: ExpandState) => {
            setSidebarMode(s);
            setIsExpand(s === ExpandState.EXPAND);
        };
        const onToggle = () => {
            setIsExpand(p => {
                setSidebarMode(!p ? ExpandState.EXPAND : ExpandState.COLLAPSE);
                return !p;
            });
        };
        return {
            setMenuExpandState,
            onToggle,
            isExpand
        };
    }, [isExpand]);

    return (
        <SidebarMenuContext.Provider value={value}>
            {children}
        </SidebarMenuContext.Provider>
    );
};

export const useSidebarMenu = () => useContext(SidebarMenuContext);

export default SidebarMenuProvider;
