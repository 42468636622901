export enum ChannelTypes {
    Voice = 'voice',
    Chat = 'chat',
    Whatsapp = 'whatsapp',
    Sms = 'sms'
}
export enum CallStatusTypes {
    Resolved = 'resolved',
    Handover = 'handover',
    Hangup = 'hangup'
}

export const LOG_MAP = {
    [ChannelTypes.Voice]: {
        text: 'Voice',
        svgSrc: '/images/dashboard/voice.svg'
    },
    [ChannelTypes.Chat]: {
        text: 'Chat',
        svgSrc: '/images/dashboard/chat.svg'
    },
    [ChannelTypes.Whatsapp]: {
        text: 'WhatsApp',
        svgSrc: '/images/dashboard/whatsApp.svg'
    },
    [ChannelTypes.Sms]: {
        text: 'SMS',
        svgSrc: '/images/dashboard/sms.svg'
    },
    [""]: {
        text: 'Voice',
        svgSrc: '/images/dashboard/voice.svg'
    },
};

export const CALL_STATUS_MAP = {
    [CallStatusTypes.Resolved]: {
        text: 'Resolved',
        svgSrc: '/images/dashboard/resolved.svg'
    },
    [CallStatusTypes.Handover]: {
        text: 'Handover',
        svgSrc: '/images/dashboard/handover.svg'
    },
    [CallStatusTypes.Hangup]: {
        text: 'Hang-up',
        svgSrc: '/images/dashboard/hangup.svg'
    }
};
